import {Modal} from 'react-bootstrap'
import {createPortal} from 'react-dom'
import {useIntl} from 'react-intl'
import {ID, KTSVG} from '../../../../../../_metronic/helpers'
import { ProjectCloseModalForm } from './ProjectCloseModalForm'

type Props = {
    show: boolean
    handleClose: () => void
    project_id: ID
  }
  
const modalsRoot = document.getElementById('root-modals') || document.body

const ProjectCloseModal = ({show, handleClose, project_id}: Props) => {
    const intl = useIntl()

    return createPortal(
        //@ts-ignore
        <Modal
          id='kt_modal_edit_project'
          tabIndex={-1}
          aria-hidden='true'
          dialogClassName='modal-dialog modal-dialog-centered mw-500px'
          show={show}
          onHide={handleClose}
          data-backdrop='static'
        >
            <div className='modal-header'>
                <h2>{intl.formatMessage({id: 'PROJECT.LABEL.CLOSE_MODAL'})}</h2>
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>
            <div className='modal-body py-lg-10 px-lg-10'>
                <ProjectCloseModalForm project_id={project_id} handleClose={handleClose} />
            </div>
        </Modal>,
        modalsRoot
    )
}

export {ProjectCloseModal}