/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {useQueryResponseLoading, useQueryResponsePagination} from '../../core/QueryResponseProvider'
import {useQueryRequest} from '../../core/QueryRequestProvider'

const mappedLabel = (label: string): string => {
  if (label === '&laquo; Anterior') {
    return 'Previous'
  }

  if (label === 'Próximo &raquo;') {
    return 'Next'
  }

  return label
}

const TasksByUserListPagination = () => {
  const pagination = useQueryResponsePagination()
  const isLoading = useQueryResponseLoading()
  const {updateState} = useQueryRequest()
  const updatePage = (page: number | null) => {
    if (!page || isLoading || pagination.current_page === page) {
      return
    }

    updateState({page, per_page: pagination.per_page || 30})
  }


  return (

    <div className='row'>
      <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div id='kt_table_teams_paginate'>
          <ul className='pagination'>
            { pagination.total! > pagination.per_page &&
            pagination.links
              ?.map((link, index) => {
                return {...link, label: mappedLabel(link.label)}

              })
              .map((link, index) => (
                <>
                <li
                  key={link.label}
                  className={clsx('page-item', {
                    active: pagination.current_page === parseInt(link.label),
                    disabled: isLoading,
                    next: link.label === 'Next',
                  })}
                >
                  <a
                    className={clsx('page-link', {
                    })}
                    onClick={() => updatePage(link.label === 'Next' && pagination.current_page !== pagination.last_page ? pagination.current_page + 1 : link.label === 'Previous' && pagination.current_page > 1 ? pagination.current_page - 1 : parseInt(link.label))}
                    style={{cursor: 'pointer'}}
                  >

                    {link.label === 'Next' ? <i className="fas fa-chevron-right fs-4 text-dark"></i> : link.label === 'Previous' ?  <i className="fas fa-chevron-left fs-4 text-dark"></i> : mappedLabel(link.label)}
                  
                  </a>
                </li>
                </>
              ) )}
          </ul>
        </div>
      </div>
    </div>
  )
}

export {TasksByUserListPagination}
