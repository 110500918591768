import {FC} from 'react'
import {useParams} from 'react-router-dom'
import {useQuery} from 'react-query'
import {TaskCloneModalForm} from './TaskCloneModalForm'
import {ID, isNotEmpty, KTCardBody, QUERIES} from '../../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {getTaskById} from '../core/_requests'
import {Loading} from '../../../../../../_metronic/partials/components/loading/Loading'

type Props = {
  handleClose: () => void | undefined
  taskId: ID
}

const TaskCloneModalFormWrapper: FC<Props> = ({handleClose, taskId}) => {
  const {
    isLoading,
    data: task,
    error,
  } = useQuery(
    `${QUERIES.TASKS_LIST}-task-${taskId}`,
    () => {
      return getTaskById(taskId)
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  )

  if (!isLoading && !error) {
    return <TaskCloneModalForm isTaskLoading={isLoading} handleClose={handleClose} task={task!} />
  } else {
    return <Loading />
  }

  return null
}

export {TaskCloneModalFormWrapper}
