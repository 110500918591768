import {Modal} from 'react-bootstrap'
import {TasksModalContentWrapper} from './TasksModalContentWrapper'

type Props = {
  show: boolean
  handleClose: () => void | undefined
  userId?: number
  status: string
}

export function TasksModal({show, handleClose, userId, status}: Props) {
  return (
    <Modal
      className='px-md-5'
      id='kt_modal_adjust_time'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={show}
      dialogClassName='mw-600px modal-dialog-centered'
      aria-hidden='true'
      onHide={handleClose}
    >
      <TasksModalContentWrapper handleClose={handleClose} userId={userId} status={status} />
    </Modal>
  )
}
