/* eslint-disable jsx-a11y/anchor-is-valid */

const TaskByUserDndHandleCell = () => {

  return (
    <div className='d-flex flex-column justify-content-center'>
      <i className="fa-solid fa-grip-vertical fs-5"></i>
    </div>
  )
} 

export {TaskByUserDndHandleCell}
