import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {Sprint, SprintsQueryResponse, Stages, Task} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const SPRINT_URL = `${API_URL}/api/sprint`

const getSprints = (): Promise<SprintsQueryResponse> => {
  return axios
    .get(`${SPRINT_URL}`)
    .then((d: AxiosResponse<SprintsQueryResponse>) => d.data)
}

const getLastSprint = (): Promise<SprintsQueryResponse> => {
  return axios
    .get(`${SPRINT_URL}/last-sprint`)
    .then((d: AxiosResponse<SprintsQueryResponse>) => d.data)
}

const getSprintById = (id: ID): Promise<Sprint | undefined> => {
  return axios
    .get(`${SPRINT_URL}/${id}`)
    .then((response: AxiosResponse<Response<Sprint>>) => response.data)
    .then((response: Response<Sprint>) => response.data)
}

const insertTaskInSprintId = (sprintId: ID, taskId: ID): Promise<SprintsQueryResponse> => {
  return axios
    .get(`${SPRINT_URL}/${sprintId}/${taskId}/insert-task-in-sprintid`)
    .then((d: AxiosResponse<SprintsQueryResponse>) => d.data)
}

const getTasksBySprintIdAndStageId = (sprintId: ID, stageId: ID): Promise<Task[]> => {
  return axios
    .get(`${SPRINT_URL}/${sprintId}/${stageId}/get-tasks-by-sprintid-and-stageid`)
    .then((response: AxiosResponse<Response<Task[]>>) => response.data)
    .then((response: Response<Task[]>) => response.data)
}

const getStagesByInstanceId = (): Promise<Stages[]> => {
  return axios
    .get(`${SPRINT_URL}/get-stages-by-instanceid`)
    .then((response: AxiosResponse<Response<Stages[]>>) => response.data)
    .then((response: Response<Stages[]>) => response.data)
}

const getStagesByInstanceIdAndSprintId = (sprintId: ID): Promise<Stages[]> => {
  return axios
    .get(`${SPRINT_URL}/${sprintId}/get-stages-by-instanceid-and-sprintid`)
    .then((response: AxiosResponse<Response<Stages[]>>) => response.data)
    .then((response: Response<Stages[]>) => response.data)
}

const getTasksBySearchAndNotSprintId = (sprintId: ID): Promise<Task[]> => {
  return axios
    .get(`${SPRINT_URL}/${sprintId}/get-tasks-by-search-and-not-sprintid`)
    .then((response: AxiosResponse<Response<Task[]>>) => response.data)
    .then((response: Response<Task[]>) => response.data)
}

const getTasksBySprintId = (id: ID): Promise<Task[]> => {
  return axios
    .get(`${SPRINT_URL}/get_tasks_by_sprintid?sprintId=${id}`)
    .then((response: AxiosResponse<Response<Task[]>>) => response.data)
    .then((response: Response<Task[]>) => response.data)
}

const updateStageTask = (sprintId: ID, taskId: string, newStageId: string, order: ID): Promise<SprintsQueryResponse> => {
  return axios
      .get(`${SPRINT_URL}/${sprintId}/${taskId}/${newStageId}/${order}/updatetask`)
      .then((d: AxiosResponse<SprintsQueryResponse>) => d.data)
}

const createSprint = (sprint: Sprint): Promise<Sprint | undefined> => {
  return axios
    .post(`${SPRINT_URL}/novo`, sprint)
    .then((response: AxiosResponse<Response<Sprint>>) => response.data)
    .then((response: Response<Sprint>) => response.data)
}

const updateSprint = (sprint: Sprint): Promise<Sprint | undefined> => {
  return axios
    .put(`${SPRINT_URL}/${sprint.id}`, sprint)
    .then((response: AxiosResponse<Response<Sprint>>) => response.data)
    .then((response: Response<Sprint>) => response.data)
}

const close_sprint = (sprintId: ID):  Promise<Sprint | undefined> => {
  return axios
  .get(`${SPRINT_URL}/${sprintId}/close`)
  .then((response: AxiosResponse<Response<Sprint>>) => response.data)
  .then((response: Response<Sprint>) => response.data)
}

const open_sprint = (sprintId: ID):  Promise<Sprint | undefined> => {
  return axios
  .get(`${SPRINT_URL}/${sprintId}/open`)
  .then((response: AxiosResponse<Response<Sprint>>) => response.data)
  .then((response: Response<Sprint>) => response.data)
}

const delete_sprint = (sprintId: ID):  Promise<Sprint | undefined> => {
  return axios
  .get(`${SPRINT_URL}/${sprintId}/excluir`)
  .then((response: AxiosResponse<Response<Sprint>>) => response.data)
  .then((response: Response<Sprint>) => response.data)
}

const deleteSelectedSprints = (sprintIds: Array<ID>): Promise<void> => {
  const requests = sprintIds.map((id) => axios.delete(`${SPRINT_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getSprints, getLastSprint, close_sprint,open_sprint, deleteSelectedSprints, getSprintById, insertTaskInSprintId, getStagesByInstanceId,getStagesByInstanceIdAndSprintId,getTasksBySearchAndNotSprintId, getTasksBySprintId,getTasksBySprintIdAndStageId, updateStageTask, createSprint, delete_sprint, updateSprint}
