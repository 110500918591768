import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {Team, TimeTrackingQueryResponse, User} from './_models'
import {TeamsQueryResponse} from '../../../../team/components/teams-list/core/_models'

const API_URL = process.env.REACT_APP_THEME_API_URL

const GET_TEAMS_URL = `${API_URL}/api/equipes/time-tracking`

const getTeamsByWeek = (): Promise<TeamsQueryResponse> => {
  return axios.get(`${GET_TEAMS_URL}/index`).then((d: AxiosResponse<TeamsQueryResponse>) => d.data)
}

const getTeamsByLast30Days = (): Promise<TeamsQueryResponse> => {
  return axios
    .get(`${GET_TEAMS_URL}/last-30-days`)
    .then((d: AxiosResponse<TeamsQueryResponse>) => d.data)
}

const getTeamsByMonth = (): Promise<TeamsQueryResponse> => {
  return axios.get(`${GET_TEAMS_URL}/month`).then((d: AxiosResponse<TeamsQueryResponse>) => d.data)
}

const getTeamsBy90Days = (): Promise<TeamsQueryResponse> => {
  return axios
    .get(`${GET_TEAMS_URL}/last-90-days`)
    .then((d: AxiosResponse<TeamsQueryResponse>) => d.data)
}

const getTeamsByYear = (): Promise<TeamsQueryResponse> => {
  return axios.get(`${GET_TEAMS_URL}/year`).then((d: AxiosResponse<TeamsQueryResponse>) => d.data)
}

const getTeamsByFilter = (filter: unknown): Promise<TeamsQueryResponse> => {
  return axios
    .get(`${GET_TEAMS_URL}/filter`, {params: filter})
    .then((d: AxiosResponse<TeamsQueryResponse>) => d.data)
}

const getTimeTrackingByUserId = (id: ID): Promise<User> => {
  return axios
    .get(`${GET_TEAMS_URL}/${id}`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const getTimeTrackingByMonthUserId = (id: ID): Promise<User> => {
  return axios
    .get(`${GET_TEAMS_URL}/${id}/month`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const getTimeTrackingByLast30DaysUserId = (id: ID): Promise<User> => {
  return axios
    .get(`${GET_TEAMS_URL}/${id}/last-30-days`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const getTimeTrackingByLast90DaysUserId = (id: ID): Promise<User> => {
  return axios
    .get(`${GET_TEAMS_URL}/${id}/last-90-days`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const getTimeTrackingByYearUserId = (id: ID): Promise<User> => {
  return axios
    .get(`${GET_TEAMS_URL}/${id}/year`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const getTimeTrackingByFilterUserId = (
  id: ID,
  beginDate: string,
  endDate: string
): Promise<User> => {
  return axios
    .get(`${GET_TEAMS_URL}/${id}/${beginDate}/${endDate}/filter`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

export {
  getTeamsByWeek,
  getTeamsByMonth,
  getTimeTrackingByUserId,
  getTimeTrackingByMonthUserId,
  getTimeTrackingByLast30DaysUserId,
  getTimeTrackingByLast90DaysUserId,
  getTimeTrackingByYearUserId,
  getTeamsByLast30Days,
  getTeamsBy90Days,
  getTeamsByYear,
  getTimeTrackingByFilterUserId,
  getTeamsByFilter,
}
