import {ChangeEvent, FC, useState} from 'react'
import clsx from 'clsx'
import {useIntl} from 'react-intl'
import {useSearchParams} from 'react-router-dom'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useQueryClient} from 'react-query'
import {KTSVG, QUERIES} from '../../../helpers'
import {createProjectAndClient} from './core/_requests'
import {Toast} from '../../widgets'
import {Loading} from '../../components/loading/Loading'
import {useQueryResponse} from '../task/core/QueryResponseProvider'
import {SelectAutoComplete} from '../../components/Select/SelectAutoComplete'
import {NowIndicatorContainer} from '@fullcalendar/core/internal'

type Props = {
  handleClose: () => void | undefined
}

const CreateProjectAndClientModalContent: FC<Props> = ({handleClose}) => {
  const intl = useIntl()
  const queryClient = useQueryClient()
  const {refetch} = useQueryResponse()
  const {query} = useQueryResponse()
  const [searchParams, setSearchParams] = useSearchParams()
  const [projectType, setProjectType] = useState<number>()
  const [beginDate, setBeginDate] = useState(
    new Date().toLocaleDateString('en-CA', {
      timeZone: 'UTC',
    })
  )
  const [clientName, setClientName] = useState('')

  const createProjectAndClientSchema = Yup.object().shape({
    project_name: Yup.string().required(intl.formatMessage({id: 'FORM.INPUT.VALIDATION.REQUIRED'})),
    project_type: Yup.number().required(intl.formatMessage({id: 'FORM.INPUT.VALIDATION.REQUIRED'})),
    project_begin_date: Yup.string().required(
      intl.formatMessage({id: 'FORM.INPUT.VALIDATION.REQUIRED'})
    ),
  })

  const initialValues = {
    project_name: searchParams.get('new_project_name') ?? '',
    project_type: undefined,
    project_begin_date: beginDate,
    client_id: undefined,
    client_name: undefined,
  }

  const formik = useFormik({
    initialValues,
    validationSchema: createProjectAndClientSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setSubmitting(true)
      try {
        await createProjectAndClient(values).then((response) => {
          setSearchParams({_new: response.project.id?.toString()!})
          Toast.fire({
            icon: 'success',
            title: intl.formatMessage(
              {id: 'ALERT.LABEL.SUCCESS_CREATE_ITEM'},
              {item: intl.formatMessage({id: 'TABLE.GENERAL.LABEL.PROJECT'})}
            ),
            customClass: {
              popup: 'bg-light-success',
              title: 'text-success',
            },
          })
        })
        resetForm()
        handleClose()
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        queryClient.invalidateQueries(`${QUERIES.SELECT_AUTOCOMPLETE_LIST}-projetos`)
        cancel(true)
      }
    },
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
  }

  function handleTypeChange(e: ChangeEvent<HTMLInputElement>) {
    formik.setFieldValue('project_type', parseInt(e.target.value))
    setProjectType(parseInt(e.target.value))
  }

  function handleBeginDateChange(e: ChangeEvent<HTMLInputElement>) {
    formik.setFieldValue('project_begin_date', e.target.value)
    setBeginDate(e.target.value)
  }

  return (
    <>
      <div className='modal-content'>
        <div className='modal-header border-0'>
          {/* begin::Modal title */}
          <h2 className='fw-bolder'>
            {intl.formatMessage({id: 'MODAL.TITLE.NEW_PROJECT_AND_CLIENT'})}
          </h2>
          {/* end::Modal title */}

          {/* begin::Close */}
          <div
            className='btn btn-icon btn-sm btn-active-icon-primary'
            data-kt-clients-modal-action='close'
            onClick={handleClose}
            style={{cursor: 'pointer'}}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
          </div>
          {/* end::Close */}
        </div>
        <div className='modal-body'>
          <form
            id='kt_modal_add_project_milestone_form'
            className='form'
            onSubmit={formik.handleSubmit}
            noValidate
          >
            <div className='mb-6'>
              <label className='required fw-bold'>
                {intl.formatMessage({id: 'FORM.INPUT.NAME.PROJECT_NAME'})}
              </label>

              <input
                {...formik.getFieldProps('project_name')}
                type='text'
                name='project_name'
                className={clsx(
                  'form-control form-control-lg form-control-solid py-2 fw-normal',
                  {'is-invalid': formik.touched.project_name && formik.errors.project_name},
                  {
                    'is-valid': formik.touched.project_name && !formik.errors.project_name,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting}
              />
              {formik.touched.project_name && formik.errors.project_name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.project_name}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='fv-row mb-6'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-4'>
                {intl.formatMessage({id: 'FORM.INPUT.NAME.PROJECT_TYPE'})}
              </label>

              {/*begin:Option */}
              <label className='d-flex align-items-center justify-content-between cursor-pointer mb-6'>
                <span className='d-flex align-items-center me-2'>
                  <span className='symbol symbol-50px me-6'>
                    <span className='symbol-label bg-light-success'>
                      <KTSVG
                        path='/media/icons/duotune/abstract/abs027.svg'
                        className='svg-icon-success svg-icon-2hx'
                      />
                    </span>
                  </span>

                  <span className='d-flex flex-column'>
                    <span className='fw-bolder fs-6'>
                      {intl.formatMessage({id: 'FORM.INPUT.NAME.CLOSED_SCOPE'})}
                    </span>
                    <span className='fs-7 text-muted'>
                      {intl.formatMessage({id: 'FORM.INPUT.TOOLTIP.CLOSED_SCOPE'})}
                    </span>
                  </span>
                </span>

                <span className='form-check form-check-custom form-check-solid'>
                  <input
                    {...formik.getFieldProps('project_type')}
                    role='button'
                    className='form-check-input'
                    type='radio'
                    name='type'
                    value={1}
                    checked={projectType === 1}
                    onChange={handleTypeChange}
                  />
                </span>
              </label>
              {/*end::Option */}

              {/*begin:Option */}
              <label className='d-flex align-items-center justify-content-between cursor-pointer mb-6'>
                <span className='d-flex align-items-center me-2'>
                  <span className='symbol symbol-50px me-6'>
                    <span className='symbol-label bg-light-warning'>
                      <KTSVG
                        path='/media/icons/duotune/abstract/abs028.svg'
                        className='svg-icon-warning svg-icon-2hx'
                      />
                    </span>
                  </span>

                  <span className='d-flex flex-column'>
                    <span className='fw-bolder fs-6'>
                      {intl.formatMessage({id: 'FORM.INPUT.NAME.RECURRENT_PROJECT'})}
                    </span>
                    <span className='fs-7 text-muted'>
                      {intl.formatMessage({id: 'FORM.INPUT.TOOLTIP.RECURRENT_PROJECT'})}
                    </span>
                  </span>
                </span>

                <span className='form-check form-check-custom form-check-solid'>
                  <input
                    {...formik.getFieldProps('project_type')}
                    role='button'
                    className='form-check-input'
                    type='radio'
                    name='projectType'
                    value={0}
                    checked={projectType === 0}
                    onChange={handleTypeChange}
                  />
                </span>
              </label>
              {/*end::Option */}
            </div>
            {/*end::Form Group */}

            {/*begin::Form Group */}
            <div className='col mb-6'>
              <label
                htmlFor='begin_date'
                className='d-flex align-items-center fs-5 fw-semibold mb-2'
              >
                {intl.formatMessage({id: 'FORM.INPUT.NAME.BEGIN_DATE'})}
              </label>
              <input
                type='date'
                className='form-control form-control-lg form-control-solid'
                name='begin_date'
                id='begin_date'
                value={beginDate}
                onChange={handleBeginDateChange}
              />

              {formik.touched.project_begin_date && formik.errors.project_begin_date && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.project_begin_date}</span>
                  </div>
                </div>
              )}
            </div>
            {/*end::Form Group */}

            <div className='mb-4'>
              <label className='required fw-bold'>
                {intl.formatMessage({id: 'FORM.INPUT.NAME.CLIENT'})}
              </label>
              <SelectAutoComplete
                {...formik.getFieldProps('client_name')}
                endPoint='clientes'
                placeholder={intl.formatMessage({id: 'FORM.INPUT.TOOLTIP.CLIENT'})}
                onChange={(e) => {
                  if (e?.__isNew__) {
                    formik.setFieldValue('client_name', e?.value)
                    setClientName(e?.value ?? '')
                  } else {
                    setClientName(e?.label ?? '')
                    formik.setFieldValue('client_name', e?.label ?? '')
                    formik.setFieldValue('client_id', e?.value ?? '')
                  }
                }}
              />

              {formik.touched.client_name && formik.errors.client_name && !clientName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>
                      {intl.formatMessage({id: 'FORM.INPUT.VALIDATION.REQUIRED'})}
                    </span>
                  </div>
                </div>
              )}
            </div>
            {/* begin::Actions */}
            <div className='text-end pt-8'>
              <button
                type='submit'
                className='btn btn-primary py-2'
                data-kt-projects-modal-action='submit'
                disabled={formik.isSubmitting || !formik.isValid || !formik.touched || !clientName}
              >
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'FORM.GENERAL.SAVE_BUTTON'})}
                </span>
                {formik.isSubmitting && (
                  <span className='indicator-progress'>
                    {intl.formatMessage({id: 'GENERAL.LOADING'})}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            {/* end::Actions */}
          </form>
        </div>
        {formik.isSubmitting && <Loading />}
      </div>
    </>
  )
}

export {CreateProjectAndClientModalContent}
