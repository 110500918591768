import {FC} from 'react'
import {toAbsoluteUrl} from '../../../helpers'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
interface IconUserModel {
  name: string
  avatar?: string
  color?: string
}

type Props = {
  users?: Array<IconUserModel>
  classes?: string
}
const UsersSymbolGroup: FC<Props> = ({users = undefined, classes = undefined}) => {
  return (
    <>
      <div className={`symbol-group symbol-hover ${classes}`}>
        {users &&
          users.map((user, i) => {
            return (
              //@ts-ignore
              <OverlayTrigger
                key={`${i}-${user.name}`}
                placement='top'
                //@ts-ignore
                overlay={<Tooltip id='tooltip-user-name'>{user.name}</Tooltip>}
              >
                <div className='symbol symbol-35px symbol-circle object-fit-cover'>
                  {user.avatar != null ? (
                  user.avatar && <img src={process.env.REACT_APP_THEME_API_URL + '/storage/' + user.avatar}  alt='Pic' className='object-fit-cover' />
                  ) : (
                    <span className='symbol-label bg-primary text-inverse-primary fw-bolder me-2'>
                      {user.name.substring(0, 1)}
                    </span>
                   )}
  

                </div>
              </OverlayTrigger>
            )
          })
        }
      </div>
    </>
  )
}

export {UsersSymbolGroup}
