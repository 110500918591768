import axios, {AxiosResponse} from 'axios'
import {ID, QueryState, Response} from '../../../../../_metronic/helpers'
import {Client, ClientsQueryResponse, ResponseClient} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const CLIENT_URL = `${API_URL}/api/clientes`
const GET_CLIENTS_URL = `${API_URL}/api/clientes`

const getClients = (query: string): Promise<ClientsQueryResponse> => {
  return axios
    .get(`${GET_CLIENTS_URL}?${query}`)
    .then((d: AxiosResponse<ClientsQueryResponse>) => d.data)
}

const getAllClients = (): Promise<ClientsQueryResponse> => {
  return axios
    .get(`${GET_CLIENTS_URL}/all`)
    .then((d: AxiosResponse<ClientsQueryResponse>) => d.data)
}

const getClientsWithRecurringProjects = (): Promise<ClientsQueryResponse> => {
  return axios
    .get(`${GET_CLIENTS_URL}/recurring-projects-only`)
    .then((d: AxiosResponse<ClientsQueryResponse>) => d.data)
}

const searchClient = (search: QueryState): Promise<ClientsQueryResponse> => {
  return axios
    .post(`${CLIENT_URL}/search`, search)
    .then((d: AxiosResponse<ClientsQueryResponse>) => d.data)
}

const getEmailValid = (client: Client): Promise<ResponseClient> => {
  return axios
    .post(`${CLIENT_URL}/verify_email`, client)
    .then((response: AxiosResponse<ResponseClient>) => response.data)
}

const getClientById = (id: ID): Promise<ResponseClient> => {
  return axios
    .get(`${CLIENT_URL}/${id}`)
    .then((response: AxiosResponse<ResponseClient>) => response.data)
}

const createClient = (client: Client): Promise<ResponseClient> => {
  return axios
    .post(`${CLIENT_URL}/novo`, client)
    .then((response: AxiosResponse<ResponseClient>) => response.data)
}

const updateClient = (client: Client): Promise<ResponseClient> => {
  return axios
    .put(`${CLIENT_URL}/${client.id}/editar`, client)
    .then((response: AxiosResponse<ResponseClient>) => response.data)
}

const deleteClient = (clientId: ID): Promise<void> => {
  return axios.delete(`${CLIENT_URL}/${clientId}/excluir`).then(() => {})
}

const deleteSelectedClients = (clientIds: Array<ID>): Promise<void> => {
  const requests = clientIds.map((id, index) => axios.delete(`${CLIENT_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const getClientProfitMargin = (clientId: ID): Promise<number> => {
  return axios
    .get(`${CLIENT_URL}/${clientId}/profit-margin`)
    .then(
      (response: AxiosResponse<{clientProfitMargin: number}>) => response.data.clientProfitMargin
    )
}

export {
  getClients,
  getAllClients,
  deleteClient,
  deleteSelectedClients,
  getEmailValid,
  searchClient,
  getClientById,
  createClient,
  updateClient,
  getClientProfitMargin,
  getClientsWithRecurringProjects
}
