import {FC, useState} from "react"
import {ID, KTSVG, QUERIES} from "../../../helpers"
import {useIntl} from 'react-intl'
import { TasksListWrapper } from "./components/tasks-list/TasksList"
import {useListView} from "../details-time-user/components/tasks-list/core/ListViewProvider"
import { useQuery } from "react-query"
import { getTasks } from "./components/tasks-list/core/_requests"
import { Task } from "../task/core/_models"
type Props =
 {
    handleClose: () => void | undefined
    userId: String 
    dateSelected: String
    dateFormatSelected: String
}

const DetailsTimeUserModalContent: FC<Props> = ({handleClose, userId, dateSelected, dateFormatSelected}) => {
    const intl = useIntl()

    return (
        <div className="h-600px">
            <div className='modal-header border-0'>
                <div className="col-md-11">
                    <div className="fw-bold fs-2">{intl.formatMessage({id: 'TIME_TRAKING.MODAL.LABEL.ADJUST_TIME'})} {dateFormatSelected} </div>
                </div>
                <div className="col-md-1 d-flex justify-content-end align-items-center">
                    <button className='btn btn-sm btn-icon btn-active-color-primary h-auto ms-4' onClick={handleClose}>
                      <KTSVG className='svg-icon-2x svg-icon-gray-800' path='/media/icons/duotune/arrows/arr061.svg' />
                    </button>
                </div>
            </div>
            <div className='modal-body'>
               <div className="tab-content pt-5">
                 <div className="tab-pane fade active show" id="kt_tab_pane_1" role="tabpanel" >
                       <TasksListWrapper userId={userId} dateSelected={dateSelected}/>
                     </div>
                 </div>
            </div>
        </div>
    )
}

export {DetailsTimeUserModalContent}