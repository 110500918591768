import {Modal} from 'react-bootstrap'
import {ID} from '../../../helpers'
import {CreateProjectAndClientModalContent} from './CreateProjectAndClientModalContent'

type Props = {
  show: boolean
  handleClose: () => void | undefined
}

const CreateProjectAndClient: React.FC<Props> = ({show, handleClose}) => {
  return (
    //@ts-ignore
    <Modal className='px-md-5 bg-glass' id='kt_modal_project_and_client'  tabIndex={-1} role='dialog' show={show}
      dialogClassName='mw-500px modal-dialog-centered' aria-hidden='true' backdrop='static' onHide={handleClose} >
      <CreateProjectAndClientModalContent handleClose={handleClose} />
    </Modal>
  )
}

export {CreateProjectAndClient}