/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {ID} from '../../../../../../_metronic/helpers'

type Props = {
  project_name: string
  project_id: ID
  clientName: string
  clientId: ID
  rowIndex: number
  columnId: number
}

const TaskByUserProjectCell: FC<Props> = ({
  project_name,
  project_id,
  clientName,
  clientId,
  rowIndex,
  columnId,
}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <p className='mb-1'>
          <div className='d-flex d-inline-flex'>
            <Link to={`/clients/${clientId}`}>
              <span className='text-gray-800 text-hover-primary'>{clientName}</span>
            </Link>
          </div>
        </p>

        <Link to={`/projects/${project_id}`}>
          <span className='text-gray-800 text-hover-primary'>{project_name}</span>
        </Link>
      </div>
    </div>
  )
}

export {TaskByUserProjectCell}
