// @ts-nocheck
import {Column} from 'react-table'
import {ContractsNameCell} from './ContractsNameCell'
import {ContractsLastModCell} from './ContractsLastModCell'
import {ContractsCustomHeader} from './ContractsCustomHeader'
import {Contracts} from '../../core/_models'
import {useIntl} from 'react-intl'

const ContractsColumns: ReadonlyArray<Column<Contracts>> = [
  {
    Header: (props) => <ContractsCustomHeader tableProps={props} title={useIntl().formatMessage({id: 'CONTRACTS.TITLE'})} className='min-w-200px p-5 shadow-sm' />,
    id: 'name',
    Cell: ({...props}) => <ContractsNameCell contract={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <ContractsCustomHeader tableProps={props} title={useIntl().formatMessage({id: 'CONTRACTS.EXPIRE'})} className='min-w-125px p-5 shadow-sm' />,
    id: 'modified',
    Cell: ({...props}) => <ContractsLastModCell contract={props.data[props.row.index]} />,
  },
]

export {ContractsColumns}
