import {Column} from 'react-table'
import {TaskNameCell} from './TaskNameCell'
import {TimeCell} from './TimeCell'
import { Task } from '../../../../../../app/modules/reports/components/sprint-team/core/_models'
import { TaskCustomHeader } from './TaskCustomHeader'

const tasksColumns: ReadonlyArray<Column<Task>> = [
  {
    Header: (props) => <TaskCustomHeader tableProps={props} />,
    id: 'name',
    Cell: ({...props}) => <TaskNameCell task={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <TaskCustomHeader tableProps={props} />,
    id: 'time',
    Cell: ({...props}) => <TimeCell task_totaltime={props.data[props.row.index].total_time} />,
  },
]

export {tasksColumns}
