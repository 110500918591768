import {FC, useState} from 'react'
import {useIntl} from 'react-intl'
import {useMutation, useQueryClient} from 'react-query'
import Swal from 'sweetalert2'
import {StepperComponent} from '../../../assets/ts/components'
import {KTSVG} from '../../../helpers'
import {Step1} from './steps/Step1'
import {Step2} from './steps/Step2'
import {Step3} from './steps/Step3'
import {Project, initialProject} from '../../../../app/modules/project/components/core/_models'
import {createProject} from '../../../../app/modules/project/components/core/_requests'
import {Toast} from '../../widgets'
import {Loading} from '../../components/loading/Loading'
import {useNavigate} from 'react-router-dom'

type Props = {
  handleClose?: () => void | undefined
  stepper: React.MutableRefObject<StepperComponent | null>
  stepperRef: React.MutableRefObject<HTMLDivElement | null>
}

const ProjectModalContent: FC<Props> = ({handleClose, stepper, stepperRef}) => {
  const intl = useIntl()
  const [data, setData] = useState<Project>(initialProject)
  const [hasError, setHasError] = useState<boolean>(false)
  const [isSubmitting, setSubmitting] = useState<boolean>(false)
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const updateData = (fieldsToUpdate: Partial<Project>) => {
    const updatedData = {...data, ...fieldsToUpdate}
    setData(updatedData)
  }

  const checkProjectBasic = (): boolean => {
    if (!data.name || !data.client_id) return false

    return true
  }

  const checkProjectComplements = () => {
    if (!data.begin_date) return false

    return true
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()
  }

  const nextStep = () => {
    setHasError(false)
    if (!stepper.current) {
      return
    }

    if (stepper.current.getCurrentStepIndex() === 1) {
      if (!checkProjectBasic()) {
        setHasError(true)
        return
      }
    }

    stepper.current.goNext()
  }

  const create = useMutation(() => createProject(data), {
    onSuccess: (response) => {
      setSubmitting(false)
      setData(initialProject)
      Toast.fire({
        icon: 'success',
        title: intl.formatMessage(
          {id: 'ALERT.LABEL.SUCCESS_CREATE_ITEM'},
          {item: intl.formatMessage({id: 'TABLE.GENERAL.LABEL.PROJECT'})}
        ),
        customClass: {
          popup: 'bg-light-success',
          title: 'text-success',
        },
      })
      if (handleClose) {
        handleClose()
      }
      queryClient.invalidateQueries()
      stepper.current?.goFirst()
      navigate(`/projects/${response?.project.id}`)
    },
    onError: (error: any) => {
      setSubmitting(false)
      Swal.fire({
        icon: 'warning',
        text: error.response.data.msg,
      })
    },
  })

  const submit = () => {
    setHasError(false)
    if (!stepper.current) {
      return
    }

    if (stepper.current.getCurrentStepIndex() === 3) {
      if (!checkProjectComplements()) {
        setHasError(true)
        return
      } else {
        setSubmitting(true)
        return create.mutateAsync()
      }
    }
  }

  return (
    <>
      <div className='modal-body'>
        {/*begin::Stepper */}
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_edit_project_stepper'
        >
          {/* begin::Aside*/}
          <div className='d-flex flex-row-auto w-100 w-xl-300px'>
            {/* begin::Nav*/}
            <div className='stepper-nav ps-lg-10 py-5'>
              {/* begin::Step 1*/}
              <div className='stepper-item current' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>1</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>
                      {intl.formatMessage({id: 'FORM.STEP.NAME.DETAILS'})}
                    </h3>

                    <div className='stepper-desc'>
                      {intl.formatMessage({id: 'FORM.STEP.DESCRIPTION.DETAILS'})}
                    </div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 1*/}

              {/* begin::Step 2*/}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>2</span>
                  </div>
                  {/* begin::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>
                      {intl.formatMessage({id: 'FORM.STEP.NAME.TYPE'})}
                    </h3>

                    <div className='stepper-desc'>
                      {intl.formatMessage({id: 'FORM.STEP.DESCRIPTION.TYPE'})}
                    </div>
                  </div>
                  {/* begin::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 2*/}

              {/* begin::Step 3*/}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>3</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>
                      {intl.formatMessage({id: 'FORM.STEP.NAME.INFORMATION'})}
                    </h3>

                    <div className='stepper-desc'>
                      {intl.formatMessage({id: 'FORM.STEP.DESCRIPTION.INFORMATION'})}
                    </div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}
              </div>
              {/* end::Step 3*/}
            </div>
            {/* end::Nav*/}
          </div>
          {/* begin::Aside*/}

          {/*begin::Content */}

          <div className='flex-row-fluid py-lg-5 px-lg-15'>
            {/*begin::Form */}
            <form noValidate id='kt_modal_edit_project_form'>
              <Step1 data={data} updateData={updateData} hasError={hasError} />
              <Step2 data={data} updateData={updateData} hasError={hasError} />
              <Step3 data={data} updateData={updateData} hasError={hasError} />

              {/*begin::Actions */}
              <div className='d-flex flex-stack pt-10'>
                <div className='me-2'>
                  <button
                    type='button'
                    className='btn btn-lg btn-light-primary me-3'
                    data-kt-stepper-action='previous'
                    onClick={prevStep}
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr063.svg'
                      className='svg-icon-3 me-1'
                    />{' '}
                    {intl.formatMessage({id: 'GENERAL.BACK'})}
                  </button>
                </div>
                <div>
                  <button
                    type='button'
                    className='btn btn-lg btn-primary'
                    data-kt-stepper-action='submit'
                    onClick={submit}
                  >
                    {intl.formatMessage({id: 'FORM.GENERAL.SAVE_BUTTON'})}{' '}
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr064.svg'
                      className='svg-icon-3 ms-2 me-0'
                    />
                  </button>

                  <button
                    type='button'
                    className='btn btn-lg btn-primary'
                    data-kt-stepper-action='next'
                    onClick={nextStep}
                  >
                    {intl.formatMessage({id: 'GENERAL.NEXT_STEP'})}{' '}
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr064.svg'
                      className='svg-icon-3 ms-1 me-0'
                    />
                  </button>
                </div>
              </div>
              {/*end::Actions */}
            </form>
            {/*end::Form */}
          </div>

          {/*end::Content */}
        </div>
        {/* end::Stepper */}
      </div>
      {isSubmitting && <Loading />}
    </>
  )
}

export {ProjectModalContent}
