import {Modal} from 'react-bootstrap'
import {ID} from '../../../helpers'
import {TaskModalContentWrapper} from './TaskModalContentWrapper'

type Props = {
  show: boolean
  handleClose: () => void | undefined
  stageId?: ID | string
  sprintId?: ID
}

const TaskModal: React.FC<Props> = ({
  show,
  handleClose,
  stageId,
  sprintId,
}) => {
  return (
    <Modal
      className='px-md-5'
      id='kt_modal_task'
      tabIndex={-1}
      role='dialog'
      show={show}
      dialogClassName='mw-1000px modal-dialog-centered'
      aria-hidden='true'
      backdrop='static'
      onHide={handleClose}
    >
      <TaskModalContentWrapper handleClose={handleClose} stageId={stageId} sprintId={sprintId} />
    </Modal>
  )
}

export {TaskModal}
