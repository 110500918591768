import {FC, useCallback, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {useParams} from 'react-router-dom'
import clsx from 'clsx'
import Swal from 'sweetalert2'
import {useQueryClient} from 'react-query'
import {InputCurrency, KTSVG, QUERIES, isNotEmpty} from '../../../../_metronic/helpers'
import {Project} from '../../../../app/modules/project/components/core/_models'
import {
  getProjectByClientId,
  getProjectById,
} from '../../../../app/modules/project/components/core/_requests'
import {Loading} from '../../components/loading/Loading'
import {
  ResponseContract,
  initialContract,
  Contracts,
} from '../../../../app/modules/contracts/components/contracts-list/core/_models'
import {useListView} from '../../../../app/modules/contracts/components/contracts-list/core/ListViewProvider'
import {useQueryResponse} from '../../../../app/modules/contracts/components/contracts-list/core/QueryResponseProvider'
import {
  createContract,
  updateContract,
} from '../../../../app/modules/contracts/components/contracts-list/core/_requests'

type Props = {
  isContractLoading: boolean
  Contracts: ResponseContract
  projectId: string | undefined
  clientId: string | undefined
  handleClose: () => void | undefined
}

const ContractsModalContent: FC<Props> = ({
  Contracts,
  isContractLoading,
  handleClose,
  projectId,
  clientId,
}) => {
  const intl = useIntl()
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const {client_id} = useParams()

  const queryClient = useQueryClient()
  const editContractschema = Yup.object().shape({
    title: Yup.string()
      .min(2, intl.formatMessage({id: 'FORM.INPUT.VALIDATION.MIN_LENGTH'}, {min: '2'}))
      .max(50, intl.formatMessage({id: 'FORM.INPUT.VALIDATION.MAX_LENGTH'}, {max: '50'}))
      .required(intl.formatMessage({id: 'FORM.INPUT.VALIDATION.REQUIRED'})),
    value: Yup.string()
      .min(2, intl.formatMessage({id: 'FORM.INPUT.VALIDATION.MIN_LENGTH'}, {min: '2'}))
      .required(intl.formatMessage({id: 'FORM.INPUT.VALIDATION.REQUIRED'})),
    // end_date: Yup.date().required(intl.formatMessage({id: 'FORM.INPUT.VALIDATION.REQUIRED'})),
    end_date: Yup.date().optional(),
    begin_date: Yup.date().required(intl.formatMessage({id: 'FORM.INPUT.VALIDATION.REQUIRED'})),
    type: Yup.number().required(intl.formatMessage({id: 'FORM.INPUT.VALIDATION.REQUIRED'})),
  })

  const [loading, setLoading] = useState<Boolean>(false)
  const [dataProject, setDataProject] = useState<Project[]>([])
  const selectProject = async () => {
    if (dataProject.length === 0) {
      setLoading(true)
      await getProjectByClientId(client_id).then((response) => {
        setDataProject(response.data)
      })
    }
  }

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    handleClose()
  }
  const [data, setData] = useState<Contracts>(initialContract)
  const updateData = (fieldsToUpdate: Partial<Contracts>) => {
    const updatedData = {...data, ...fieldsToUpdate}
    setData(updatedData)
  }

  const formik = useFormik({
    initialValues: Contracts.contracts,
    validationSchema: editContractschema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await updateContract(values).then((response) => {
            const CurrentTheme = window.localStorage.getItem('kt_theme_mode_value') || ''
            let theme = ''
            if (CurrentTheme === 'dark') {
              theme = '#353b48'
            } else {
              theme = '#fff'
            }
            Swal.fire({
              icon: 'success',
              title: 'Editado!',
              html: '<h5>' + response?.message + '</h5>',
              background: theme,
              confirmButtonColor: '#009ef7',
              color: '#fff',
            })
            queryClient.invalidateQueries(`${QUERIES.CONTRACTS_LIST}`)
          })
        } else {
          await createContract(values).then((response) => {
            const CurrentTheme = window.localStorage.getItem('kt_theme_mode_value') || ''
            let theme = ''
            if (CurrentTheme === 'dark') {
              theme = '#353b48'
            } else {
              theme = '#fff'
            }

            Swal.fire({
              icon: 'success',
              title: 'Criado!',
              html: '<h5>' + response?.message + '</h5>',
              background: theme,
              confirmButtonColor: '#009ef7',
              color: '#fff',
            })
            queryClient.invalidateQueries(`${QUERIES.CONTRACTS_LIST}`)
          })
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  useEffect(() => {
    if (projectId) {
      getProjectById(parseInt(projectId)).then((response) => {
        formik.setFieldValue('client_id', clientId)
        formik.setFieldValue('project_id', projectId)
      })
    }
    if (client_id) {
      formik.setFieldValue('client_id', client_id)
      formik.setFieldValue('project_id', Contracts.contracts.project_id)
    }
  }, [])

  const handleCreate = useCallback(
    async (e: any) => {
      formik.setFieldValue('file', e.target.files[0])
    },
    [formik]
  )

  return (
    <>
      <form
        id='kt_modal_add_contract_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_contract_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_contract_header'
          data-kt-scroll-wrappers='#kt_modal_add_contract_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='row justify-content-between'>
            <div className='col mb-5 mb-lg-0'>
              <div className='mb-4'>
                <label className='required fw-bold mb-1'>
                  {intl.formatMessage({id: 'FORM.INPUT.NAME.CHANGE_FILE'})}
                </label>

                <input
                  // {...formik.getFieldProps('file')}
                  type='file'
                  name='file'
                  id='file'
                  className={clsx(
                    'form-control form-control-solid py-2  rounded fw-normal',
                    {'is-invalid': formik.touched.file && formik.errors.file},
                    {
                      'is-valid': formik.touched.file && !formik.errors.file,
                    }
                  )}
                  accept='.png, .jpg, .jpeg, .pdf, .doc, .docx, .zip, .rar, .sql, .xls, .xlsx'
                  onChange={handleCreate}
                />
              </div>

              <div className='mb-4'>
                <label className='required fw-bold mb-1'>
                  {intl.formatMessage({id: 'FORM.INPUT.NAME.NAME'})}
                </label>

                <input
                  placeholder={intl.formatMessage({id: 'FORM.INPUT.NAME.NAME'})}
                  {...formik.getFieldProps('title')}
                  type='text'
                  name='title'
                  className={clsx(
                    'form-control form-control-solid py-2  rounded fw-normal',
                    {'is-invalid': formik.touched.title && formik.errors.title},
                    {
                      'is-valid': formik.touched.title && !formik.errors.title,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isContractLoading}
                />
                {formik.touched.title && formik.errors.title && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.title}</span>
                    </div>
                  </div>
                )}
              </div>

              {!projectId && (
                <div className='mb-4'>
                  <label className='fw-bold mb-1'>
                    {intl.formatMessage({id: 'FORM.INPUT.NAME.PROJECTS'})}
                  </label>
                  <select
                    {...formik.getFieldProps('project_id')}
                    disabled={formik.isSubmitting || isContractLoading}
                    onChange={(e: any) => {
                      formik.setFieldValue('project_id', e.target.value)
                    }}
                    data-placeholder={intl.formatMessage({id: 'FORM.INPUT.NAME.CLIENT'})}
                    onClick={selectProject}
                    className={clsx(
                      'form-control form-control-solid py-2 rounded fw-normal',
                      {'is-invalid': formik.touched.project_id && formik.errors.project_id},
                      {
                        'is-valid': formik.touched.project_id && !formik.errors.project_id,
                      }
                    )}
                  >
                    {!loading && (
                      <option key={formik.values.project?.id}>{formik.values.project?.name}</option>
                    )}
                    {loading ? <option>Carregando Projetos</option> : ''}
                    {dataProject.map((rs, index) => {
                      return (
                        <>
                          <option value={rs.id}>{rs.name}</option>
                        </>
                      )
                    })}
                  </select>
                  {formik.touched.client_id && formik.errors.client_id && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.client_id}</span>
                      </div>
                    </div>
                  )}
                </div>
              )}

              <div className='row'>
                <div className='col mb-4'>
                  <label className='required fw-bold mb-1'>
                    {intl.formatMessage({id: 'FORM.INPUT.NAME.BEGIN_DATE'})}
                  </label>

                  <input
                    placeholder={intl.formatMessage({id: 'FORM.INPUT.NAME.BEGIN_DATE'})}
                    {...formik.getFieldProps('begin_date')}
                    type='date'
                    name='begin_date'
                    className={clsx(
                      'form-control form-control-solid py-2  rounded fw-normal',
                      {'is-invalid': formik.touched.begin_date && formik.errors.begin_date},
                      {
                        'is-valid': formik.touched.begin_date && !formik.errors.begin_date,
                      }
                    )}
                    autoComplete='off'
                    disabled={formik.isSubmitting || isContractLoading}
                  />
                  {formik.touched.begin_date && formik.errors.begin_date && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.begin_date}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col mb-4'>
                  <label className='fw-bold mb-1'>
                    {intl.formatMessage({id: 'FORM.INPUT.NAME.END_CONTRACT'})}
                  </label>

                  <input
                    placeholder={intl.formatMessage({id: 'FORM.INPUT.NAME.END_CONTRACT'})}
                    {...formik.getFieldProps('end_date')}
                    type='date'
                    name='end_date'
                    className={clsx(
                      'form-control form-control-solid py-2  rounded fw-normal',
                      {'is-invalid': formik.touched.end_date && formik.errors.end_date},
                      {
                        'is-valid': formik.touched.end_date && !formik.errors.end_date,
                      }
                    )}
                    autoComplete='off'
                    disabled={formik.isSubmitting || isContractLoading}
                  />
                  {formik.touched.end_date && formik.errors.end_date && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.end_date}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='d-flex align-items-center justify-content-between cursor-pointer'>
                <div className='row mt-5 mt-lg-0'>
                  <p className='required fs-5 fw-bold mb-5'>
                    {intl.formatMessage({id: 'FORM.INPUT.NAME.CONTRACT_TYPE'})}
                  </p>

                  <div className='col-md-6 mb-4'>
                    <div className='form-check form-check-custom form-check-solid form-check-sm'>
                      <input
                        {...formik.getFieldProps('type')}
                        type='radio'
                        name='type'
                        id='closed_scope'
                        className={clsx('form-check-input')}
                        autoComplete='off'
                        value={1}
                        defaultChecked={formik.values.type === 1}
                        onChange={(e: any) => {
                          updateData({
                            type: parseInt(e.target.value),
                          })
                          formik.setFieldValue('type', e.target.value)
                        }}
                        disabled={formik.isSubmitting || isContractLoading}
                      />
                      {formik.touched.type && formik.errors.type && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.type}</span>
                          </div>
                        </div>
                      )}

                      <label className='form-check-label' htmlFor='closed_scope'>
                        {intl.formatMessage({id: 'FORM.INPUT.NAME.CLOSED_SCOPE'})}
                      </label>
                    </div>
                  </div>

                  <div className='col-md-6 mb-4'>
                    <div className='form-check form-check-custom form-check-solid form-check-sm'>
                      <input
                        {...formik.getFieldProps('type')}
                        type='radio'
                        name='type'
                        value={2}
                        id='recurrent'
                        defaultChecked={formik.values.type === 2}
                        onChange={(e: any) => {
                          updateData({
                            type: parseInt(e.target.value),
                          })
                          formik.setFieldValue('type', e.target.value)
                        }}
                        className={clsx('form-check-input')}
                        autoComplete='off'
                        disabled={formik.isSubmitting || isContractLoading}
                      />
                      {formik.touched.type && formik.errors.type && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.type}</span>
                          </div>
                        </div>
                      )}

                      <label className='form-check-label' htmlFor='recurrent'>
                        {intl.formatMessage({id: 'FORM.INPUT.NAME.RECURRENT_CONTRACT'})}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className='mb-4'>
                <label
                  htmlFor='budget'
                  className='required d-flex align-items-center fs-5 fw-semibold mb-2'
                >
                  {data.type === 1 || formik.values.type === 1 ? (
                    <>
                      <span>{intl.formatMessage({id: 'FORM.INPUT.NAME.CLOSED_BUDGET'})}</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title={intl.formatMessage({id: 'FORM.INPUT.TOOLTIP.CLOSED_BUDGET'})}
                      ></i>
                    </>
                  ) : (
                    <>
                      <span>{intl.formatMessage({id: 'FORM.INPUT.NAME.MONTHLY_BUDGET'})}</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title={intl.formatMessage({id: 'FORM.INPUT.TOOLTIP.MONTHLY_BUDGET'})}
                      ></i>
                    </>
                  )}
                </label>

                <InputCurrency
                  placeholder='Ex: R$20.000,00'
                  {...formik.getFieldProps('value')}
                  name='value'
                  className={clsx(
                    'form-control form-control-solid py-2 rounded fw-normal',
                    {'is-invalid': formik.touched.value && formik.errors.value},
                    {
                      'is-valid': formik.touched.value && !formik.errors.value,
                    }
                  )}
                  value={formik.values.value ? formik.values.value : 0}
                  disabled={formik.isSubmitting || isContractLoading}
                  onChange={(e) => {
                    formik.setFieldValue(
                      'value',
                      e.target.value.replace('.', '').replace('.', '').replace(',', '.')
                    )
                  }}
                />
                {formik.touched.value && formik.errors.value && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.value}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-end pt-15'>
          <button
            type='submit'
            className='btn btn-primary py-2'
            data-kt-contracts-modal-action='submit'
            disabled={
              isContractLoading ||
              formik.isSubmitting ||
              !formik.isValid ||
              !formik.touched ||
              !formik.values.file
            }
          >
            <span className='indicator-label'>
              {intl.formatMessage({id: 'FORM.GENERAL.SAVE_BUTTON'})}
            </span>
            {(formik.isSubmitting || isContractLoading) && (
              <span className='indicator-progress'>
                {intl.formatMessage({id: 'GENERAL.LOADING'})}{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isContractLoading) && <Loading />}
    </>
  )
}

export {ContractsModalContent}
