import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {SprintReportStatistics} from '../../../../app/modules/reports/components/sprint-team/core/_models'
import {TasksModal} from '../../modals/sprint-report/TasksModal'
import {useState} from 'react'
import {Link} from 'react-router-dom'

type Props = {
  statistics: SprintReportStatistics[]
  totalUsers: number
}

export function TableSprintMembersStatistics({statistics, totalUsers}: Props) {
  const [showModal, setShowModal] = useState(false)
  const [userId, setUserId] = useState<number>()
  const [status, setStatus] = useState('')

  function handleTaskStatusClick(status: string, userId: number) {
    setStatus(status)
    setUserId(userId)
    setShowModal(true)
  }

  return (
    <div className={`card mb-5 mb-xl-8`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Estatísticas detalhadas</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{totalUsers} Membros</span>
        </h3>
      </div>

      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-150px'>Membro</th>
                <th className='min-w-140px'>Total de tarefas</th>
                <th className='min-w-120px'>Não iniciadas</th>
                <th className='min-w-120px'>Em andamento</th>
                <th className='min-w-100px'>Entregues</th>
                <th className='min-w-100px'>Progresso</th>
              </tr>
            </thead>

            <tbody>
              {statistics.map((user, i) => (
                <tr>
                  <td>
                    <div className='d-flex align-items-center'>
                      <OverlayTrigger
                        key={`${i}-${user.user_name}`}
                        placement='top'
                        overlay={<Tooltip id='tooltip-user-name'>{user.user_name}</Tooltip>}
                      >
                        <Link to={`/reports/sprint-user?userId=${user.user_id}`} target='_blank'>
                          <div className='symbol symbol-circle symbol-45px overflow-hidden me-5 '>
                            <div className='symbol-label'>
                              {user.user_avatar ? (
                                <img
                                  src={
                                    process.env.REACT_APP_THEME_API_URL +
                                    '/storage/' +
                                    user.user_avatar
                                  }
                                  alt={user.user_name}
                                  className='w-100 object-fit-cover'
                                />
                              ) : (
                                <span className='fw-bold'>{user.user_name.charAt(0)}</span>
                              )}
                            </div>
                          </div>
                        </Link>
                      </OverlayTrigger>
                      <div className='d-flex justify-content-start flex-column'>
                        <Link to={`/reports/sprint-user?userId=${user.user_id}`} target='_blank'>
                          <span className='text-dark fw-bold text-hover-primary fs-6'>
                            {user.user_name}
                          </span>
                        </Link>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          {user.user_job_role}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span
                      role='button'
                      className='text-dark fw-bold text-hover-primary d-block fs-6'
                      onClick={() => handleTaskStatusClick('', user.user_id)}
                    >
                      {user.total_tasks}
                    </span>
                  </td>
                  <td>
                    <span
                      role='button'
                      className='text-dark fw-bold text-hover-primary d-block fs-6'
                      onClick={() => handleTaskStatusClick('Backlog', user.user_id)}
                    >
                      {user.tasks_backlog}
                    </span>
                  </td>
                  <td>
                    <span
                      role='button'
                      className='text-dark fw-bold text-hover-primary d-block fs-6'
                      onClick={() => handleTaskStatusClick('Em andamento', user.user_id)}
                    >
                      {user.tasks_in_progress}
                    </span>
                  </td>
                  <td>
                    <span
                      role='button'
                      className='text-dark fw-bold text-hover-primary d-block fs-6'
                      onClick={() => handleTaskStatusClick('Entregue', user.user_id)}
                    >
                      {user.tasks_delivered}
                    </span>
                  </td>
                  <td className='text-end'>
                    <div className='d-flex flex-column w-100 me-2'>
                      <div className='d-flex flex-stack mb-2'>
                        <span className='text-muted me-2 fs-7 fw-semibold'>
                          {user.progress_percentage}%
                        </span>
                      </div>
                      <div className='progress h-6px w-100'>
                        <div
                          className={`progress-bar ${
                            user.progress_percentage >= 80 ? 'bg-success' : 'bg-danger'
                          }`}
                          role='progressbar'
                          style={{width: `${user.progress_percentage}%`}}
                        ></div>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {userId && showModal && (
        <TasksModal
          show={showModal}
          handleClose={() => setShowModal(false)}
          userId={userId}
          status={status}
        />
      )}
    </div>
  )
}
