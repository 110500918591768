import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {TasksTable} from './table/TasksTable'
import {KTCard} from '../../../../../helpers'
import {FC, useState} from "react"
import { Task } from './core/_models'

type Props =
 {
  userId: String
  dateSelected: String
}


const TasksList: FC<Props> = ({userId, dateSelected}) =>  {
  
  return (
    <>
      <KTCard>
        <TasksTable userId={userId} dateSelected={dateSelected}/>
      </KTCard>
    </>
  )
}

const TasksListWrapper: FC<Props> = ({userId, dateSelected}) => (
  <QueryRequestProvider>
      <ListViewProvider>
        <TasksList userId={userId} dateSelected={dateSelected}/>
      </ListViewProvider>
  </QueryRequestProvider>
)

export {TasksListWrapper}
