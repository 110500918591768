/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useRef} from 'react'
import {getCSSVariableValue} from '../../../../assets/ts/_utils'
import {useThemeMode} from '../../../layout/theme-mode/ThemeModeProvider'

type SprintReportSummary = {
  id?: number
  name?: string
  total_tasks: number
  user_name?: string
  begin_date: string
  end_date: string
  total_sprints?: number
  total_tasks_for_user?: number
  tasks_delivered: number
  tasks_in_progress: number
  tasks_backlog: number
}

type Props = {
  className: string
  chartSize?: number
  chartLine?: number
  chartRotate?: number
  showSprintsNumber?: boolean
  summary: SprintReportSummary
}

function calculateTaskPercentage(tasksNumber: number, totalTasks: number) {
  const percentage = (tasksNumber * 100) / (totalTasks ?? 1)
  return percentage
}

const CardSprintTasks: FC<Props> = ({
  className,
  chartSize = 70,
  chartLine = 11,
  chartRotate = 145,
  summary,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  useEffect(() => {
    refreshChart()
  }, [mode])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    setTimeout(() => {
      initChart(
        chartSize,
        chartLine,
        chartRotate,
        summary.tasks_backlog,
        summary.tasks_in_progress,
        summary.tasks_delivered,
        summary.total_tasks
      )
    }, 10)
  }

  return (
    <div className={`card card-flush ${className}`}>
      <div className='card-header justify-content-start pt-5'>
        <div className='card-title d-flex flex-column w-100'>
          <div className='d-flex align-items-center justify-content-between w-100'>
            <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>
              {summary.total_tasks} Tarefas
            </span>
            {summary.total_sprints && (
              <span className='text-dark pt-1 fw-semibold fs-3'>
                Em {summary.total_sprints} Sprints
              </span>
            )}
          </div>
          <span className='text-gray-400 pt-1 fw-semibold fs-6'>
            Expectativa de quantidade de tarefas
          </span>
        </div>
      </div>

      <div className='card-body pt-2 pb-4 d-flex flex-wrap align-items-center'>
        <div className='d-flex flex-center me-5 pt-2'>
          <div
            id='kt_card_widget_17_chart'
            ref={chartRef}
            style={{minWidth: chartSize + 'px', minHeight: chartSize + 'px'}}
            data-kt-size={chartSize}
            data-kt-line={chartLine}
          ></div>
        </div>

        <div className='d-flex flex-column content-justify-center flex-row-fluid'>
          <div className='d-flex fw-semibold align-items-center'>
            <div className='bullet w-8px h-3px rounded-2 bg-success me-3'></div>
            <div className='fs-4 text-gray-500 flex-grow-1 me-4'>Entregues</div>
            <div className='d-flex'>
              <div className='fs-4 fw-bolder text-gray-700 text-xxl-end me-2'>
                {summary.tasks_delivered}
              </div>
              <span className='badge badge-light-success fs-base'>
                {calculateTaskPercentage(summary.tasks_delivered, summary.total_tasks).toFixed(2)} %
              </span>
            </div>
          </div>
          <div className='d-flex fw-semibold align-items-center my-3'>
            <div className='bullet w-8px h-3px rounded-2 bg-primary me-3'></div>
            <div className='fs-4 text-gray-500 flex-grow-1 me-4'>Em andamento</div>
            <div className='d-flex'>
              <div className='fs-4 fw-bolder text-gray-700 text-xxl-end me-2'>
                {summary.tasks_in_progress}
              </div>
              <span className='badge badge-light-primary fs-base'>
                {calculateTaskPercentage(summary.tasks_in_progress, summary.total_tasks).toFixed(2)}{' '}
                %
              </span>
            </div>
          </div>
          <div className='d-flex fw-semibold align-items-center'>
            <div className='bullet w-8px h-3px rounded-2 me-3 bg-secondary'></div>
            <div className='fs-4 text-gray-500 flex-grow-1 me-4'>Não iniciadas</div>
            <div className='d-flex'>
              <div className='fs-4 fw-bolder text-gray-700 text-xxl-end me-2'>
                {summary.tasks_backlog}
              </div>
              <span className='badge badge-secondary fs-base'>
                {calculateTaskPercentage(summary.tasks_backlog, summary.total_tasks).toFixed(2)} %
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const initChart = function (
  chartSize: number = 70,
  chartLine: number = 11,
  chartRotate: number = 145,
  backlogTasks: number,
  inProgressTasks: number,
  deliveredTasks: number,
  totalTasks: number
) {
  const el = document.getElementById('kt_card_widget_17_chart')
  if (!el) {
    return
  }
  el.innerHTML = ''

  var options = {
    size: chartSize,
    lineWidth: chartLine,
    rotate: chartRotate,
    //percent:  el.getAttribute('data-kt-percent') ,
  }

  const canvas = document.createElement('canvas')
  const span = document.createElement('span')

  // @ts-ignore
  if (typeof G_vmlCanvasManager !== 'undefined') {
    // @ts-ignore
    G_vmlCanvasManager.initElement(canvas)
  }

  const ctx = canvas.getContext('2d')
  canvas.width = canvas.height = options.size

  el.appendChild(span)
  el.appendChild(canvas)

  // @ts-ignore
  ctx.translate(options.size / 2, options.size / 2) // change center
  // @ts-ignore
  ctx.rotate((-1 / 2 + options.rotate / 180) * Math.PI) // rotate -90 deg

  //imd = ctx.getImageData(0, 0, 240, 240);
  const radius = (options.size - options.lineWidth) / 2

  const drawCircle = function (color: string, lineWidth: number, percent: number) {
    percent = Math.min(Math.max(0, percent || 1), 1)
    if (!ctx) {
      return
    }

    ctx.beginPath()
    ctx.arc(0, 0, radius, 0, Math.PI * 2 * percent, false)
    ctx.strokeStyle = color
    ctx.lineCap = 'round' // butt, round or square
    ctx.lineWidth = lineWidth
    ctx.stroke()
  }

  // Init 2
  drawCircle(getCSSVariableValue('--kt-secondary'), options.lineWidth, 1)
  drawCircle(
    getCSSVariableValue('--kt-primary'),
    options.lineWidth,
    1 - calculateTaskPercentage(backlogTasks, totalTasks) / 100
  )
  drawCircle(
    getCSSVariableValue('--kt-success'),
    options.lineWidth,
    1 -
      calculateTaskPercentage(inProgressTasks, totalTasks) / 100 -
      calculateTaskPercentage(backlogTasks, totalTasks) / 100
  )
}

export {CardSprintTasks}
