import {useSearchParams} from 'react-router-dom'
import {Task} from '../../../../../../app/modules/reports/components/sprint-team/core/_models'

type Props = {
  task: Task
}

export function TaskNameCell({task}: Props) {
  const [searchParams, setSearchParams] = useSearchParams()

  const openDetails = (taskId: string) => {
    if (taskId) {
      const newParams = new URLSearchParams(searchParams)
      newParams.set('taskId', taskId.toString())
      setSearchParams(newParams)
    }
  }

  return (
    <div className='d-flex flex-column justify-content-center'>
      <p
        className='text-gray-900 fs-5 mb-0 cursor-pointer'
        onClick={() => openDetails(task.id?.toString()!)}
      >
        {task.name}
      </p>
    </div>
  )
}
