import {TasksModalContent} from './TasksModalContent'

type Props = {
  handleClose: () => void | undefined
  userId?: number
  status: string
}

export function TasksModalContentWrapper({handleClose, userId, status}: Props) {
  return <TasksModalContent handleClose={handleClose} userId={userId} status={status} />
}
