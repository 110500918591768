import {FC, useEffect, useRef} from 'react'
import {UserModalContent} from '../../../../_metronic/partials/modals/user-create-modal-stepper/UserModalContent'
import {initialUser} from '../../../../_metronic/partials/modals/user-create-modal-stepper/core/_models'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'

const Step2: FC = () => {
  const stepperRefUserForm = useRef<HTMLDivElement | null>(null)
  const stepperUserForm = useRef<StepperComponent | null>(null)
  const loadStepper = () => {
    stepperUserForm.current = StepperComponent.createInsance(stepperRefUserForm.current as HTMLDivElement)
    console.log(stepperRefUserForm.current)
  }
  
  useEffect(() => {
    loadStepper()
  }, [stepperRefUserForm.current])

  // setTimeout(() => loadStepper(), 10000)

  return (
    <div className='w-100'>
      {/* <!--begin::Heading--> */}
      <div className='pb-10 pb-lg-15'>
        {/* <!--begin::Title--> */}
        <h2 className='fw-bold text-dark'>Gerenciando usuários</h2>
        {/* <!--end::Title--> */}
        {/* <!--begin::Notice--> */}
        <div className='text-gray-700 fw-semibold fs-5'>
          Inicialmente, vamos configurar a jornada de trabalho do seu usuário e alguns dados
          importantes. Lembre-se de que essas primeiras infomações são essenciais para que o
          TaskRush te ajude a gerenciar os projetos e tarefas.{' '}
          {/* <a href='#' className='link-primary fw-bold'>
            Clique aqui e veja um tutorial sobre o gerenciamento de usuários
          </a>
          . */}
        </div>
        {/* <!--end::Notice--> */}
      </div>
      {/* <!--end::Heading--> */}
      <div className='fv-row'>
        {/* <!--begin::Row--> */}
        <div className='row'>
          {/* <!--begin::Col--> */}
          <div className='col-12 bg-body'>
            <UserModalContent userId={undefined} user={initialUser} stepper={stepperUserForm} stepperRef={stepperRefUserForm} />
          </div>
          {/* <!--end::Col--> */}
        </div>
        {/* <!--end::Row--> */}
      </div>
    </div>
  )
}

export {Step2}
