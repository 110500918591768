/* eslint-disable jsx-a11y/anchor-is-valid */
import {useIntl} from 'react-intl'
import {StepProps, User} from '../core/_models'
import {ChangeEvent, useEffect, useState} from 'react'

export function ConfirmCostHourEdit({data, updateData, user}: Partial<StepProps> & {user: User}) {
  const intl = useIntl()
  const [selectedOption, setSelectedOption] = useState('current')
  useEffect(() => {
    if (updateData) updateData({option: selectedOption})
  }, [selectedOption])

  return (
    <div className='pb-5'>
      <div className='w-100'>
        <div className='fv-row mt-5'>
          <div className='col-12'>
            <div className='alert alert-warning mt-0 mb-6' role='alert'>
              <p className='mb-0'>
                Este usuário possui um valor hora de{' '}
                {intl.formatNumber(user.cost_hour, {
                  style: 'currency',
                  currency: 'BRL',
                })}{' '}
                e será alterado para{' '}
                {intl.formatNumber(data?.cost_hour!, {
                  style: 'currency',
                  currency: 'BRL',
                })}
                . Deseja corrigir seu custo/hora desde quando esse usuário ingressou na equipe, ou
                daqui em diante?
              </p>
              <p className='mb-0'>
                Esta alteração pode impactar nos custos já lançados nos projetos em que esse usuário
                trabalhou.
              </p>
            </div>

            <div className='form-check form-check-custom form-check-solid'>
              <input
                className='form-check-input me-3'
                name='role'
                type='radio'
                value='current'
                id='kt_modal_update_role_option_0'
                checked={selectedOption === 'current'}
                onChange={(e) => setSelectedOption(e.target.value)}
              />

              <label className='form-check-label' htmlFor='kt_modal_update_role_option_0'>
                <div className='fw-bolder text-gray-800'>
                  Atualizar o valor/hora a partir da data de hoje.
                </div>
              </label>
            </div>

            <div className='form-check form-check-custom form-check-solid mt-2'>
              <input
                className='form-check-input me-3'
                name='role'
                type='radio'
                value='all'
                id='kt_modal_update_role_option_1'
                checked={selectedOption === 'all'}
                onChange={(e) => setSelectedOption(e.target.value)}
              />

              <label className='form-check-label' htmlFor='kt_modal_update_role_option_1'>
                <div className='fw-bolder text-gray-800'>
                  Atualizar o valor/hora desde quando o usuário foi cadastrado.
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
