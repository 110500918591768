import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../../../_metronic/helpers'
import {ProjectCycleView} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const PROJECT_URL = `${API_URL}/api/projetos`

const getProjectCycleView = (id: ID, query: string): Promise<ProjectCycleView> => {
  return axios
    .get(`${PROJECT_URL}/${id}/cycle-view?${query}`)
    .then((response: AxiosResponse<Response<ProjectCycleView>>) => response.data)
    .then((response: Response<ProjectCycleView>) => response.data)
}

export {getProjectCycleView}
