import { FC, CSSProperties, useEffect, useState } from 'react'
import {useQuery} from 'react-query'
import {useSearchParams} from 'react-router-dom'
import {OptionProps, components} from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import CreatableSelect from 'react-select/creatable';
import {FilterOptionOption} from 'react-select/dist/declarations/src/filters';
import {SingleValue} from 'react-select/dist/declarations/src';
import {useIntl} from 'react-intl';
import {getData} from './core/_requests';
import {QUERIES} from '../../../helpers';
import {useAuth} from '../../../../app/modules/auth';

//Interfaces 

interface Options {
  label: string;
  value?: string;
  __isNew__?: string;
  options?: readonly Options[];
}

interface GroupedOptions {
  label: string;
  options: readonly Options[];
}

//Types 

type Props = {
  value?: Options,
  optionsGroup?: GroupedOptions[],
  endPoint?: string,
  async?: boolean,
  placeholder?: string,
  onCreateOption?: (inputValue: string) => void,
  onChange?: (newValue: SingleValue<Options>) => void
}

//Styles
const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const groupBadgeStyles: CSSProperties = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

const SelectAutoComplete: FC<Props> = ({ value = null, onCreateOption, placeholder = 'Selecione', endPoint = '', async = false, onChange }) => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const [simpleOption, setSimpleOption] = useState<Options[]>([])
  const [searchParams, setSearchParams] = useSearchParams()
  const [inputValue, setInputValue] = useState<Options | null>(value)
  const { Option } = components;

  const {
    isFetching,
    data: data,
    error,
  } = useQuery(
    `${QUERIES.SELECT_AUTOCOMPLETE_LIST}-${endPoint}`,
    () => {
      return getData(endPoint)
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setSimpleOption(data);    
      },
      onError: (err) => {
        console.error(err)
      },
    }
  )

  useEffect(() => {
    if(searchParams.get('_new')) {
      var newModel: {value?: string, label?: string} = {value: undefined, label: undefined}
      var isGroup = simpleOption.filter(function (option) {
        return option.hasOwnProperty('options');
      }).length > 0;
      if(isGroup) {
        simpleOption.map(group => {
          if(!newModel.value) {
            newModel = group.options?.find(option => {return option.value == searchParams.get('_new')}) ?? {value: '', label: ''}
          }
          return
        })
      } else {
        newModel = simpleOption.find(option => option.value == searchParams.get('_new')) ?? {value: '', label: ''}
      }
      
      if(newModel) {
        setInputValue({value: newModel.value, label: newModel.label!})
        onChange!({value: newModel.value, label: newModel.label!})
      }
    }
  }, [data])

  const updateInputValue = (newValue: SingleValue<Options>) => {
    setInputValue(newValue)
    onChange!(newValue)
  }

  const filterOptions = (inputValue: string) => {
    return simpleOption.filter((i) => {
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    }
    );
  };

  const promiseOptions = (inputValue: string) =>
    new Promise<Options[]>((resolve) => {
      setTimeout(() => {
        resolve(filterOptions(inputValue));
      }, 1000);
    });

  //Functions
  const formatGroupLabel = (data: GroupedOptions) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  const selectOption = (props: any) =>  {
    return ( 
      <Option {...props} className={`${props.data.__isNew__ && 'bg-primary rounded text-center fw-bold cursor-pointer py-3'}`}>
        {props.data.label}
      </Option>
    )
  }

  return (
    <>
      {async &&
        <>
          <AsyncCreatableSelect
            isDisabled={isFetching}
            isLoading={isFetching}
            value={inputValue}
            onCreateOption={onCreateOption}
            isClearable
            placeholder={placeholder}
            cacheOptions
            defaultOptions
            loadOptions={promiseOptions}
            onChange={newValue => updateInputValue(newValue)}
            formatCreateLabel={(val: string) => currentUser?.administrator ? `${intl.formatMessage({id: 'GENERAL.ADD'})} ${val}` : undefined}
            noOptionsMessage={() => intl.formatMessage({id: 'GENERAL.LABEL.NO_RECORD_FOUND'})}
            classNames={{
              control: (state) =>
                'form-control form-control-solid py-1',
              input: (state) =>
                'text-dark',
              singleValue: (state) =>
                'text-dark',
              menu: (state) =>
              'bg-body'
            }}
            styles={{
              option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                return {
                  ...styles,
                  backgroundColor: isDisabled
                    ? undefined
                    : isSelected
                    ? '#369bff70'
                    : isFocused
                    ? '#369bff70'
                    : undefined,
                  color: isDisabled
                    ? '#ccc'
                    : isSelected
                    ? '#181C32'
                    : undefined,
                  cursor: data.__isNew__
                    && 'pointer',
                  display: (data.__isNew__ && !currentUser?.administrator) ? 'none' : ''
                };
              },
            }}
            filterOption={(options: FilterOptionOption<Options>, inputValue: string) => {
              inputValue = inputValue.normalize('NFD').replace(/\p{Diacritic}/gu, '').toLocaleLowerCase()
              var label = options.data.label ? options.data.label.normalize('NFD').replace(/\p{Diacritic}/gu, '').toLocaleLowerCase() : ''

              if (label.includes(inputValue)) {
                return true
              }

              const groupOptions = simpleOption.filter((group) =>
                group.label.normalize('NFD').replace(/\p{Diacritic}/gu, '').toLocaleLowerCase().includes(inputValue)
              )

              if (groupOptions) {
                for (const groupOption of groupOptions) {
                  const option = groupOption.options?.find((opt) => opt.label.normalize('NFD').replace(/\p{Diacritic}/gu, '').toLocaleLowerCase() === label)
                  if (option) {
                    return true
                  }
                }
              }
              return false
            }}
            components={{ Option: selectOption }}
          />
        </>
      }
      {!async &&
        <>
          <CreatableSelect
            isDisabled={isFetching}
            isLoading={isFetching}
            onCreateOption={onCreateOption}
            value={inputValue}
            isClearable
            placeholder={placeholder}
            options={simpleOption} 
            onChange={newValue => updateInputValue(newValue)}
            formatCreateLabel={(val: string) => currentUser?.administrator ? `${intl.formatMessage({id: 'GENERAL.ADD'})} ${val}` : undefined}
            noOptionsMessage={() => intl.formatMessage({id: 'GENERAL.LABEL.NO_RECORD_FOUND'})}
            classNames={{
              control: (state) =>
                'form-control form-control-solid py-1',
              input: (state) =>
                'text-dark',
              singleValue: (state) =>
                'text-dark',
              menu: (state) =>
              'bg-body'
            }}
            styles={{
              option: (styles, { data, isDisabled, isFocused, isSelected,  }) => {
                return {
                  ...styles,
                  backgroundColor: isDisabled
                    ? undefined
                    : isSelected
                    ? '#369bff70'
                    : isFocused
                    ? '#369bff70'
                    : undefined,
                  color: isDisabled
                    ? '#ccc'
                    : isSelected
                    ? '#181C32'
                    : undefined,
                  cursor: data.__isNew__
                    && 'pointer',
                  display: (data.__isNew__ && !currentUser?.administrator) ? 'none' : ''
                };
              },
            }}
            filterOption={(options: FilterOptionOption<Options>, inputValue: string) => {
              inputValue = inputValue.normalize('NFD').replace(/\p{Diacritic}/gu, '').toLocaleLowerCase()
              var label = options.data.label ? options.data.label.normalize('NFD').replace(/\p{Diacritic}/gu, '').toLocaleLowerCase() : ''

              if (label.includes(inputValue)) {
                return true
              }

              const groupOptions = simpleOption.filter((group) =>
                group.label.normalize('NFD').replace(/\p{Diacritic}/gu, '').toLocaleLowerCase().includes(inputValue)
              )

              if (groupOptions) {
                for (const groupOption of groupOptions) {
                  const option = groupOption.options?.find((opt) => opt.label.normalize('NFD').replace(/\p{Diacritic}/gu, '').toLocaleLowerCase() === label)
                  if (option) {
                    return true
                  }
                }
              }
              return false
            }}
            components={{ Option: selectOption }}
          />
        </>
      }
    </>
  )
}

export { SelectAutoComplete }
