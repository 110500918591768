import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useIntl} from 'react-intl'
import {useQueryClient} from 'react-query'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {Project, initialProjectExtraCost} from './core/_models'
import {closeProjectById, countTasksByProjectId} from './core/_requests'
import {Toast} from '../../../../../../_metronic/partials/widgets'
import {ID} from '../../../../../../_metronic/helpers'
import {Loading} from '../../../../../../_metronic/partials/components/loading/Loading'
import {Link} from 'react-router-dom'
import {Modal} from 'react-bootstrap'
import {getProjectById} from '../../core/_requests'

type Props = {
  project_id: ID
  handleClose: () => void
}

const projectExtraCostSchema = Yup.object().shape({
  closed_at: Yup.date().required('O campo data é obrigatório'),
})

const ProjectCloseModalForm = ({handleClose, project_id}: Props) => {
  const intl = useIntl()
  var today = new Date().toISOString().split('T')[0]
  const queryClient = useQueryClient()
  const [isConfirmationStep, setIsConfirmationStep] = useState(false)
  const [date, setDate] = useState(today)
  const [countTasks, setCountTasks] = useState(0)

  const [projectExtraCostEdit] = useState<Project>({
    closed_at: initialProjectExtraCost.closed_at,
    project_id: initialProjectExtraCost?.project_id!,
  })

  useEffect(() => {
    formik.setFieldValue('project_id', project_id)
  }, [])

  const formik = useFormik({
    initialValues: projectExtraCostEdit,
    validationSchema: projectExtraCostSchema,
    onSubmit: async (values, {setSubmitting, resetForm, setFieldValue}) => {
      setSubmitting(true)
      try {
        await closeProjectById(values)
        Toast.fire({
          icon: 'success',
          title: intl.formatMessage(
            {id: 'ALERT.LABEL.SUCCESS_CLOSE_ITEM'},
            {item: intl.formatMessage({id: 'TABLE.GENERAL.LABEL.PROJECT'})}
          ),
          customClass: {
            popup: 'bg-light-success',
            title: 'text-success',
          },
        })
        queryClient.invalidateQueries()
        // queryClient.invalidateQueries([`${QUERIES.PROJECT_CLOSE_LIST}`])
        // queryClient.invalidateQueries([`${QUERIES.PROJECT_CLOSE_LIST}-project-${project_id}`])
        resetForm()
        handleClose()
      } catch (error: any) {
        Toast.fire({
          icon: 'error',
          title: error.response.data.message,
          customClass: {
            popup: 'bg-light-danger',
            title: 'text-danger',
          },
        })
      } finally {
        setSubmitting(true)
        setIsConfirmationStep(false)
      }
    },
  })

  async function onSubmit(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault()
    formik.setFieldValue('closed_at', date)
    const currentProject = await getProjectById(project_id)

    if (currentProject.type === 1) {
      formik.submitForm()
      return
    }

    const data = await countTasksByProjectId(project_id!, date)
    setIsConfirmationStep(true)
    setCountTasks(data)
  }

  return (
    <>
      <form
        id='kt_modal_add_project_milestone_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        {!isConfirmationStep ? (
          <>
            <div className='row mb-5'>
              <div className='col-md-6'>
                <label htmlFor='closed_at' className='d-block fw-bold fs-6 mb-2'>
                  {intl.formatMessage({id: 'FORM.INPUT.NAME.DATE'})}
                </label>

                <input
                  {...formik.getFieldProps('closed_at')}
                  type='date'
                  name='closed_at'
                  id='closed_at'
                  max={today}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.closed_at && formik.errors.closed_at},
                    {
                      'is-valid': formik.touched.closed_at && !formik.errors.closed_at,
                    }
                  )}
                  value={date}
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  onChange={(e) => setDate(e.target.value)}
                />
                {formik.touched.closed_at && formik.errors.closed_at && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.closed_at}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='text-center pt-7'>
              <button type='button' className='btn btn-primary btn-sm px-8' onClick={onSubmit}>
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'PROJECT.LABEL.CLOSE_MODAL'})}
                </span>
              </button>
            </div>
          </>
        ) : (
          <>
            <div className='alert alert-warning mb-0' role='alert'>
              <p className='mb-0'>
                Você deseja fechar o projeto na data{' '}
                {new Date(date).toLocaleDateString(intl.locale, {
                  timeZone: 'UTC',
                })}
                , porém, há{' '}
                <Link
                  className='alert-warning text-hover-warning'
                  to={`/projects/${project_id}/tasks`}
                >
                  <strong>
                    <u>{countTasks} tarefas</u>
                  </strong>
                </Link>{' '}
                neste projeto que foram cadastradas após esta data. Se o projeto for fechado, você
                ainda poderá consultar essas tarefas posteriormente, porém, elas não serão exibidas
                nos relatórios de ciclos que existem após a data de fechamento. Você deseja
                continuar ?
              </p>
            </div>

            <Modal.Footer className='pe-0 pb-0'>
              <button
                type='button'
                onClick={handleClose}
                className='btn btn-outline-secondary py-2'
              >
                Cancelar
              </button>
              <button
                type='submit'
                className='btn btn-primary py-2'
                data-kt-users-modal-action='submit'
                disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
              >
                <span className='indicator-label'>Sim, fechar.</span>
                {formik.isSubmitting && (
                  <span className='indicator-progress'>
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}{' '}
              </button>
            </Modal.Footer>
          </>
        )}
      </form>
      {formik.isSubmitting && <Loading />}
    </>
  )
}

export {ProjectCloseModalForm}
