/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useAuth} from '../../modules/auth'
import {DashboardManagerPage} from './dashboard-manager/DashboardManagerPage'
import {DashboardUserPage} from './dashboard-user/DashboardUserPage'

const DashboardPageWrapper: FC = () => {
  const {currentUser} = useAuth()

  return (
    <>
      {
        currentUser?.administrator
        ?
        <DashboardManagerPage />
        :
        <DashboardUserPage />
      }
    </>
  )
}

export {DashboardPageWrapper}
