import {useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {ListViewProvider, useListView} from '../core/ListViewProvider'
import {QueryRequestProvider} from '../core/QueryRequestProvider'
import {MyTasksTable} from './table/MyTasksTable'
import {KTCard} from '../../../../_metronic/helpers'
import {QueryResponseProvider, useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {PageTitle, PageToolbar} from '../../../../_metronic/layout/core'
import {TaskModal} from '../../../../_metronic/partials/modals/task/TaskModal'
import {MyTasksListHeader} from './header/MyTasksListHeader'
import { TasksKanban } from './kanban/TasksKanban'

const MyTasksList = () => {
  const intl = useIntl()
  const {taskIdForUpdate} = useListView()
  const isLoading = useQueryResponseLoading()

  const data = useQueryResponseData()
  const [total_clients, setTotalClients] = useState<number | undefined>()

  useEffect(() => {
    data.forEach((rs) => {
      setTotalClients(rs.id!)
    })
  }, [data])

  const [showModalStepper, setShowModalStepper] = useState<boolean>(false)

  const buttons = [
    {
      text: intl.formatMessage({id: 'TOOLBAR.NEW_TASK'}),
      onClick: () => setShowModalStepper(true),
      class: 'btn btn-sm fw-bold btn-primary',
    },
  ]

  var url_atual = window.location.href
  let result = url_atual.includes('dashboard')

  return (
    <>
      {result === false ? (
        <>
          <PageTitle>{intl.formatMessage({id: 'MENU.MYTASKS'})}</PageTitle>
          <PageToolbar buttons={buttons} />
        </>
      ) : null}
      <KTCard className='bg-transparent'>
        <MyTasksListHeader />
        <div className={`${isLoading && 'pe-none'} tab-content`}>
          <MyTasksTable />
          <TasksKanban />
        </div>
      </KTCard>
      <TaskModal
        show={showModalStepper}
        handleClose={() => setShowModalStepper(false)}
      />
    </>
  )
}

const MyTasksListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <MyTasksList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {MyTasksListWrapper}
