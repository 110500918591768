import { ID,Response } from "../../../../../../_metronic/helpers"
import { Client } from "../../../../clients/components/core/_models"
import { Project } from "../../../../project/components/core/_models"

export type Contracts = {
  id?: ID
  name_contracts?: string
  client_id?: number
  title?: string
  project_id?: number
  client?: Client
  project?: Project
  size?: number
  last_modified?: string
  begin_date?: string
  end_date?: string
  type?: number
  value?: string
  file?: string
  expire?: string
  obs?: string
}

export type ResponseContract = {
  contracts: Contracts
  message: string
  errors: boolean
}

export type ContractsQueryResponse = Response<Array<Contracts>>

export const initialContract: Contracts = {
  id: undefined,
  name_contracts: '',
  client_id: 1,
  value: '0'
}
