import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../helpers'
import {TimeTracking, TimeTrackingQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const TIME_TRACKING_USER_URL = `${API_URL}/api/tarefas/timetracking`


const updateTime = (timeTracking: TimeTracking): Promise<TimeTracking | undefined> => {

  let formData = new FormData();
  if(timeTracking.total_time){
    formData.append('total_time', timeTracking.total_time.toString());
  }
  if(timeTracking.type) {
    formData.append('type', timeTracking.type.toString());
  }
  if(timeTracking.task_id) {
    formData.append('task_id',timeTracking.task_id.toString());
  }
  if(timeTracking.date) {
    formData.append('date',timeTracking.date);
  }

  return axios
      .post(`${TIME_TRACKING_USER_URL}/adjust`, timeTracking)
      .then((response: Response<TimeTracking>) => response.data)
}


export {updateTime}
