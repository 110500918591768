import axios, {AxiosResponse} from 'axios'
import {ID, QueryState, Response} from '../../../../../../../_metronic/helpers'
import {CloneQueryResponse, ResponseTaskChecklist, TaskChecklist, TasksQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const GET_TASKCHECKLIST_URL = `${API_URL}/api/checklist`

const getChecklist = (id: ID): Promise<TasksQueryResponse> => {
  return axios
      .get(`${GET_TASKCHECKLIST_URL}/${id}`)
      .then((d: AxiosResponse<TasksQueryResponse>) => d.data)
}


const getChecklistClone = (id: ID): Promise<CloneQueryResponse> => {
  return axios
      .get(`${GET_TASKCHECKLIST_URL}/${id}/clone`)
      .then((d: AxiosResponse<CloneQueryResponse>) => d.data)
}

const concludedTaskCheckList = (id: ID): Promise<TasksQueryResponse> => {
  return axios
      .get(`${GET_TASKCHECKLIST_URL}/${id}/concluded`)
      .then((d: AxiosResponse<TasksQueryResponse>) => d.data)
}

const createTaskChecklist = (taskId: ID): Promise<TasksQueryResponse> => {
  return axios
      .get(`${GET_TASKCHECKLIST_URL}/${taskId}/insert`)
      .then((d: AxiosResponse<TasksQueryResponse>) => d.data)
}

const updateNameTaskChecklist = (id: ID, query: string): Promise<TasksQueryResponse> => {
  return axios
      .get(`${GET_TASKCHECKLIST_URL}/${id}/${query}/updatenametaskchecklist`)
      .then((d: AxiosResponse<TasksQueryResponse>) => d.data)
}


const deleteTaskCheckList = (taskId: ID): Promise<void> => {
  return axios.delete(`${GET_TASKCHECKLIST_URL}/${taskId}/excluir`).then(() => {})
}

const getUsers = (): Promise<TasksQueryResponse> => {
  return axios
      .get(`${GET_TASKCHECKLIST_URL}/getUsers`)
      .then((d: AxiosResponse<TasksQueryResponse>) => d.data)
}

export {createTaskChecklist, getChecklist, getChecklistClone, concludedTaskCheckList, deleteTaskCheckList, getUsers, updateNameTaskChecklist}
