/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {Task} from '../../core/_models'
import {Link, useSearchParams} from 'react-router-dom'

type Props = {
  task: Task
}

const TaskNameCell: FC<Props> = ({task}) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const openDetails = (taskId: string) => {
    if (taskId) {
      const newParams = new URLSearchParams(searchParams)
      newParams.set('taskId', taskId.toString())
      setSearchParams(newParams)
    }
  }

  return (
    <>
      <div className='d-flex flex-column justify-content-center'>
        <Link to={`/projects/${task.project_id}`}>
          <span className='text-uppercase text-semi-bold text-gray-600 fs-7'>
            {task.project_name}
          </span>
        </Link>
        <p
          className='text-gray-900 fs-5 mb-0 cursor-pointer'
          onClick={() => openDetails(task.task_id?.toString()!)}
        >
          {task.task_name}
        </p>
      </div>
    </>
  )
}

export {TaskNameCell}
