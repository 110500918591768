import {KTSVG} from '../../../helpers'
import {useIntl} from 'react-intl'
import {TasksList} from './TasksList'

type Props = {
  handleClose: () => void | undefined
  userId?: number
  status: string
}

export function TasksModalContent({handleClose, userId, status}: Props) {
  const intl = useIntl()

  return (
    <div className='h-600px'>
      <div className='modal-header border-0'>
        <div className='col-md-11'>
          <div className='fw-bold fs-2'>
            {intl.formatMessage({id: 'TIME_TRAKING.MODAL.LABEL.SPRINT_REPORT.TITLE'})}
          </div>
        </div>
        <div className='col-md-1 d-flex justify-content-end align-items-center'>
          <button
            className='btn btn-sm btn-icon btn-active-color-primary h-auto ms-4'
            onClick={handleClose}
          >
            <KTSVG
              className='svg-icon-2x svg-icon-gray-800'
              path='/media/icons/duotune/arrows/arr061.svg'
            />
          </button>
        </div>
      </div>
      <div className='modal-body'>
        <div className='tab-content pt-5'>
          <div className='tab-pane fade active show' id='kt_tab_pane_1' role='tabpanel'>
            <TasksList userId={userId} status={status} />
          </div>
        </div>
      </div>
    </div>
  )
}
