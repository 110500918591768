// @ts-nocheck
import clsx from 'clsx'
import {FC, useState} from 'react'
import {Row} from 'react-table'
import {useSortable} from '@dnd-kit/sortable'
import {CSS} from '@dnd-kit/utilities'
import {Task} from '../../core/_models'

type Props = {
  row: Row<Task>
}

const CustomRow: FC<Props> = ({row}) => {

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: row.original.id?.toString()})

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };


  return (
    <tr 
      {...row.getRowProps()} 
      ref={setNodeRef} 
      style={style}
      className="bg-hover-light cursor-default"
    >
      {row.cells.map((cell, i) => {
            if (cell.column.id === 'dnd-handle') {
              return (
                  <td
                      {...cell.getCellProps()}
                      {...attributes}
                      {...listeners}
                      key={i}
                  >
                    {cell.render('Cell')}
                  </td>
              )
            } else {
              return (
                  <td
                      {...cell.getCellProps()}
                      className={clsx({'text-end min-w-100px': cell.column.id === 'actions'})}
                      key={i}
                  >
                    {cell.render('Cell')}

                  </td>

              )
            }

          }
      )}

    </tr>
  )
} 

export {CustomRow}
