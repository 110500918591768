import {useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {ListViewProvider, useListView} from '../core/ListViewProvider'
import {QueryRequestProvider} from '../core/QueryRequestProvider'
import {TasksByUserTable} from './table/TasksByUserTable'
import {KTCard} from '../../../../_metronic/helpers'
import {QueryResponseProvider, useQueryResponseData} from '../core/QueryResponseProvider'
import {PageTitle, PageToolbar} from '../../../../_metronic/layout/core'
import {TaskModal} from '../../../../_metronic/partials/modals/task/TaskModal'
import {TasksByUserListHeader} from './header/TasksByUserListHeader'

const TasksByUserList = () => {
  const intl = useIntl()
  const {taskIdForUpdate} = useListView()

  const data = useQueryResponseData()
  const [total_clients, setTotalClients] = useState<number | undefined>()

  useEffect(() => {
    data.forEach((rs) => {
      setTotalClients(rs.id!)
    })
  }, [data])

  const [showModalStepper, setShowModalStepper] = useState<boolean>(false)


  return (
    <>
      <KTCard className='bg-transparent'>
        <TasksByUserListHeader />
        <div className='tab-content'>
          <TasksByUserTable />
        </div>
      </KTCard>
      <TaskModal
        show={showModalStepper}
        handleClose={() => setShowModalStepper(false)}
      />
    </>
  )
}

const TasksByUserIdListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <TasksByUserList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {TasksByUserIdListWrapper}
