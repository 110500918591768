import {FC, useMemo, useState} from 'react'
import {useTable, Row} from 'react-table'
import {CustomRow} from './columns/CustomRow'
import {tasksColumns} from './columns/_columns'
import {Task} from '../core/_models'
import {ID, KTCardBody, QUERIES, secondsToHours} from '../../../../../../helpers'
import {useIntl} from "react-intl";
import { useQuery } from 'react-query'
import { getTasks } from '../core/_requests'
import { AdjustTimeUserModal } from '../../../../adjust-time-user/AdjustTimeUserModal'


type Props =
 {
  userId: String
  dateSelected: String
}

const TasksTable: FC<Props> = ({userId, dateSelected}) =>  {
  const columns = useMemo(() => tasksColumns, [])
  const intl = useIntl()
  const [tasks, setTasks] = useState<Array<Task>>([])
  const [task_id, setTaskId] = useState<ID>()

  const {
    isLoading,
    data: tasks_data,
    error,
  } = useQuery(
    `${QUERIES.TASKS_TIMER_LIST}-${userId}-${dateSelected}`,
    () => {
      return getTasks(userId.toString(), dateSelected.toString())
    },
    {
      cacheTime: 0,
      onSuccess(data) {
        setTasks(data.tasks);
      },
      refetchOnWindowFocus: false
    }
  )

  const [showModalAdjustHours, setShowModalAdjustHours] = useState<boolean>(false)
  const openModal = (task_id: ID) => {
    setShowModalAdjustHours(true)
    setTaskId(task_id)
  }

  const data = useMemo(() => tasks, [tasks!])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  return (
    <>
    <KTCardBody className='p-4 pt-0 mh-350px overflow-scroll'>
      <div className='table-responsive'>
        <table
          id='kt_table_tasks'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer tab-pane fade show active'
          {...getTableProps()}
        >
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0  && !isLoading ? (
              rows.map((row: Row<Task>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    {intl.formatMessage({id: 'TABLE.GENERAL.NO_RECORD_FOUND'})}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </KTCardBody>
    {/* 
    //@ts-ignore */}
    <AdjustTimeUserModal
           show={showModalAdjustHours}
           handleClose={() => setShowModalAdjustHours(false)}
           taskId={task_id}
    />
    </>
  )
}

export {TasksTable}
