import { KTCard } from "../../../helpers"
import { TasksTable } from "./table/TasksTable"

type Props = {
  userId?: number
  status: string
}

export function TasksList({userId, status}: Props) {
  return (
    <KTCard>
      <TasksTable userId={userId} status={status}/>
    </KTCard>
  )
}