/* eslint-disable jsx-a11y/anchor-is-valid */
import {useIntl} from 'react-intl'
import {useEffect, useState} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {useSearchParams} from 'react-router-dom'
import {StepProps} from '../../../../../app/modules/project/components/core/_models'
import {Client} from '../../../../../app/modules/clients/components/core/_models'
import {getClients} from '../../../../../app/modules/clients/components/core/_requests'
import {stringifyRequestQuery} from '../../../../helpers'
import {useQueryRequest} from '../../../../../app/modules/project/components/core/QueryRequestProvider'
import {SelectAutoComplete} from '../../../components/Select/SelectAutoComplete'
import {ClientEditModal} from '../../client-edit-modal/ClientEditModal'

const Step1 = ({data, updateData, hasError}: StepProps) => {
  const intl = useIntl()
  const [clients, setClients] = useState<Client[]>([])
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const [showClientEditModal, setShowClientEditModal] = useState<boolean>(false)
  const [searchParams, setSearchParams] = useSearchParams()
  
  const getSelectClients = useMutation(() => getClients(query), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: (response) => {
      // ✅ update detail view directly
      setClients(response.data)
    },
  })

  useEffect(() => {
    getSelectClients.mutateAsync()
  }, [])

  const handleCreateClient = (newClientName: string) => {
    setSearchParams({new_client_name: newClientName})
    setShowClientEditModal(true)
  }

  const handleCloseClientEditModal = () => {
    setShowClientEditModal(false)
    // searchParams.delete('new_client_name')
    // setSearchParams(searchParams)
  }

  return (
    <>
    <div className='current' data-kt-stepper-element='content'>
      <div className='w-100'>
        {/*begin::Form Group */}
        <div className='fv-row mb-10'>
          <label htmlFor='project-name' className='d-flex align-items-center fs-5 fw-semibold mb-2'>
            <span className='required'>{intl.formatMessage({id: 'FORM.INPUT.NAME.PROJECT_NAME'})}</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title={intl.formatMessage({id: 'FORM.INPUT.TOOLTIP.PROJECT_NAME'})}
            ></i>
          </label>
          <input
            type='text'
            className='form-control text-gray-900 form-control-lg form-control-solid'
            name='project-name'
            id='project-name'
            placeholder=''
            autoComplete='off'
            value={data.name ?? ''}
            onChange={(e: any) => {
              updateData({
                name: e.target.value,
              })
            }}
          />
          {!data.name && hasError && (
            <div className='fv-plugins-message-container'>
              <div data-field='project-name' data-validator='notEmpty' className='fv-help-block'>
                {intl.formatMessage({id: 'FORM.INPUT.VALIDATION.REQUIRED'})}
              </div>
            </div>
          )}
        </div>
        {/*end::Form Group */}

        {/*begin::Form Group */}
        <div className='fv-row'>
          <label htmlFor="project-client" className='d-flex align-items-center fs-5 fw-semibold mb-2'>
            <span className='required'>{intl.formatMessage({id: 'FORM.INPUT.NAME.CLIENT'})}</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title={intl.formatMessage({id: 'FORM.INPUT.TOOLTIP.CLIENT'})}
            ></i>
          </label>
          <SelectAutoComplete 
            endPoint="clientes" 
            placeholder={intl.formatMessage({id: 'FORM.INPUT.TOOLTIP.CLIENT'})}
            onChange={(e) => {
              updateData({
                client_id: e?.value
              })
            }}
            onCreateOption={(inputValue) => handleCreateClient(inputValue)}
            // async={true}
          />
          {!data.client_id && hasError && (
            <div className='fv-plugins-message-container'>
              <div data-field='project-client' data-validator='notEmpty' className='fv-help-block'>
                {intl.formatMessage({id: 'FORM.INPUT.VALIDATION.REQUIRED'})}
              </div>
            </div>
          )}
        </div>
        {/*end::Form Group */}
      </div>
    </div>
    <ClientEditModal show={showClientEditModal} handleClose={handleCloseClientEditModal} clientId={data.clientId} />
    </>
  )
}

export {Step1}
