import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {User, UsersQueryResponse, ResponseUser} from './_models'
import {Client, ResponseClient} from '../../../../clients/components/core/_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const USER_URL = `${API_URL}/api/usuarios`
const GET_USERS_URL = `${API_URL}/api/usuarios `

const getUsers = (query: string): Promise<UsersQueryResponse> => {
  return axios.get(`${USER_URL}?${query}`).then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getUsersBySelect = (): Promise<UsersQueryResponse> => {
  return axios
    .get(`${USER_URL}/find-users-select`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getUserById = (id: ID): Promise<ResponseUser> => {
  return axios
    .get(`${USER_URL}/${id}`)
    .then((response: AxiosResponse<ResponseUser>) => response.data)
}

const createUser = (user: User): Promise<ResponseUser | undefined> => {
  return axios
    .post(`${USER_URL}/novo`, user)
    .then((response: AxiosResponse<ResponseUser>) => response.data)
}

const updateUser = (user: User, userId: ID): Promise<ResponseUser | undefined> => {
  return axios
    .post(`${USER_URL}/${userId}/editar`, user)
    .then((response: AxiosResponse<ResponseUser>) => response.data)
}

const filterByValue = (value: String): Promise<UsersQueryResponse | undefined> => {
  let formData = new FormData()
  if (value) {
    formData.append('value', value.toString())
  }
  return axios
    .post(`${USER_URL}/filter-by-value`, formData)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const updateAdministrator = async (checked: boolean, userId: ID): Promise<ResponseUser> => {
  return axios
    .get(`${USER_URL}/${userId}/${checked}/editaradministrador`)
    .then((response: AxiosResponse<ResponseUser>) => response.data)
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${userId}/excluir`).then(() => {})
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const updateHasTimeTracking = (checked: boolean, userId: ID): Promise<ResponseUser> =>
  axios
    .put(`${USER_URL}/${userId}/${checked}/editHasTimeTracking`)
    .then((response: AxiosResponse<ResponseUser>) => response.data)

const restoreUser = (userId: ID): Promise<void> => {
  return axios.post(`${USER_URL}/${userId}/restore`).then(() => {})
}

export {
  getUsers,
  deleteUser,
  updateAdministrator,
  deleteSelectedUsers,
  getUserById,
  createUser,
  updateUser,
  filterByValue,
  getUsersBySelect,
  updateHasTimeTracking,
  restoreUser,
}
