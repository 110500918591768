import axios, {AxiosResponse} from 'axios'
import {ID, QueryState, Response} from '../../../../_metronic/helpers'
import {ResponseTask, Task, TasksQueryResponse} from './_models'
import {ResponseTaskAttachement, TaskAttachement} from "../../project/components/tasks-list/TaskAttachment/core/_models";

const API_URL = process.env.REACT_APP_THEME_API_URL
const GET_TASK_URL = `${API_URL}/api/minhastarefas`
const GET_TASKATTACHEMENT_URL = `${API_URL}/api/minhastarefas/anexo`


const getTaskById = (id: ID): Promise<ResponseTask> => {
  return axios
      .get(`${GET_TASK_URL}/${id}`)
      .then((response: AxiosResponse<ResponseTask>) => response.data)
}

const getTasksList = (query: string): Promise<TasksQueryResponse> => {
  return axios
      .get(`${GET_TASK_URL}?${query}`)
      .then((d: AxiosResponse<TasksQueryResponse>) => d.data)
}

const updateNameTask = (task_id: ID, query: string): Promise<TasksQueryResponse> => {
  return axios
      .get(`${GET_TASK_URL}/${task_id}/${query}/updatenamecell`)
      .then((d: AxiosResponse<TasksQueryResponse>) => d.data)
}

const updateStatusTask = (task_id: ID, query: string): Promise<TasksQueryResponse> => {
  return axios
      .get(`${GET_TASK_URL}/${task_id}/${query}/updatestatuscell`)
      .then((d: AxiosResponse<TasksQueryResponse>) => d.data)
}

const updateOrderTask = (tasks: {id:number, order_mytasks:number}[]): Promise<TasksQueryResponse> => {
  return axios
      .put(`${GET_TASK_URL}/updateordercell`, {tasks: tasks})
      .then((d: AxiosResponse<TasksQueryResponse>) => d.data)
}

const createTask = (task: Task): Promise<ResponseTask | undefined> => {
  return axios
      .post(`${GET_TASK_URL}/novo`, task)
      .then((response: AxiosResponse<ResponseTask>) => response.data)
}


const cloneTask = (task: Task): Promise<ResponseTask | undefined> => {
  return axios
      .post(`${GET_TASK_URL}/clone-task`, task)
      .then((response: AxiosResponse<ResponseTask>) => response.data)
}


const updateTask = (task: Task): Promise<ResponseTask> => {
  return axios
      .put(`${GET_TASK_URL}/${task.id}/editar`, task)
      .then((response: AxiosResponse<Response<ResponseTask>>) => response.data)
      .then((response: Response<ResponseTask>) => response.data)
}

const getTasksByUserId = (query: string): Promise<TasksQueryResponse> => {
  return axios
      .get(`${GET_TASK_URL}?${query}`)
      .then((d: AxiosResponse<TasksQueryResponse>) => d.data)
}

const getTasksCloseByUserId = (query: string): Promise<TasksQueryResponse> => {
  return axios
      .get(`${GET_TASK_URL}/close?${query}`)
      .then((d: AxiosResponse<TasksQueryResponse>) => d.data)
}

const getTasksByCreatorId = (query: string): Promise<TasksQueryResponse> => {
  return axios
      .get(`${GET_TASK_URL}/by-creatorid?${query}`)
      .then((d: AxiosResponse<TasksQueryResponse>) => d.data)
}

const deliveryTask = (taskId: ID): Promise<ResponseTask> => {
  return axios
  .get(`${GET_TASK_URL}/${taskId}/delivery-task`)
  .then((d: AxiosResponse<ResponseTask>) => d.data)
}

const deleteTask = (taskId: ID): Promise<void> => {
  return axios.delete(`${GET_TASK_URL}/${taskId}/excluir`).then(() => {})
}

const deleteSelectedTasks = (taskIds: Array<ID>): Promise<void> => {
  const requests = taskIds.map((id) => axios.delete(`${GET_TASK_URL}/${id}`))
  return axios.all(requests).then(() => {})
}
const searchTask = (search: string): Promise<TasksQueryResponse> => {
  let formData = new FormData()

  formData.append('search', search)

  return axios
      .post(`${GET_TASK_URL}/search-my-tasks`, formData)
      .then((d: AxiosResponse<TasksQueryResponse>) => d.data)
}

const getAttachements = (id: ID): Promise<TasksQueryResponse> => {
  return axios
      .get(`${GET_TASKATTACHEMENT_URL}/${id}`)
      .then((d: AxiosResponse<TasksQueryResponse>) => d.data)
}

const createTaskAttachement =  (taskAttachement: TaskAttachement): Promise<ResponseTaskAttachement> => {
  let formData = new FormData()
  if (taskAttachement.file) {
    formData.append('file', taskAttachement.file)
  }
  if (taskAttachement.task_id) {
    formData.append('task_id', taskAttachement.task_id.toLocaleString())
  }
  return axios
      .post(`${GET_TASKATTACHEMENT_URL}/novo`, formData)
      .then((response: AxiosResponse<ResponseTaskAttachement>) => response.data)
}

const deleteAttachement = (attachementid: ID): Promise<void> => {
  return axios.delete(`${GET_TASKATTACHEMENT_URL}/${attachementid}/excluir`).then(() => {})
}

const playTimeTracking = (taskId: ID): Promise<TasksQueryResponse> => {
  return axios
      .get(`${GET_TASK_URL}/${taskId}/timetracking/start`)
      .then((d: AxiosResponse<TasksQueryResponse>) => d.data)

}

const stopTimeTracking = (taskId: ID): Promise<TasksQueryResponse> => {
  return axios
      .get(`${GET_TASK_URL}/${taskId}/timetracking/stop`)
      .then((d: AxiosResponse<TasksQueryResponse>) => d.data)
}


export {deleteTask, deleteSelectedTasks, getTaskById, createTask, updateTask, updateOrderTask, deliveryTask, cloneTask, searchTask, getTasksByUserId, getTasksCloseByUserId, getTasksByCreatorId, getAttachements, deleteAttachement, getTasksList, updateNameTask, playTimeTracking, stopTimeTracking, createTaskAttachement, updateStatusTask}
