import {FC, useEffect} from 'react'
import {ClientEditModalHeader} from './ClientEditModalHeader'
import {ClientEditModalFormWrapper} from './ClientEditModalFormWrapper'
import {Modal} from 'react-bootstrap'
import { ID } from '../../../helpers'

type Props = {
  show: boolean
  handleClose: () => void | undefined
  clientId: ID | undefined
}

const ClientEditModal: FC<Props> = ({show, handleClose, clientId}) => {
  return (
    <>
    {/* 
    //@ts-ignore */}
      <Modal
        className='modal'
        id='kt_modal_add_client'
        tabIndex={-1}
        dialogClassName='modal-dialog modal-md modal-dialog-centered'
        show={show}
        onHide={handleClose}
        backdrop='static'
      >
        <ClientEditModalFormWrapper handleClose={handleClose} clientId={clientId} />
      </Modal>
    </>
  )
}

export {ClientEditModal}
