/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {ID} from "../../../../../../_metronic/helpers";

type Props = {
    task_id: ID
}

const TaskByUserIdCell: FC<Props> = ({ task_id}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <p className='text-gray-900 mb-1'>
        {task_id}
      </p>
    </div>
  </div>
)

export {TaskByUserIdCell}
