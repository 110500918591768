import {Outlet} from 'react-router-dom'
import {Content} from './components/content'
import {toAbsoluteUrl} from '../helpers'

const PublicLayout = () => {
  return (
    <div className='d-flex flex-column flex-root app-root h-100' id='kt_app_root'>
      <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
        <div className='d-flex align-items-center justify-content-center bg-body start-0 py-4 shadow-sm'>
          <a href='https://taskrush.com.br' target='_blank'>
            <img
              alt='TaskRush'
              src={toAbsoluteUrl('/media/logos/taskrush.svg')}
              className='h-50px app-sidebar-logo-default theme-light-show'
            />
          </a>
        </div>
        <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
          <div className='d-flex flex-column flex-column-fluid'>
            <Content>
              <Outlet />
            </Content>
          </div>
        </div>
        <div className='d-flex align-items-center justify-content-center bg-body start-0 py-4 shadow-sm'>
          <a href='https://taskrush.com.br' target='_blank'>
            <img
              alt='TaskRush'
              src={toAbsoluteUrl('/media/logos/taskrush.svg')}
              className='h-30px app-sidebar-logo-default theme-light-show'
            />
          </a>
        </div>
      </div>
    </div>
  )
}

export {PublicLayout}
