import React, {FC, useEffect, useState} from 'react'
import {ID, isNotEmpty, QUERIES, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useTheme, styled} from '@mui/material/styles'
import Popper from '@mui/material/Popper'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import Autocomplete, {
  AutocompleteCloseReason,
  autocompleteClasses,
} from '@mui/material/Autocomplete'
import InputBase from '@mui/material/InputBase'
import Box from '@mui/material/Box'
import {useFormik} from 'formik'
import {Toast} from '../../../../../_metronic/partials/widgets/alerts/Toast'
import {taskresponsible_delete} from '../tasks-list/TaskUsers/core/_requests'
import {useAuth} from '../../../auth'
import {usersColumns} from '../../../user/components/users-list/table/columns/_columns'
import {TextField} from '@mui/material'

interface IconUserModel {
  name: string
  avatar?: string
  color?: string | null
  initials?: string
  task_responsible_id?: ID
  user_id?: ID
}

type Props = {
  users: Array<IconUserModel>
  classes?: string
  onChange?: (e: any) => void
}

interface PopperComponentProps {
  anchorEl?: any
  disablePortal?: boolean
  open: boolean
}

const top100Films = [
  {label: 'The Shawshank Redemption', year: 1994},
  {label: 'The Godfather', year: 1972},
  {label: 'The Godfather: Part II', year: 1974},
  {label: 'The Dark Knight', year: 2008},
  {label: '12 Angry Men', year: 1957},
  {label: "Schindler's List", year: 1993},
  {label: 'Pulp Fiction', year: 1994},
  {
    label: 'The Lord of the Rings: The Return of the King',
    year: 2003,
  },
  {label: 'The Good, the Bad and the Ugly', year: 1966},
  {label: 'Fight Club', year: 1999},
  {
    label: 'The Lord of the Rings: The Fellowship of the Ring',
    year: 2001,
  },
  {
    label: 'Star Wars: Episode V - The Empire Strikes Back',
    year: 1980,
  },
  {label: 'Forrest Gump', year: 1994},
  {label: 'Inception', year: 2010},
  {
    label: 'The Lord of the Rings: The Two Towers',
    year: 2002,
  },
  {label: "One Flew Over the Cuckoo's Nest", year: 1975},
  {label: 'Goodfellas', year: 1990},
  {label: 'The Matrix', year: 1999},
  {label: 'Seven Samurai', year: 1954},
  {
    label: 'Star Wars: Episode IV - A New Hope',
    year: 1977,
  },
  {label: 'City of God', year: 2002},
  {label: 'Se7en', year: 1995},
  {label: 'The Silence of the Lambs', year: 1991},
  {label: "It's a Wonderful Life", year: 1946},
  {label: 'Life Is Beautiful', year: 1997},
  {label: 'The Usual Suspects', year: 1995},
  {label: 'Léon: The Professional', year: 1994},
  {label: 'Spirited Away', year: 2001},
  {label: 'Saving Private Ryan', year: 1998},
  {label: 'Once Upon a Time in the West', year: 1968},
  {label: 'American History X', year: 1998},
  {label: 'Interstellar', year: 2014},
  {label: 'Casablanca', year: 1942},
  {label: 'City Lights', year: 1931},
  {label: 'Psycho', year: 1960},
  {label: 'The Green Mile', year: 1999},
  {label: 'The Intouchables', year: 2011},
  {label: 'Modern Times', year: 1936},
  {label: 'Raiders of the Lost Ark', year: 1981},
  {label: 'Rear Window', year: 1954},
  {label: 'The Pianist', year: 2002},
  {label: 'The Departed', year: 2006},
  {label: 'Terminator 2: Judgment Day', year: 1991},
  {label: 'Back to the Future', year: 1985},
  {label: 'Whiplash', year: 2014},
  {label: 'Gladiator', year: 2000},
  {label: 'Memento', year: 2000},
  {label: 'The Prestige', year: 2006},
  {label: 'The Lion King', year: 1994},
  {label: 'Apocalypse Now', year: 1979},
  {label: 'Alien', year: 1979},
  {label: 'Sunset Boulevard', year: 1950},
  {
    label: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb',
    year: 1964,
  },
  {label: 'The Great Dictator', year: 1940},
  {label: 'Cinema Paradiso', year: 1988},
  {label: 'The Lives of Others', year: 2006},
  {label: 'Grave of the Fireflies', year: 1988},
  {label: 'Paths of Glory', year: 1957},
  {label: 'Django Unchained', year: 2012},
  {label: 'The Shining', year: 1980},
  {label: 'WALL·E', year: 2008},
  {label: 'American Beauty', year: 1999},
  {label: 'The Dark Knight Rises', year: 2012},
  {label: 'Princess Mononoke', year: 1997},
  {label: 'Aliens', year: 1986},
  {label: 'Oldboy', year: 2003},
  {label: 'Once Upon a Time in America', year: 1984},
  {label: 'Witness for the Prosecution', year: 1957},
  {label: 'Das Boot', year: 1981},
  {label: 'Citizen Kane', year: 1941},
  {label: 'North by Northwest', year: 1959},
  {label: 'Vertigo', year: 1958},
  {
    label: 'Star Wars: Episode VI - Return of the Jedi',
    year: 1983,
  },
  {label: 'Reservoir Dogs', year: 1992},
  {label: 'Braveheart', year: 1995},
  {label: 'M', year: 1931},
  {label: 'Requiem for a Dream', year: 2000},
  {label: 'Amélie', year: 2001},
  {label: 'A Clockwork Orange', year: 1971},
  {label: 'Like Stars on Earth', year: 2007},
  {label: 'Taxi Driver', year: 1976},
  {label: 'Lawrence of Arabia', year: 1962},
  {label: 'Double Indemnity', year: 1944},
  {
    label: 'Eternal Sunshine of the Spotless Mind',
    year: 2004,
  },
  {label: 'Amadeus', year: 1984},
  {label: 'To Kill a Mockingbird', year: 1962},
  {label: 'Toy Story 3', year: 2010},
  {label: 'Logan', year: 2017},
  {label: 'Full Metal Jacket', year: 1987},
  {label: 'Dangal', year: 2016},
  {label: 'The Sting', year: 1973},
  {label: '2001: A Space Odyssey', year: 1968},
  {label: "Singin' in the Rain", year: 1952},
  {label: 'Toy Story', year: 1995},
  {label: 'Bicycle Thieves', year: 1948},
  {label: 'The Kid', year: 1921},
  {label: 'Inglourious Basterds', year: 2009},
  {label: 'Snatch', year: 2000},
  {label: '3 Idiots', year: 2009},
  {label: 'Monty Python and the Holy Grail', year: 1975},
]

const StyledAutocompletePopper = styled('div')(({theme}) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: 'none',
    margin: 0,
    color: 'inherit',
    fontSize: 13,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 8,
      borderBottom: `1px solid  ${theme.palette.mode === 'light' ? ' #eaecef' : '#30363d'}`,
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
        {
          backgroundColor: theme.palette.action.hover,
        },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
}))

function PopperComponent(props: PopperComponentProps) {
  const {disablePortal, anchorEl, open, ...other} = props
  return <StyledAutocompletePopper {...other} />
}
//@ts-ignore
const StyledPopper = styled(Popper)(({theme}) => ({
  border: `1px solid ${theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'}`,
  boxShadow: `0 8px 24px ${
    theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'
  }`,
  borderRadius: 6,
  width: 300,
  zIndex: theme.zIndex.modal,
  fontSize: 13,
  color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
}))

const StyledInput = styled(InputBase)(({theme}) => ({
  padding: 10,
  width: '100%',
  borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
  '& input': {
    borderRadius: 4,
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#0d1117',
    padding: 8,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    border: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
    fontSize: 14,
    '&:focus': {
      boxShadow: `0px 0px 0px 3px ${
        theme.palette.mode === 'light' ? 'rgba(3, 102, 214, 0.3)' : 'rgb(12, 45, 107)'
      }`,
      borderColor: theme.palette.mode === 'light' ? '#0366d6' : '#388bfd',
    },
  },
}))

const UsersSymbolGroup: FC<Props> = ({users, classes, onChange}) => {
  const {currentUser} = useAuth()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [value, setValue] = useState<IconUserModel[]>(users)
  const [pendingValue, setPendingValue] = useState<IconUserModel[]>([])
  const theme = useTheme()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    {
      users.map((user) => {
        if (user.task_responsible_id == currentUser?.id && pendingValue.length == 0) {
          setPendingValue((value) => [...value, user])
        }
      })
    }
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setValue(pendingValue)
    if (anchorEl) {
      anchorEl.focus()
    }
    setAnchorEl(null)
  }

  useEffect(() => {
    {
      users.map((user) => {
        if (user.task_responsible_id == currentUser?.id && pendingValue.length == 0) {
          setPendingValue((value) => [...value, user])
          onChange &&
            onChange([
              {
                user_id: currentUser?.id,
                name: currentUser?.name!,
                avatar: currentUser?.avatar,
                color: null,
                task_responsible_id: currentUser?.id,
              },
            ])
        }
      })
    }
  }, [])

  const open = Boolean(anchorEl)
  const intl = useIntl()

  return (
    <>
      <div className={`symbol-group symbol-hover ${classes}`} onClick={handleClick}>
        {pendingValue.length > 0
          ? pendingValue.map((user, i) => {
              return i < 3 ? (
                <OverlayTrigger
                  key={`${i}-${user.name}`}
                  placement='top'
                  overlay={<Tooltip id='tooltip-user-name'>{user.name}</Tooltip>}
                >
                  <div className='symbol symbol-35px symbol-circle'>
                    {user.avatar != null ? (
                      <img
                        src={process.env.REACT_APP_THEME_API_URL + '/storage/' + user.avatar}
                        className='object-fit-cover'
                      />
                    ) : (
                      <span className='symbol-label bg-primary text-inverse-primary fw-bolder'>
                        {user.name.substring(0, 1)}
                      </span>
                    )}
                  </div>
                </OverlayTrigger>
              ) : null
            })
          : null}

        {pendingValue && pendingValue.length > 3 ? (
          <div className='symbol symbol-35px symbol-circle'>
            <div className='symbol symbol-20px symbol-circle'>
              <span className='symbol-label bg-primary text-inverse-primary fw-bolder me-2'>
                +{pendingValue.length}
              </span>
            </div>
          </div>
        ) : null}

        <div className='symbol symbol-35px symbol-circle'>
          <div className='symbol symbol-20px symbol-circle'>
            <span className='symbol-label bg-primary text-inverse-primary fw-bolder me-2'>+</span>
          </div>
        </div>
      </div>
      {/* 
      //@ts-ignore */}
      <StyledPopper
        id={value.toString()}
        open={open}
        anchorEl={anchorEl}
        placement='top-end'
        disablePortal={true}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Box
              sx={{
                borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
                padding: '8px 10px',
                fontWeight: 600,
              }}
            >
              {intl.formatMessage({id: 'FORM.INPUT.NAME.ADDUSER_TASK'})}
            </Box>
            <Autocomplete
              open
              multiple
              onClose={(event: React.ChangeEvent<{}>, reason: AutocompleteCloseReason) => {
                if (reason === 'escape') {
                  handleClose()
                }
              }}
              value={pendingValue}
              onChange={(event, newValue, reason) => {
                if (
                  event.type === 'keydown' &&
                  (event as React.KeyboardEvent).key === 'Backspace' &&
                  reason === 'removeOption'
                ) {
                  return
                }
                if (reason === 'removeOption') {
                  var newUser = newValue.filter(
                    (user) => !pendingValue.every((user2) => user2.user_id !== user.user_id)
                  )
                  if (!pendingValue.length) {
                    //@ts-ignore
                    newUser = newValue
                  }
                  try {
                    onChange && onChange(newUser)
                  } catch (ex) {
                    console.error(ex)
                  } finally {
                  }
                } else {
                  if (!pendingValue.length) {
                    //@ts-ignore
                    newUser = newValue
                  }
                  try {
                    onChange && onChange(newValue)
                  } catch (ex) {
                    console.error(ex)
                  } finally {
                  }
                }
                //@ts-ignore
                setPendingValue(newValue)
              }}
              disableCloseOnSelect
              PopperComponent={PopperComponent}
              renderTags={() => null}
              noOptionsText='No labels'
              renderOption={(props, option, {selected}) => (
                <li {...props}>
                  <Box
                    component={DoneIcon}
                    sx={{width: 17, height: 17, mr: '15px', ml: '-2px'}}
                    style={{
                      visibility: selected ? 'visible' : 'hidden',
                    }}
                  />
                  <Box>
                    {option.avatar != null ? (
                      <Box
                        component='img'
                        sx={{
                          width: 20,
                          height: 20,
                          flexShrink: 0,
                          borderRadius: '3px',
                          mr: 1,
                          mt: '2px',
                        }}
                        src={process.env.REACT_APP_THEME_API_URL + '/storage/' + option.avatar}
                      />
                    ) : (
                      <div className='symbol symbol-20px symbol-circle'>
                        <span className='symbol-label bg-primary text-inverse-primary fw-bolder me-2'>
                          {option.name.substring(0, 1)}
                        </span>
                      </div>
                    )}
                  </Box>
                  <Box
                    sx={{
                      flexGrow: 1,
                      '& span': {
                        color: theme.palette.mode === 'light' ? '#586069' : '#8b949e',
                      },
                    }}
                  >
                    {option.name}
                    <br />
                  </Box>
                  <Box
                    component={CloseIcon}
                    sx={{opacity: 0.6, width: 18, height: 18}}
                    style={{
                      visibility: selected ? 'visible' : 'hidden',
                    }}
                  />
                </li>
              )}
              options={users.sort((a, b) => {
                // Display the selected labels first.
                let ai = value.indexOf(a)
                ai = ai === -1 ? value.length + users.indexOf(a) : ai
                let bi = value.indexOf(b)
                bi = bi === -1 ? value.length + users.indexOf(b) : bi
                return ai - bi
              })}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <StyledInput
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  autoFocus
                  placeholder={intl.formatMessage({id: 'FORM.INPUT.NAME.SELECTUSER_TEAMS'})}
                />
              )}
            />
          </div>
        </ClickAwayListener>
      </StyledPopper>
    </>
  )
}

export {UsersSymbolGroup}
