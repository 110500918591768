/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {useQueryClient} from 'react-query'
import {ID, secondsToDays, secondsToHours} from '../../../helpers'
import {UsersSymbolGroupCard} from '../../../../app/modules/project/components/projects-list/card/UsersSymbolGroupCard'
import {useQueryResponse} from '../../../../app/modules/project/components/core/QueryResponseProvider'
import {ProjectListActions} from '../../../../app/modules/project/components/projects-list/components/actions/ProjectListActions'
import {useAuth} from '../../../../app/modules/auth'
import {isFunction} from 'formik'
interface IconUserModel {
  name: string
  avatar?: string
  color?: string
  initials?: string
}

type Props = {
  id?: ID
  name?: string
  subtitle?: string
  client?: string
  budgetedHours?: number
  users: Array<IconUserModel>
  begin_date: string
  end_date: string
  closed_at?: string | null
  totalTime?: number | null
  profit_margin?: number | null
}

const ProjectCard: FC<Props> = ({
  id,
  name,
  budgetedHours,
  client,
  users,
  begin_date,
  end_date,
  closed_at,
  totalTime,
  profit_margin,
}) => {
  const intl = useIntl()
  const queryClient = useQueryClient()
  const {query} = useQueryResponse()
  const [progressedTime, setProgressedTime] = useState<number>(0)
  const [progressedTimePercent, setProgressedTimePercent] = useState<number>(0)
  const [progressBarColor, setprogressBarColor] = useState<string>('bg-primary')
  const now = new Date()
  const begin = begin_date ? new Date(begin_date) : null
  const end = end_date ? new Date(end_date) : null
  const closedAt = closed_at ? new Date(closed_at) : null
  var status = intl.formatMessage({id: 'PROJECT.LABEL.IN_PROGRESS'})
  var statusColor = 'light-primary'
  const deadline = end && begin ? secondsToDays(Math.abs(end.getTime() - begin.getTime())) : null
  const {currentUser} = useAuth()

  useEffect(() => {
    calculateProgress()
  }, [closed_at])

  if (begin && now < begin) {
    status = intl.formatMessage({id: 'PROJECT.LABEL.NOT.START'})
    statusColor = 'light-warning'
  }

  if (end && now > end) {
    status = intl.formatMessage({id: 'PROJECT.LABEL.DELAYED'})
    statusColor = 'light-danger'
  }

  if (closedAt) {
    status = intl.formatMessage({id: 'PROJECT.LABEL.CLOSED'})
    statusColor = 'light-success'
  }

  if (closedAt && end && closedAt > end) {
    status = intl.formatMessage({id: 'PROJECT.LABEL.CLOSED_WITH_DELAY'})
    statusColor = 'light-danger'
  }

  const calculateProgress = () => {
    if (begin && end && deadline) {
      if (now < begin) {
        setProgressedTimePercent(0)
      }
      if (now > end) {
        setprogressBarColor('bg-danger')
        setProgressedTimePercent(100)

        if (!closed_at) {
          var progressed = secondsToDays(Math.abs(now.getTime() - begin.getTime()))
          setProgressedTime(progressed)
        } else {
          const closedAtInDate = new Date(closed_at)
          var progressed = secondsToDays(Math.abs(closedAtInDate.getTime() - begin.getTime()))
          setProgressedTime(progressed)
        }
      }
      if (now >= begin && now < end) {
        if (!closed_at) {
          var progressed = secondsToDays(Math.abs(now.getTime() - begin.getTime()))
          var progressPercent = (progressed * 100) / deadline
          setProgressedTime(progressed)
          setProgressedTimePercent(progressPercent)
          setprogressBarColor('bg-primary')
        } else {
          const closedAtInDate = new Date(closed_at)
          var progressed = secondsToDays(Math.abs(closedAtInDate.getTime() - begin.getTime()))
          var progressPercent = (progressed * 100) / deadline
          setProgressedTime(progressed)
          setProgressedTimePercent(progressPercent)
          setprogressBarColor('bg-success')
        }
      }
    }
  }

  return (
    <div className='card h-100 border border-2 border-gray-200 border-hover'>
      <div className='card-header flex-grow-1 align-items-start border-0 p-5'>
        <div className='card-title flex-column m-0 col-8'>
          <Link to={`/projects/${id}`} className='fs-3 fw-bolder text-dark'>
            {name}
          </Link>
          <div className='fs-5 text-gray-700'>{client}</div>
        </div>
        <div className='card-toolbar col-4 flex-nowrap justify-content-end my-0'>
          <span
            className={`badge badge-${statusColor} fw-bolder me-2 px-3 py-3 white-space-break-spaces`}
          >
            {status}
          </span>
          {currentUser?.administrator ? <ProjectListActions id={id} closed_at={closed_at} /> : null}
        </div>
      </div>
      <div className='card-body flex-grow-0 p-5 p-md-5'>
        {/* <p className='text-gray-400 fw-bold fs-5 mt-1 mb-7'>{subtitle}</p> */}
        <div className='d-flex mb-5 justify-content-between gap-5'>
          <div className='border border-gray-200 border-dashed rounded min-w-100px w-140px py-3 px-4 mb-3'>
            <div className='fs-6 text-gray-800 fw-bolder'>
              {intl.formatMessage({id: 'PROJECT.LABEL.DEADLINE'})}
            </div>
            <div className='fw-bold text-gray-500'>
              {deadline
                ? `${progressedTime} ${intl.formatMessage({id: 'GENERAL.OF'})} ${deadline}`
                : intl.formatMessage({id: 'PROJECT.LABEL.UNDETERMINED_DEADLINE'})}
            </div>
          </div>
          <div className='border border-gray-200 border-dashed rounded min-w-100px w-140px py-3 px-4 mb-3'>
            <div className='fs-6 text-gray-800 fw-bolder'>
              {intl.formatMessage({id: 'PROJECT.LABEL.RECORDED_HOURS'})}
            </div>
            {/* <div className='fw-bold text-gray-500'>{recordedHours && recordedHours + 'h'}</div> */}
            <div className='fw-bold text-gray-500'>
              {totalTime
                ? secondsToHours(totalTime)
                : intl.formatMessage({id: 'PROJECT.LABEL.NO_RECORDED_HOURS'})}
            </div>
          </div>
        </div>
        {currentUser?.administrator ? (
          <div className='d-flex mb-5 justify-content-between gap-5'>
            <div className='border border-gray-200 border-dashed rounded min-w-100px w-140px py-3 px-4 mb-3'>
              <div className='fs-6 text-gray-800 fw-bolder'>
                {intl.formatMessage({id: 'PROJECT.LABEL.PROFIT_MARGIN'})}
              </div>
              <div className='fw-bold text-gray-500'>
                {profit_margin ? profit_margin + '%' : 'Sem lançamento'}
              </div>
            </div>
            {budgetedHours ? (
              <div className='border border-gray-200 border-dashed rounded min-w-100px w-140px  py-3 px-4 mb-3'>
                <div className='fs-6 text-gray-800 fw-bolder'>
                  {intl.formatMessage({id: 'PROJECT.LABEL.BUDGETED_HOURS'})}
                </div>
                <div className='fw-bold text-gray-500'>{secondsToHours(budgetedHours)}</div>
              </div>
            ) : null}
          </div>
        ) : null}
        <div
          className='h-4px w-100 bg-light mb-5'
          data-bs-toggle='tooltip'
          title='This project completed'
        >
          <div
            className={`rounded h-4px ${progressBarColor}`}
            role='progressbar'
            style={{width: `${progressedTimePercent}%`}}
          ></div>
        </div>
        <div className='symbol symbol-35px symbol-circle'>
          <UsersSymbolGroupCard users={users} classes='justify-content-start' />
        </div>
      </div>
    </div>
  )
}

export {ProjectCard}
