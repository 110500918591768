import {useEffect, useMemo, useState} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {
  DndContext,
  closestCorners,
  MouseSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core'
import {arrayMove, SortableContext, verticalListSortingStrategy} from '@dnd-kit/sortable'
import {restrictToVerticalAxis} from '@dnd-kit/modifiers'
import {useIntl} from 'react-intl'
import {useParams, useSearchParams} from 'react-router-dom'
import {useQuery} from 'react-query'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {useQueryResponseLoading} from '../../core/QueryResponseProvider'
import {useListView} from '../../core/ListViewProvider'
import {tasksColumns} from './columns/_columns'
import {Task} from '../../core/_models'
import {ID, KTCardBody, QUERIES} from '../../../../../_metronic/helpers'
import {updateOrderTask} from '../../core/_requests'
import {TasksByUserListPagination} from '../pagination/TasksByUserListPagination'
import {Loading} from '../../../../../_metronic/partials/components/loading/Loading'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {getUserById} from '../../../user/components/users-list/core/_requests'
import {useAuth} from '../../../auth'

const TasksByUserTable = () => {
  const {tasks, setTasks} = useListView()
  const isLoading = useQueryResponseLoading()
  const columns = useMemo(() => tasksColumns, [])
  const intl = useIntl()
  const data = useMemo(() => tasks, [tasks])
  const [newOrder, setNewOrder] = useState(0)
  const [oldOrder, setOldOrder] = useState(0)
  const [showModal, setShowModal] = useState<boolean>(false)
  const {setItemIdForUpdate} = useListView()
  const {userId} = useParams()

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  const items = useMemo(
    () =>
      tasks?.reduce((filtered: string[], option: Task) => {
        if (option.id !== null && option.id !== undefined) {
          var id = option.id.toString()
          filtered.push(id)
        }
        return filtered
      }, []),
    [tasks]
  )

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  )

  const handleDragEnd = (event: DragEndEvent) => {
    const {active, over} = event
    if (over && active.id !== over?.id && setTasks) {
      var newOrder = over.id.toString()
      var oldOrder = active.id.toString()

      var newOrderPosition = items.indexOf(over.id.toString())
      var oldOrderPosition = items.indexOf(active.id.toString())

      updateOrderTask(parseInt(newOrder), parseInt(oldOrder))

      setTasks((tasks) => {
        return arrayMove(tasks, oldOrderPosition, newOrderPosition)
      })
    }
  }

  const [action, setAction] = useState<boolean>(false)

  const [searchParams, setSearchParams] = useSearchParams()
  const [taskByIdURL, setTaskByIdURL] = useState<ID>()
  const {currentUser} = useAuth()

  useEffect(() => {
    if (action == false && searchParams.get('taskId')) {
      var taskId_search = searchParams.get('taskId')!
      setItemIdForUpdate(parseInt(taskId_search))
      setTaskByIdURL(parseInt(taskId_search))
      setShowModal(true)
    }
  }, [])

  const {data: user, error} = useQuery(
    `${QUERIES.USERS_LIST}-USER-${userId}`,
    () => {
      return getUserById(parseInt(userId!))
    },
    {
      onSuccess: (response) => {
        if (response.users.instance_id != currentUser?.instance_id) {
          window.location.href = `../404`
        }
      },
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  )

  return (
    <>
      <PageTitle>
        {intl.formatMessage({id: 'MENU.TASKS.BY_USER'}, {item: user?.users.name})}
      </PageTitle>
      <KTCardBody className='py-4'>
        <div className='table-responsive'>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCorners}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToVerticalAxis]}
          >
            <table
              id='kt_table_tasks'
              className='table align-middle table-row-dashed fs-6 gy-4 dataTable no-footer tab-pane fade show active'
              {...getTableProps()}
            >
              <thead>
                <tr className='text-start text-muted fw-bolder fs-7 gs-0 border-bottom-0'>
                  {headers.map((column: ColumnInstance<Task>) => (
                    <CustomHeaderColumn key={column.id} column={column} />
                  ))}
                </tr>
              </thead>
              <tbody className='text-gray-600' {...getTableBodyProps()}>
                <SortableContext items={items} strategy={verticalListSortingStrategy}>
                  {rows.length > 0 ? (
                    rows.map((row: Row<Task>, i) => {
                      prepareRow(row)
                      return <CustomRow row={row} key={row.original.id} />
                    })
                  ) : (
                    <tr>
                      <td colSpan={7}>
                        <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                          {intl.formatMessage({id: 'TABLE.GENERAL.NO_RECORD_FOUND'})}
                        </div>
                      </td>
                    </tr>
                  )}
                </SortableContext>
              </tbody>
            </table>
          </DndContext>
        </div>
        <TasksByUserListPagination />

        {isLoading && <Loading />}
      </KTCardBody>
    </>
  )
}

export {TasksByUserTable}
