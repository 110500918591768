import {FC, useState, useEffect, HTMLProps} from 'react'
import {useIntl} from 'react-intl'

type Props = Omit<HTMLProps<HTMLInputElement>, 'onChange'> & {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  value?: string | number
}

function formatTime(value: string) {
  const numbers = value.replace(/\D/g, '')
  let hours = numbers.slice(0, -2) || '0'
  let minutes = numbers.slice(-2).padStart(2, '0')

  if (hours.length > 2) hours = hours.slice(-2)

  hours = hours.padStart(2, '0')
  return `${hours}:${minutes}`
}

const InputHour: FC<Props> = ({onChange, value = '', ...props}) => {
  const [hourValue, setHourValue] = useState<string>(formatTime(value.toString()))

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    var value = event.target.value

    setHourValue(formatTime(value))
    if (onChange) {
      onChange({
        ...event,
        target: {
          ...event.target,
          value: value,
        },
      })
    }
  }

  return <input type='text' onChange={handleChange} value={hourValue} {...props} />
}

export {InputHour}
