import React, {FC, InputHTMLAttributes, useCallback, useEffect, useState} from 'react'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import clsx from 'clsx'
import * as Yup from 'yup'
import {KTSVG, QUERIES, isNotEmpty} from '../../../../../../_metronic/helpers'
import {Task, ResponseTask} from '../core/_models'
import {useListView} from '../core/ListViewProvider'
import {Loading} from '../../../../../../_metronic/partials/components/loading/Loading'
import {cloneTask} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {useSearchParams} from 'react-router-dom'
import {getProjectsTask} from '../../core/_requests'
import {Project} from '../../core/_models'
import {useAuth} from '../../../../auth'
import {Toast} from '../../../../../../_metronic/partials/widgets'
import {SelectAutoComplete} from '../../../../../../_metronic/partials/components/Select/SelectAutoComplete'
import {CreateProjectAndClient} from '../../../../../../_metronic/partials/modals/create-project-and-client/CreateProjectAndClientModal'
import {UsersSymbolGroup} from '../../projects-list/UsersSymbolGroup'
import {getUsersInstanceTask} from '../../../../../../_metronic/partials/modals/task/core/_requests'
import {getChecklistClone} from '../TaskChecklist/core/_requests'
var today = new Date().toISOString().split('T')[0]

type Props = {
  isTaskLoading: boolean
  task: ResponseTask
  handleClose: () => void | undefined
}

interface checkProps extends InputHTMLAttributes<HTMLInputElement> {
  content: string
  check: boolean
}

interface checkPropsFile extends InputHTMLAttributes<HTMLInputElement> {
  file: any
}

const TaskCloneModalForm: FC<Props> = ({task, isTaskLoading, handleClose}) => {
  const intl = useIntl()
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [data, setData] = useState<Task>(task.tasks)
  const queryClient = useQueryClient()
  const {query} = useQueryResponse()
  const [checks, setChecks] = useState<Array<checkProps>>([])
  const {currentUser} = useAuth()
  const [showProjectAndClientModal, setShowProjectAndClientModal] = useState<boolean>(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [clients, setClients] = useState<Project[]>([])
  const [name_input, setNameInput] = useState<string>(data.name?.toString()!)
  const [description_input, setDescriptionInput] = useState<string>(data.description?.toString()!)
  const [delivery_date_input, setDeliveryDateInput] = useState<string>(
    data.expected_date?.toString()!
  )
  const [checksFile, setChecksFile] = useState<Array<checkPropsFile>>([])

  const getSelectClients = useMutation(() => getProjectsTask(parseInt(task.tasks.project_id!)), {
    onSuccess: (response) => {
      setClients(response.data)
    },
  })

  useEffect(() => {
    getSelectClients.mutateAsync()
    getChecklistCost.mutateAsync()
  }, [])

  const handleCreateProjectAndClient = (newProjectName: string) => {
    setSearchParams({new_project_name: newProjectName})
    setShowProjectAndClientModal(true)
  }

  const addCheckFile = () => {
    setChecksFile([...checksFile, {file: ''}])
  }

  const removeCheck = (key: number) => {
    setChecks(checks.filter((value, index) => key !== index))
  }

  const updateData = (fieldsToUpdate: Partial<Task>) => {
    const updatedData = {...data, ...fieldsToUpdate}
    setData(updatedData)
  }

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const removeCheckFile = (key: number) => {
    setChecksFile(checksFile.filter((value, index) => key !== index))
  }

  const {data: dataConst} = useQuery(
    `${QUERIES.TASKS_LIST}-usersTask`,
    () => {
      return getUsersInstanceTask()
    },
    {
      cacheTime: 0,
      onError: (err) => {
        console.error(err)
      },
      refetchOnWindowFocus: false,
    }
  )

  const update = useMutation(() => cloneTask(data), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: (response) => {
      Toast.fire({
        icon: 'success',
        title: intl.formatMessage(
          {id: 'ALERT.LABEL.SUCCESS_TASK_CLONE_ITEM'},
          {item: intl.formatMessage({id: 'TABLE.GENERAL.LABEL.TASK'})}
        ),
        customClass: {
          popup: 'bg-light-success',
          title: 'text-success',
        },
      })
      cancel(true)
      queryClient.invalidateQueries(`${QUERIES.TASKS_LIST}-${query}`)
      handleClose()
    },
    onError: (error) => {
      Toast.fire({
        icon: 'warning',
        title: intl.formatMessage(
          {id: 'ALERT.LABEL.ERROR_TASK_CLONE_ITEM'},
          {item: intl.formatMessage({id: 'TABLE.GENERAL.LABEL.TASK'})}
        ),
        customClass: {
          popup: 'bg-light-danger',
          title: 'text-danger',
        },
      })
    },
  })

  const initialValues = {
    file: [],
    name: '',
    description: '',
    expected_date: '',
    project_id: '',
    checklist_concluded: [],
    checklist_name: [],
    users: [],
  }

  const createAccountSchemas = [
    Yup.object().shape({
      name: Yup.string().required(intl.formatMessage({id: 'FORM.INPUT.VALIDATION.REQUIRED'})),
    }),
  ]

  const getChecklistCost = useMutation(() => getChecklistClone(task.tasks.id), {
    onSuccess: (response) => {
      setChecks(response.data)
    },
  })

  const addCheck = () => {
    setChecks([...checks, {content: '', check: false}])
  }

  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0])

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: currentSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(data.name)) {
          await cloneTask(values).then((response) => {
            Toast.fire({
              icon: 'success',
              title: intl.formatMessage(
                {id: 'ALERT.LABEL.SUCCESS_TASK_CLONE_ITEM'},
                {item: intl.formatMessage({id: 'TABLE.GENERAL.LABEL.TASK'})}
              ),
              customClass: {
                popup: 'bg-light-success',
                title: 'text-success',
              },
            })
            queryClient.invalidateQueries()
            resetForm()
          })
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        queryClient.invalidateQueries()
        cancel(true)
      }
    },
  })

  const submitForm = useCallback(
    async (e: any) => {
      formik.setFieldValue(
        'checklist_concluded',
        checks.map((check, index) => {
          return check.check
        })
      )

      formik.setFieldValue(
        'checklist_name',
        checks.map((check, index) => {
          return check.content
        })
      )

      formik.setFieldValue(
        'file',
        checksFile.map((file, index) => {
          return file.file
        })
      )

      formik.submitForm()
      handleClose()
    },
    [formik]
  )

  useEffect(() => {
    formik.setFieldValue('task_id', data.id)
    formik.setFieldValue('creator_id', currentUser?.id)
    formik.setFieldValue('status', data.status)
    formik.setFieldValue('name', name_input)
    formik.setFieldValue('description', description_input)
    formik.setFieldValue('expected_date', delivery_date_input)
    formik.setFieldValue('project_id', task.tasks.project_id)
  }, [name_input, description_input, delivery_date_input])

  return (
    <>
      <div className='modal-content'>
        <form
          id='kt_modal_add_task_clone_form'
          className='form'
          onSubmit={(e) => e.preventDefault()}
          noValidate
        >
          <div className='modal-content'>
            <div className='modal-header border-0'>
              <div className='col-md-8'>
                <input
                  placeholder={intl.formatMessage({id: 'FORM.INPUT.NAME.TASK_TITLE'})}
                  type='text'
                  onKeyPress={(e) => {
                    e.key === 'Enter' && e.preventDefault()
                  }}
                  {...formik.getFieldProps('name')}
                  value={name_input}
                  onChange={(e: any) => setNameInput(e.target.value)}
                  className={clsx('form-control py-2 bg-transparent border-0 fs-1')}
                  autoComplete='off'
                />
              </div>

              <div className='col-md-3 d-none d-md-flex justify-content-end pe-4'>
                {dataConst?.users?.length! > 0 ? (
                  <UsersSymbolGroup
                    users={dataConst?.users!}
                    classes='justify-content-end'
                    onChange={(users) => formik.setFieldValue('users', users)}
                  />
                ) : null}
              </div>

              <button
                className='btn btn-sm btn-icon btn-active-color-primary h-auto ms-4'
                onClick={handleClose}
              >
                <KTSVG
                  className='svg-icon-2x svg-icon-gray-800'
                  path='/media/icons/duotune/arrows/arr061.svg'
                />
              </button>
            </div>
            <div className='modal-body d-flex flex-column flex-lg-row justify-content-between'>
              <div className='col-lg-8 pe-5 pb-5 pb-lg-0 mb-15 mb-lg-0 border-bottom border-bottom-lg-0'>
                <ul
                  className='nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap '
                  role='tablist'
                >
                  <li className='nav-item'>
                    <a
                      className='nav-link cursor-pointer text-gray-900 active'
                      data-bs-toggle='tab'
                      href='#kt_task_description_pane_clone'
                    >
                      {intl.formatMessage({id: 'TASK.TABLE.DESCRIPTION'})}
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className='nav-link cursor-pointer text-gray-900'
                      data-bs-toggle='tab'
                      href='#kt_task_attachments_pane_clone'
                    >
                      {intl.formatMessage({id: 'TASK.TABLE.ATTACHMENTS'})}
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className='nav-link cursor-pointer text-gray-900'
                      data-bs-toggle='tab'
                      href='#kt_task_checklist_pane_clone'
                    >
                      {intl.formatMessage({id: 'TASK.TABLE.CHECKLIST'})}
                    </a>
                  </li>
                </ul>
                <div className='tab-content pt-3'>
                  <div id='kt_task_description_pane_clone' className='tab-pane fade show active'>
                    <textarea
                      className='form-control border-0 fw-normal'
                      onKeyPress={(e) => {
                        e.key === 'Enter' && e.preventDefault()
                      }}
                      {...formik.getFieldProps('description')}
                      value={description_input}
                      onChange={(e: any) => setDescriptionInput(e.target.value)}
                      style={{resize: 'none'}}
                      rows={8}
                      placeholder={intl.formatMessage({id: 'TASK.TABLE.DESCRIPTION'})}
                    ></textarea>
                  </div>
                  <div id='kt_task_attachments_pane_clone' className='tab-pane fade'>
                    {checksFile.map((check, key) => (
                      <div className='check d-flex align-items-center mb-4' key={key}>
                        <input
                          className='form-control form-control-solid py-2  rounded fw-normal'
                          type='file'
                          id='file'
                          accept='.png, .jpg, .jpeg, .pdf, .doc, .docx, .zip, .rar, .sql, .xls, .xlsx'
                          disabled={formik.isSubmitting}
                          onKeyPress={(e) => {
                            e.key === 'Enter' && e.preventDefault()
                          }}
                          // value={''}

                          onChange={(e) => {
                            setChecksFile(
                              checksFile.filter((c, index) => {
                                if (key === index) {
                                  if (e.target.files) {
                                    c.file = e.target.files[0]
                                  }
                                }
                                return c
                              })
                            )
                          }}
                        ></input>

                        <button onClick={() => removeCheckFile(key)} className='btn p-0 ms-5'>
                          <i className='bi bi-trash3-fill'></i>
                        </button>
                      </div>
                    ))}
                    <button
                      onClick={addCheckFile}
                      onKeyPress={(e) => {
                        e.key === 'Enter' && e.preventDefault()
                      }}
                      className='btn text-bold d-flex align-items-center p-0 mt-10'
                    >
                      <div className='d-flex align-items-center justify-content-center w-15px h-15px bg-gray-900 me-3'>
                        <i className='bi bi-plus text-gray-100 fs-5 p-0'></i>
                      </div>
                      {intl.formatMessage({id: 'GENERAL.ADD_ITEM'})}
                    </button>
                  </div>

                  <div id='kt_task_checklist_pane_clone' className='tab-pane fade'>
                    <div className='checklist pt-6'>
                      {checks?.map((x, key) => {
                        return (
                          <div className='check d-flex align-items-center mb-4' key={key}>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              id={key.toString()}
                              {...formik.getFieldProps('checklist_concluded')}
                              onKeyPress={(e) => {
                                e.key === 'Enter' && e.preventDefault()
                              }}
                              defaultChecked={false}
                              onChange={(e) =>
                                setChecks(
                                  checks.filter((c, index) => {
                                    if (key === index) {
                                      x.checked = e.target.checked
                                    }
                                    return c
                                  })
                                )
                              }
                            />

                            <input
                              className='form-control border-0 rounded-0 bg-gray-100 bg-focus-gray-100 p-0 ps-2 py-2 ms-4'
                              type='text'
                              id={key.toString()}
                              onKeyPress={(e) => {
                                e.key === 'Enter' && e.preventDefault()
                              }}
                              placeholder={intl.formatMessage({id: 'CHECKLIST.NAME_NEW'})}
                              {...formik.getFieldProps('checklist_name')}
                              value={x.content}
                              onChange={(e) => {
                                setChecks(
                                  checks.filter((c, index) => {
                                    if (key === index) {
                                      c.content = e.target.value
                                    }
                                    return c
                                  })
                                )
                              }}
                              defaultValue={x.name}
                            ></input>

                            <button onClick={() => removeCheck(key)} className='btn p-0 ms-5'>
                              <i className='bi bi-trash3-fill'></i>
                            </button>
                          </div>
                        )
                      })}
                      <button
                        onClick={addCheck}
                        onKeyPress={(e) => {
                          e.key === 'Enter' && e.preventDefault()
                        }}
                        className='btn text-bold d-flex align-items-center p-0 mt-10'
                      >
                        <div className='d-flex align-items-center justify-content-center w-15px h-15px bg-gray-900 me-3'>
                          <i className='bi bi-plus text-gray-100 fs-5 p-0'></i>
                        </div>
                        {intl.formatMessage({id: 'GENERAL.ADD_ITEM'})}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-lg-4 border-start-lg ps-lg-10'>
                <div className='border-bottom pb-8'>
                  <div className='d-flex mb-4'>
                    <span className='fw-bold text-dark me-1'>
                      {intl.formatMessage({id: 'TASK.LABEL.ESTIMATED_DEADLINE'})}
                    </span>
                  </div>

                  <div className='d-flex mb-4'>
                    <input
                      placeholder={intl.formatMessage({id: 'FORM.INPUT.NAME.BEGIN_DATE'})}
                      type='date'
                      min={today}
                      {...formik.getFieldProps('expected_date')}
                      value={delivery_date_input}
                      onChange={(e: any) => setDeliveryDateInput(e.target.value)}
                      onKeyPress={(e) => {
                        e.key === 'Enter' && e.preventDefault()
                      }}
                      className={clsx('form-control form-control-solid py-2  rounded fw-normal')}
                      autoComplete='off'
                    />
                  </div>

                  <div className='d-flex mb-4'>
                    <span className='fw-bold text-dark me-1'>
                      {intl.formatMessage({id: 'TASK.LABEL.CREATOR_DEADLINE'})} {data.creator_name}
                    </span>
                  </div>
                </div>

                <div className='pt-10'>
                  <div>
                    <span className='fw-bold text-dark me-1'>
                      {intl.formatMessage({id: 'TASK.LABEL.PROJECT'})}
                    </span>

                    <SelectAutoComplete
                      endPoint='projetos'
                      value={{value: task.tasks.project_id, label: task.tasks.project_name!}}
                      placeholder={intl.formatMessage({id: 'FORM.INPUT.TOOLTIP.PROJECT'})}
                      onChange={(e) => {
                        formik.setFieldValue('project_id', e?.value)
                      }}
                      onCreateOption={(inputValue) => handleCreateProjectAndClient(inputValue)}
                    />
                  </div>
                </div>

                <div className='text-center mt-20'>
                  <a onClick={submitForm} className='btn btn-primary btn-sm py-2 px-8' href='#'>
                    {intl.formatMessage({id: 'GENERAL.CLONE_TASK'})}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </form>
        {(formik.isSubmitting || isTaskLoading) && <Loading />}
      </div>

      <CreateProjectAndClient
        show={showProjectAndClientModal}
        handleClose={() => setShowProjectAndClientModal(false)}
      />
    </>
  )
}

export {TaskCloneModalForm}
