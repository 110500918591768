import {FC, useState} from 'react'
import {useSortable} from '@dnd-kit/sortable'
import {CSS} from '@dnd-kit/utilities'
import {ID, secondsToHours} from '../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {UsersSymbolGroup} from '../../../../../_metronic/partials/widgets/users/UsersSymbolGroup'
import {useSearchParams} from 'react-router-dom'
import {Task} from '../../core/_models'

type Props = {
  task: Task
}

const KanbanCard: FC<Props> = ({task}) => {
  const {setTaskIdForUpdate} = useListView()
  const [showModal, setShowModal] = useState(false)
  const [action, setAction] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [taskByIdURL, setTaskByIdURL] = useState<ID>()

  const {attributes, listeners, setNodeRef, transform, transition, isDragging} = useSortable({
    id: task.id!,
    disabled: false,
  })

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    opacity: isDragging ? 0.8 : 1,
  }

  const openEditModal = (taskIdParam: ID) => {
    const newParams = new URLSearchParams(searchParams)
    newParams.set('taskId', taskIdParam!.toString()!)
    setSearchParams(newParams)
    setTaskIdForUpdate(taskIdParam)
    setTaskByIdURL(taskIdParam)
    setShowModal(true)
    setAction(true)
  }

  return (
    <>
      <div
        style={style}
        ref={setNodeRef}
        {...attributes}
        {...listeners}
        className='card hover-elevate-up shadow-sm parent-hover mb-5'
        onClick={() => openEditModal(parseInt(task.id!.toString()))}
      >
        <div className='card-body d-flex align-items flex-column'>
          <p className='text-dark fs-4 fw-bold mb-1'>{task.name}</p>
          <span>#{task.id}</span>
          <span>
            {/* [{task.client_name}]  */}
            {task.project_name}
          </span>
          <div className='border w-fit-content px-2 py-1 mt-5'>
            <span className='me-2'>{secondsToHours(task.total_time ?? 0)}</span>
            <span>
              <i className='bi bi-clock'></i>
            </span>
          </div>
          <div className='d-none d-md-block mt-4'>
            <UsersSymbolGroup users={task.users} />
          </div>
          {/* {task.end_date !== null &&
            <div className="d-flex mt-4">
                <span className="fw-bold text-dark me-1">Entrega:</span>
                <span className="text-dark me-2">{task.end_date}</span>
            </div>
        } */}
        </div>
      </div>
    </>
  )
}

export {KanbanCard}
