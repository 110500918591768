import axios, { AxiosResponse } from 'axios'
import {Contracts, ContractsQueryResponse, ResponseContract} from './_models'
import { ID, QueryState } from '../../../../../../_metronic/helpers'
import {ClientsQueryResponse} from "../../../../clients/components/core/_models";

const API_URL = process.env.REACT_APP_THEME_API_URL
const CONTRACT_URL = `${API_URL}/api/contracts`
const GET_CONTRACTS_URL = `${API_URL}/api/contracts`


// const ContractById = (id: ID) => {
//   const Contract = contract.data.find(obj => obj.id === id);
//   return Contract
// }

const getContract = (query: string): Promise<ContractsQueryResponse> => {
  return axios
    .get(`${GET_CONTRACTS_URL}`)
    .then((d: AxiosResponse<ContractsQueryResponse>) => d.data)
}

const getContracts = (query: string): Promise<ContractsQueryResponse> => {
  return axios
      .get(`${GET_CONTRACTS_URL}?${query}`)
      .then((d: AxiosResponse<ContractsQueryResponse>) => d.data)
}

const getContractExpire = (): Promise<ContractsQueryResponse> => {
  return axios
    .get(`${GET_CONTRACTS_URL}/contractExpire`)
    .then((d: AxiosResponse<ContractsQueryResponse>) => d.data)
}

const getContractById =  (id: ID): Promise<ResponseContract> => {
 return axios
    .get(`${CONTRACT_URL}/${id}`)
    .then((response: AxiosResponse<ResponseContract>) => response.data)
}

const getContractByClientId =  (id: ID): Promise<ContractsQueryResponse> => {
  return axios
      .get(`${CONTRACT_URL}/clientes/${id}`)
      .then((response: AxiosResponse<ContractsQueryResponse>) => response.data)
}

const getContractByProjectId =  (id: ID): Promise<ContractsQueryResponse> => {
  return axios
      .get(`${CONTRACT_URL}/projetos/${id}`)
      .then((response: AxiosResponse<ContractsQueryResponse>) => response.data)
}

const searchContract = (search: QueryState): Promise<ContractsQueryResponse> => {
  return axios
    .post(`${CONTRACT_URL}/search`, search)
    .then((d: AxiosResponse<ContractsQueryResponse>) => d.data)
}

const openViewContract =  (id: ID) => {
  axios.defaults.responseType = 'blob'
  return axios
     .post(`${CONTRACT_URL}/${id}/download`)
     .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'banco.png'); //or any other extension
      document.body.appendChild(link);
      link.click();
   })
 }

const createContract =  (contract: Contracts): Promise<ResponseContract> => {
  let formData = new FormData()
  if (contract.file) {
    formData.append('file', contract.file)
  }
  if (contract.title) {
    formData.append('title', contract.title)
  }
  if (contract.begin_date) {
    formData.append('begin_date', contract.begin_date)
  }
  if (contract.end_date) {
    formData.append('end_date', contract.end_date)
  }
  if (contract.value) {
    formData.append('value', contract.value.toLocaleString())
  }
  if (contract.client_id) {
    formData.append('client_id', contract.client_id.toLocaleString())
  }
  if (contract.project_id) {
    formData.append('project_id', contract.project_id.toLocaleString())
  }
  if (contract.type) {
    formData.append('type', contract.type.toLocaleString())
  }else{
    formData.append('type', '1')
  }
  return axios
    .post(`${CONTRACT_URL}/novo`, formData)
    .then((response: AxiosResponse<ResponseContract>) => response.data)
}

const updateContract =  (contract: Contracts): Promise<ResponseContract> => {
  let formData = new FormData()
  if (contract.file) {
    formData.append('file', contract.file)
  }
  if (contract.title) {
    formData.append('title', contract.title)
  }
  if (contract.begin_date) {
    formData.append('begin_date', contract.begin_date)
  }
  if (contract.end_date) {
    formData.append('end_date', contract.end_date)
  }
  if (contract.value) {
    formData.append('value', contract.value.toLocaleString())
  }
  if (contract.client_id) {
    formData.append('client_id', contract.client_id.toLocaleString())
  }
  if (contract.project_id) {
    formData.append('project_id', contract.project_id.toLocaleString())
  }
  if (contract.type) {
    formData.append('type', contract.type.toLocaleString())
  }else{
    formData.append('type', '1')
  }
  return axios
    .post(`${CONTRACT_URL}/${contract.id}/editar`, formData)
    .then((response: AxiosResponse<ResponseContract>) => response.data)
}

const deleteContract =  (id: ID): Promise<void> => {
   return axios.delete(`${CONTRACT_URL}/${id}/excluir`).then(() => {})

}

const deleteSelectedContracts =  (contractIds: Array<string | undefined>): Promise<void> => {
  const requests = contractIds.map((id, index) => axios.delete(`${CONTRACT_URL}/${id}`))
   return axios.all(requests).then(() => {})
}

export {getContract,getContractExpire,openViewContract,searchContract, deleteContract, deleteSelectedContracts, getContractById, createContract, updateContract, getContractByClientId, getContractByProjectId, getContracts}
