/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, ChangeEvent} from 'react'
import {initialQueryState, KTSVG, QUERIES, useDebounce} from '../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useParams} from "react-router-dom";
import { useQueryClient } from 'react-query';

const TasksByUserListSearchComponent = () => {
    const {updateState} = useQueryRequest()
    const [searchTerm, setSearchTerm] = useState<string>('')
    const debouncedSearchTerm = useDebounce(searchTerm, 150)
    const queryClient = useQueryClient()
    const [currentFilter, setCurrentFilter] = useState('Aberta');

    useEffect(
        () => {
            if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
                updateState({search: debouncedSearchTerm, ...initialQueryState})
            }
        },
        [debouncedSearchTerm]
    )


        return (
            <div className='card-title w-100 w-md-fit-content'>

                <div className='d-flex align-items-center position-relative me-5'>
                   <select className="form-select form-select-sm form-control form-control-solid w-350px w-100 w-md-300px h-45px" id="period"
                        onChange={(e) => updateState({search: e.target.value, ...initialQueryState})}>
                        <option value="Aberta" selected={currentFilter === 'Aberta' } >Tarefas abertas</option>
                        <option value="Fechada" selected={currentFilter === 'Fechada' } >Tarefas fechadas</option>
                 </select>
                </div>

                <div className='w-100 d-flex align-items-center position-relative my-1'>
                    <KTSVG
                        path='/media/icons/duotune/general/gen021.svg'
                        className='svg-icon-1 position-absolute ms-3'
                    />

                    <input
                        type='text'
                        data-kt-client-table-filter='search'
                        className='form-control form-control-solid w-350px ps-15 w-100 w-md-300px'
                        placeholder='Buscar tarefa'
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
                {/* end::Search */}
            </div>
        )

}

export {TasksByUserListSearchComponent}
