import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {Info, Projects, RecentActivities, ProjectsQueryResponse, TimeTrackingSquadRandomAdm, RecentActivitiesQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const DASHBOARD_URL = `${API_URL}/api/dashboard`

const getDashboardInfo = (): Promise<Info> => {
  return axios
    .get(`${DASHBOARD_URL}/dashbord-info`)
    .then((response: AxiosResponse<Response<Info>>) => response.data)
    .then((response: Response<Info>) => response.data)
}

const getDashboardInfoUser = (): Promise<Info> => {
  return axios
    .get(`${DASHBOARD_URL}/dashbord-info-user`)
    .then((response: AxiosResponse<Response<Info>>) => response.data)
    .then((response: Response<Info>) => response.data)
}

const getProjects = (): Promise<Projects> => {
  return axios
    .get(`${DASHBOARD_URL}/projects`)
    .then((response: Response<Projects>) => response.data)
}

const getRecurringProjects = (): Promise<Projects> => {
  return axios
    .get(`${DASHBOARD_URL}/recurring-projects`)
    .then((response: Response<Projects>) => response.data)
}

const getOpenedProjects = (): Promise<Projects> => {
  return axios
    .get(`${DASHBOARD_URL}/opened-projects`)
    .then((response: Response<Projects>) => response.data)
}

const getRecentActivities = (): Promise<RecentActivitiesQueryResponse> => {
  return axios
    .get(`${DASHBOARD_URL}/recent-activities`)
    .then((response: Response<RecentActivitiesQueryResponse>) => response.data)
}

const getTimeTrackingBySquadRandom = (): Promise<TimeTrackingSquadRandomAdm> => {
  return axios
    .get(`${DASHBOARD_URL}/timetracking-squad-random-adm`)
    .then((response: Response<TimeTrackingSquadRandomAdm>) => response.data)
}

const getTimeTrackingBySquadRandomMonth = (): Promise<TimeTrackingSquadRandomAdm> => {
  return axios
    .get(`${DASHBOARD_URL}/timetracking-squad-random-adm-month`)
    .then((response: Response<TimeTrackingSquadRandomAdm>) => response.data)
}

export {getDashboardInfo, getDashboardInfoUser, getProjects, getRecurringProjects, getOpenedProjects, getRecentActivities, getTimeTrackingBySquadRandom, getTimeTrackingBySquadRandomMonth}
