import {useIntl} from 'react-intl'
import {SprintReportStatistics} from '../../../../app/modules/reports/components/sprint-user/core/_models'
import {Link, useSearchParams} from 'react-router-dom'
import {useState} from 'react'
import {TasksModal} from '../../modals/sprint-report/TasksModal'

type Props = {
  userName: string
  statistics: SprintReportStatistics[]
}

export function TableSprintStatistics({statistics, userName}: Props) {
  const intl = useIntl()
  const [showModal, setShowModal] = useState(false)
  const [status, setStatus] = useState('')
  const [searchParams, setSearchParams] = useSearchParams()

  function handleTaskStatusClick(status: string, sprintId: number) {
    const newParams = new URLSearchParams(searchParams)
    newParams.set('sprintId', String(sprintId))
    setSearchParams(newParams)
    setStatus(status)
    setShowModal(true)
  }

  function handleCloseModal() {
    setShowModal(false)
    const newParams = new URLSearchParams(searchParams)
    if (!newParams.get('sprintId')) return

    newParams.delete('sprintId')
    setSearchParams(newParams)
  }

  return (
    <div className={`card mb-5 mb-xl-8`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Estatísticas detalhadas</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{userName}</span>
        </h3>
      </div>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-150px'>Sprint</th>
                <th className='min-w-140px'>Total de tarefas</th>
                <th className='min-w-120px'>Não iniciadas</th>
                <th className='min-w-120px'>Em andamento</th>
                <th className='min-w-100px'>Entregues</th>
                <th className='min-w-100px'>Progresso</th>
              </tr>
            </thead>
            <tbody>
              {statistics.map((sprint) => (
                <tr>
                  <td>
                    <div className='d-flex justify-content-start flex-column'>
                      <Link
                        to={`/sprints?sprintId=${sprint.id}`}
                        target='_blank'
                        className='text-dark fw-bold text-hover-primary fs-6'
                      >
                        Sprint #{sprint.id} - {sprint.sprint_name}
                      </Link>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        {new Date(sprint.begin_date).toLocaleDateString(intl.locale, {
                          timeZone: 'UTC',
                        })}{' '}
                        -{' '}
                        {new Date(sprint.end_date).toLocaleDateString(intl.locale, {
                          timeZone: 'UTC',
                        })}
                      </span>
                    </div>
                  </td>
                  <td>
                    <span
                      role='button'
                      className='text-dark fw-bold text-hover-primary d-block fs-6'
                      onClick={() => handleTaskStatusClick('', sprint.id)}
                    >
                      {sprint.total_tasks_in_sprint}
                    </span>
                  </td>
                  <td>
                    <span
                      role='button'
                      className='text-dark fw-bold text-hover-primary d-block fs-6'
                      onClick={() => handleTaskStatusClick('Backlog', sprint.id)}
                    >
                      {sprint.tasks_backlog}
                    </span>
                  </td>
                  <td>
                    <span
                      role='button'
                      className='text-dark fw-bold text-hover-primary d-block fs-6'
                      onClick={() => handleTaskStatusClick('Em andamento', sprint.id)}
                    >
                      {sprint.tasks_in_progress}
                    </span>
                  </td>
                  <td>
                    <span
                      role='button'
                      className='text-dark fw-bold text-hover-primary d-block fs-6'
                      onClick={() => handleTaskStatusClick('Entregue', sprint.id)}
                    >
                      {sprint.tasks_delivered}
                    </span>
                  </td>
                  <td className='text-end'>
                    <div className='d-flex flex-column w-100 me-2'>
                      <div className='d-flex flex-stack mb-2'>
                        <span className='text-muted me-2 fs-7 fw-semibold'>
                          {sprint.sprint_progress}%
                        </span>
                      </div>
                      <div className='progress h-6px w-100'>
                        <div
                          className={`progress-bar ${
                            sprint.sprint_progress >= 80 ? 'bg-success' : 'bg-danger'
                          }`}
                          role='progressbar'
                          style={{width: `${sprint.sprint_progress}%`}}
                        ></div>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {showModal && <TasksModal show={showModal} handleClose={handleCloseModal} status={status} />}
    </div>
  )
}
