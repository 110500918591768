/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {useIntl} from 'react-intl'
import {MenuComponent} from '../../../../../../../../_metronic/assets/ts/components'
import {ID, KTSVG, QUERIES, secondsToHours} from '../../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import { AdjustTimeUserModal } from '../../../../../adjust-time-user/AdjustTimeUserModal'

type Props = {
  task_id: ID
  task_totaltime: number
}

const TaskActionsCell: FC<Props> = ({task_id, task_totaltime}) => {
  const intl = useIntl()
  const [showModalAdjustHours, setShowModalAdjustHours] = useState<boolean>(false)

  const openModal = () => {
    setShowModalAdjustHours(true)
}

  return (
  <>
    <div className="d-flex justify-content-center align-items-center">
      <div className="fs-4 me-3">{secondsToHours(task_totaltime)}</div>
      <div>
        <a
          href='#'
          className='text-primary text-hover-muted'
          data-kt-menu-trigger='click'
          onClick={openModal}
          data-kt-menu-placement='bottom-end'
        >
          {intl.formatMessage({id: 'TABLE.GENERAL.LABEL.ADJUST'})}
        </a>


      </div>
    </div>
    {/* 
    //@ts-ignore */}
    <AdjustTimeUserModal
           show={showModalAdjustHours}
           handleClose={() => setShowModalAdjustHours(false)}
           taskId={task_id}
    />

  </>
  )
}

export {TaskActionsCell}
