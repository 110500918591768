import axios, {AxiosResponse} from 'axios'
import {SprintReport, SprintUser, Task} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const GET_REPORTS_URL = `${API_URL}/api/relatorio`
const SPRINT_URL = `${GET_REPORTS_URL}/sprint`

export function getSprintReportById(id: string): Promise<SprintReport> {
  return axios
    .get(`${SPRINT_URL}/${id}`)
    .then((response: AxiosResponse<SprintReport>) => response.data)
}

export function getSprintUsers(id: string): Promise<SprintUser[]> {
  return axios
    .get(`${SPRINT_URL}/${id}/users`)
    .then((response: AxiosResponse<SprintUser[]>) => response.data)
}

export function getTasksByUserSprintAndStatus(sprintId: string, userId: number, status: string): Promise<Task[]> {
  return axios
    .get(`${SPRINT_URL}/${sprintId}/user/${userId}/tasks/status/${status ? status : null}`)
    .then((response: AxiosResponse<Task[]>) => response.data)
}
