import {FormikProps} from 'formik'
import {ID, Response} from '../../../../../_metronic/helpers'

export type User = {
  id: number
  avatar: string
  name: string
  username: string
  email: string
  phone: string
  job_role: string
  instance_id: string
  workdays: Array<number>
  administrator: number
  edit_costs?: boolean
  register_hours?: boolean
  password: string
  in_company_since: string
  birthday: string
  cost_hour: number
  shift_time: string
  creator_id: string
  active: number
  format_timeShift: string
}

export type ResponseUser = {
  users: User
  message: string
  errors: boolean
}

export type UsersQueryResponse = Response<Array<User>>

export const initialUser: User = {
  id: 0,
  username: '',
  email: '',
  job_role: '',
  phone: '',
  avatar: '',
  password: '',
  workdays: [],
  cost_hour: 0,
  administrator: 0,
  edit_costs: true,
  shift_time: '',
  register_hours: false,
  name: '',
  in_company_since: '',
  birthday: '',
  instance_id: '',
  creator_id: '',
  active: 0,
  format_timeShift: '',
}

export type StepProps = {
  data: User & {option?: string; shiftTimeOption?: string}
  updateData: (fieldsToUpdate: Partial<User & {option: string; shiftTimeOption: string}>) => void
  hasError: boolean
  props: FormikProps<User>
}
