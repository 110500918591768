import {FC} from 'react'
import {useQuery} from 'react-query'
import {useLocation} from 'react-router-dom'
import {TaskEditModalForm} from './TaskEditModalForm'
import {ID, QUERIES} from '../../../../../../_metronic/helpers'
import {getTaskById} from '../core/_requests'
import {Loading} from '../../../../../../_metronic/partials/components/loading/Loading'

type Props = {
  handleClose: () => void | undefined
  taskId: ID
}

const TaskEditModalFormWrapper: FC<Props> = ({handleClose, taskId}) => {
  const location = useLocation()
  const {pathname} = location
  const {
    isLoading,
    data: task,
    error,
  } = useQuery(
    `${QUERIES.TASKS_LIST}-task-${taskId}`,
    () => {
      return getTaskById(taskId);
    },
    {
      enabled: !!taskId,
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      onSuccess: (data) => !data.tasks && window.location.assign(pathname),
    }
  )

  if (!isLoading && !error) {
    return <TaskEditModalForm isTaskLoading={isLoading} handleClose={handleClose} task={task!} />
  } else {
    return <Loading />
  }
}

export {TaskEditModalFormWrapper}
