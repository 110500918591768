import {TaskSprint, Board, Stage} from "./models";
import {getTasksByStage} from "./tasks";
import { Stages } from "../../../app/modules/sprints/components/kanban/core/_models";


export const initializeBoard = (tasks: TaskSprint[], stages?: Stages[]) => {

  const boardSections: Board = {};

  if(stages) {


    {stages.map((stage, index) => {
      boardSections[stage.id] = getTasksByStage(
        tasks,
        stage.id.toString()
      );
      })}
  }

  return boardSections;
};

export const findBoardSectionContainer = (
  boardSections: Board,
  id: string
) => {
  if (id in boardSections) {
    return id;
  }
  
  const container = Object.keys(boardSections).find((key) =>
  id == undefined ? 0 : boardSections[key].find((item) => item !== undefined ? item.id === id : 0)
  );
  return container;
};

