import {FC, useEffect, useRef} from 'react'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'
import { ProjectModalContent } from '../../../../_metronic/partials/modals/project-create-modal-stepper/ProjectModalContent'

const Step4: FC = () => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  useEffect(() => {
    loadStepper()
  }, [])

  return (
    <div className='w-100'>
      {/* <!--begin::Heading--> */}
      <div className='pb-10 pb-lg-15'>
        {/* <!--begin::Title--> */}
        <h2 className='fw-bold text-dark'>Meu primeiro projeto</h2>
        {/* <!--end::Title--> */}
        {/* <!--begin::Notice--> */}
        <div className='text-gray-700 fw-semibold fs-5'>
          Vamos cadastrar as informações sobre o seu primeiro projeto. Aqui, você pode cadastrar um
          projeto do tipo recorrente, isto é, um projeto no qual seu cliente pagará um fee mensal,
          ou, um projeto de escopo fechado. Isto é, aquele projeto que tem início, meio e fim e seu
          cliente pagará um valor fechado pelo serviço.{' '}
          {/* <a href='#' className='text-primary fw-bold'>
            Entenda os tipos de projeto neste tutorial
          </a>
          . */}
        </div>
        {/* <!--end::Notice--> */}
      </div>
      {/* <!--end::Heading--> */}
      <div className='fv-row'>
        <div className='row'>
          <div className='col-12 bg-body'>
            <ProjectModalContent stepper={stepper} stepperRef={stepperRef} />
          </div>
        </div>
      </div>
    </div>
  )
}

export {Step4}
