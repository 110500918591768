import { useIntl } from 'react-intl'
import {KTSVG} from '../../../../../../_metronic/helpers'

type Props = {
  handleClose: () => void | undefined
}

const ContractEditModalHeader = ({handleClose}:Props) => {
  const intl = useIntl()

  return (
    <div className='modal-header'>
      <h2 className='fw-bolder'>{intl.formatMessage({id: 'MODAL.TITLE.CONTRACT'})}</h2>
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-projects-modal-action='close'
        onClick={handleClose}
        style={{cursor: 'pointer'}}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </div>
      {/* end::Close */}
    </div>
  )
}

export {ContractEditModalHeader}
