import {Route, Routes, Outlet, useParams} from 'react-router-dom'
import { TasksByUserIdListWrapper } from './components/TasksByUserList'

const TasksByUserPage = () => {

    return (
        <Routes>
            <Route element={<Outlet />}>

                <Route
                    path=':userId'
                    element={
                        <>
                            <TasksByUserIdListWrapper />
                        </>
                    }
                />

            </Route>
        </Routes>
    )
}

export default TasksByUserPage