const QUERIES = {
  USERS_LIST: 'users-list',
  COMPANY_LIST: 'company-list',
  CONTRACTS_LIST: 'contracts-list',
  TEAMS_LIST: 'teams-list',
  PROJECTS_LIST: 'projects-list',
  PROJECT_EXTRA_COST_LIST: 'project-extra-costs-list',
  PROJECT_EXTRA_HOUR_LIST: 'project-extra-hours-list',
  PROJECT_CLOSE_LIST: 'project-close-list',
  PROJECT_MILESTONE: 'project-milestone-list',
  PROJECT_SCHEDULE: 'project-schedule',
  PROJECTS_WITH_SCHEDULE_LIST: 'projects-with-schedule-list',
  EVENTS_LIST: 'events-list',
  CONFIG_LIST: 'config-list',
  TASKS_LIST: 'tasks-list',
  MYTASKS_LIST: 'mytasks-list',
  TASKS_TIMER_LIST: 'tasks-timer_list',
  TASKS_ATTACHEMENT_LIST: 'tasks-attachement-list',
  TASKS_CHECKLIST: 'tasks-checklist-list',
  TICKETS_LIST: 'tickets-list',
  TAGS_LIST: 'tags-list',
  FORECAST_LIST: 'forecast-list',
  CLIENTS_LIST: 'clients-list',
  SPRINTS_LIST: 'sprints-list',
  STAGES_LIST: 'stages-list',
  SQUAD_LIST: 'squad-list',
  REPORTS_LIST: 'reports-list',
  WIKI_LIST: 'wiki-list',
  PROFIT_MARGIN_RECURRING_LIST: 'profit-margin-recurring-list',
  PROFIT_MARGIN_CLOSED_SCOPE_LIST: 'profit-margin-closed-scope-list',
  SCHEDULE_LIST: 'schedule-list',
  MODAL_SCHEDULE_LIST: 'modal-schedule-list',
  SCHEDULE_PHASE_LIST: 'schedule-phase-list',
  SELECT_AUTOCOMPLETE_LIST: 'select-autocomplete-list',
  DASHBOARD_INFO: 'dashboard-info',
  DASHBOARD_INFO_USER: 'dashboard-info-user',
  DASHBOARD_PROJECTS: 'dashboard-projects',
  DASHBOARD_RECURRING_PROJECTS: 'dashboard-recurring-projects',
  DASHBOARD_OPENED_PROJECTS: 'dashboard-opened-projects',
  DASHBOARD_RECENT_ACTIVITIES: 'dashboard-recent-activities',
  DASHBOARD_TIMETRACKING: 'dashboard-info-user-timetracking',
  SCHEDULE_VIEW: 'schedule-view',
  BILLINGS_LIST: 'billings-list'
}

export {QUERIES}
