import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import Box from "@mui/material/Box";

interface IconUserModel {
    name: string
    avatar?: string
    color?: string
    initials?: string
}

type Props = {
    users?: Array<IconUserModel>
    classes?: string
}


const UsersSymbolGroupCard: FC<Props> = ({users = undefined, classes = undefined}) => {
    return (
        <>

        <div className={`symbol symbol-group symbol-hover ${classes}`}>
        {users && users.length > 0 ?
          users.map((user, i) => {

            i++;
            if(i <= 12) {
              return (
                //@ts-ignore
                <OverlayTrigger
                  key={`${i}-${user.name}`}
                  placement='top'
                  //@ts-ignore
                  overlay={<Tooltip id='tooltip-user-name'>{user.name}</Tooltip>}
                >
                  <div className='symbol symbol-35px symbol-circle'>
                    {user.avatar != null ? (
                      <img src={process.env.REACT_APP_THEME_API_URL + '/storage/' + user.avatar} className='symbol object-fit-cover'/>
                    ) : (
                      <span className='symbol symbol-label bg-primary text-inverse-primary fw-bolder'>
                        {user.name.substring(0, 1)}
                      </span>
                    )}
                  </div>
                </OverlayTrigger>
              )
            } else {
              <div className='symbol symbol-35px symbol-circle'>
                <span className='symbol symbol-label bg-primary text-inverse-primary fw-bolder me-2'>+{users.length}</span>
             </div>
            }
           
          }) 
        :
        null 
        } 

        </div>

        </>
    )
}

export {UsersSymbolGroupCard}



