import { secondsToHours } from "../../../../../helpers"

type Props = {
  task_totaltime: number
}

export function TimeCell({task_totaltime}: Props) {
  return (
    <div className='d-flex justify-content-center align-items-center'>
      <div className='fs-4 me-3'>{secondsToHours(task_totaltime)}</div>
    </div>
  )
}
