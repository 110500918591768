import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import clsx from 'clsx'
import {useMutation, useQueryClient} from 'react-query'
import {mask} from 'remask'
import {useSearchParams} from 'react-router-dom'
import {KTSVG, QUERIES, isNotEmpty} from '../../../helpers'
import {Loading} from '../../components/loading/Loading'
import {Client, ResponseClient} from '../../../../app/modules/clients/components/core/_models'
import {useListView} from '../../../../app/modules/clients/components/core/ListViewProvider'
import {createClient, updateClient} from '../../../../app/modules/clients/components/core/_requests'
import {useQueryResponse} from '../../../../app/modules/clients/components/core/QueryResponseProvider'
import {Toast} from '../../widgets'

type Props = {
  isClientLoading: boolean
  client: Client
  handleClose?: () => void | undefined
}

const ClientEditModalForm: FC<Props> = ({client, isClientLoading, handleClose}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  if (searchParams.get('new_client_name')) {
    client.name! = searchParams.get('new_client_name')!
  }
  const intl = useIntl()
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [data, setData] = useState<Client>(client)
  const queryClient = useQueryClient()
  const {query} = useQueryResponse()

  const editClientSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, intl.formatMessage({id: 'FORM.INPUT.VALIDATION.MIN_LENGTH'}, {min: '3'}))
      .max(50, intl.formatMessage({id: 'FORM.INPUT.VALIDATION.MAX_LENGTH'}, {max: '50'}))
      .required(intl.formatMessage({id: 'FORM.INPUT.VALIDATION.REQUIRED'}))
      .nullable(),
    email: Yup.string()
      .email(intl.formatMessage({id: 'FORM.INPUT.VALIDATION.EMAIL'}))
      .required(intl.formatMessage({id: 'FORM.INPUT.VALIDATION.REQUIRED'}))
      .nullable(),
    phone: Yup.string()
      .min(14, intl.formatMessage({id: 'FORM.INPUT.VALIDATION.MIN_LENGTH'}, {min: '14'}))
      .required(intl.formatMessage({id: 'FORM.INPUT.VALIDATION.REQUIRED'}))
      .nullable(),
  })

  const updateData = (fieldsToUpdate: Partial<Client>) => {
    const updatedData = {...data, ...fieldsToUpdate}
    setData(updatedData)
  }

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const update = useMutation(() => updateClient(data), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: (response) => {
      // ✅ update detail view directly
      Toast.fire({
        icon: 'success',
        title: intl.formatMessage(
          {id: 'ALERT.LABEL.SUCCESS_UPDATE_ITEM'},
          {item: intl.formatMessage({id: 'TABLE.GENERAL.LABEL.CLIENT'})}
        ),
        customClass: {
          popup: 'bg-light-success',
          title: 'text-success',
        },
      })

      cancel(true)
      queryClient.invalidateQueries(`${QUERIES.CLIENTS_LIST}-${query}`)
      if (handleClose) {
        handleClose()
      }
    },
    onError: (error) => {
      Toast.fire({
        icon: 'error',
        iconHtml: '<span>!</span>',
        title: intl.formatMessage(
          {id: 'ALERT.LABEL.ERROR_UPDATE_ITEM'},
          {item: intl.formatMessage({id: 'TABLE.GENERAL.LABEL.CLIENT'})}
        ),
        customClass: {
          popup: 'bg-light-danger',
          title: 'text-danger',
        },
      })
    },
  })

  const create = useMutation(() => createClient(data), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: (response) => {
      setSearchParams({_new: response.client.id!})
      // ✅ update detail view directly
      Toast.fire({
        icon: 'success',
        title: intl.formatMessage(
          {id: 'ALERT.LABEL.SUCCESS_CREATE_ITEM'},
          {item: intl.formatMessage({id: 'TABLE.GENERAL.LABEL.CLIENT'})}
        ),
        customClass: {
          popup: 'bg-light-success',
          title: 'text-success',
        },
      })

      cancel(true)
      queryClient.invalidateQueries(`${QUERIES.CLIENTS_LIST}-${query}`)
      queryClient.invalidateQueries(`${QUERIES.SELECT_AUTOCOMPLETE_LIST}-clientes`)
      if (handleClose) {
        handleClose()
      }
    },
    onError: (error) => {
      Toast.fire({
        icon: 'error',
        iconHtml: '<span>!</span>',
        title: intl.formatMessage(
          {id: 'ALERT.LABEL.ERROR_CREATE_ITEM'},
          {item: intl.formatMessage({id: 'TABLE.GENERAL.LABEL.CLIENT'})}
        ),
        customClass: {
          popup: 'bg-light-danger',
          title: 'text-danger',
        },
      })
    },
  })

  const formik = useFormik({
    initialValues: data,
    validationSchema: editClientSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)

      try {
        if (isNotEmpty(values.id)) {
          return update.mutateAsync()
        } else {
          return create.mutateAsync()
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  return (
    <>
      <div className='modal-body'>
        <form
          id='kt_modal_add_project_form'
          className='form'
          onSubmit={formik.handleSubmit}
          noValidate
        >
          {/* begin::Scroll */}
          <div
            className='d-flex flex-column'
            id='kt_modal_add_project_scroll'
            data-kt-scroll='true'
            data-kt-scroll-activate='{default: false, lg: true}'
            data-kt-scroll-max-height='auto'
            data-kt-scroll-dependencies='#kt_modal_add_project_header'
            data-kt-scroll-wrappers='#kt_modal_add_project_scroll'
            data-kt-scroll-offset='300px'
          >
            <div className='row justify-content-between'>
              <div className='col-lg-12 mb-5 mb-lg-0'>
                <div className='mb-4'>
                  <label className='required fw-bold'>
                    {intl.formatMessage({id: 'FORM.INPUT.NAME.NAME'})}
                  </label>

                  <input
                    {...formik.getFieldProps('name')}
                    placeholder='TaskRush'
                    type='text'
                    name='name'
                    value={data.name ?? ''}
                    onChange={(e: any) => {
                      formik.setFieldValue('name', e.target.value)
                      updateData({
                        name: e.target.value,
                      })
                    }}
                    className={clsx(
                      'form-control form-control-solid py-2 rounded fw-normal',
                      {'is-invalid': formik.touched.name && formik.errors.name},
                      {
                        'is-valid': formik.touched.name && !formik.errors.name,
                      }
                    )}
                    autoComplete='off'
                    disabled={formik.isSubmitting || isClientLoading}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.name}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='mb-4'>
                  <label className='fw-bold'>
                    {intl.formatMessage({id: 'FORM.INPUT.NAME.RESPONSIBLE'})}
                  </label>

                  <input
                    placeholder='João Silva'
                    type='text'
                    value={data.responsible ?? ''}
                    name='responsible'
                    onChange={(e: any) =>
                      updateData({
                        responsible: e.target.value,
                      })
                    }
                    className={clsx(
                      'form-control form-control-solid py-2  rounded fw-normal',
                      {'is-invalid': formik.touched.responsible && formik.errors.responsible},
                      {
                        'is-valid': formik.touched.responsible && !formik.errors.responsible,
                      }
                    )}
                    autoComplete='off'
                    disabled={formik.isSubmitting || isClientLoading}
                  />
                  {formik.touched.responsible && formik.errors.responsible && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.responsible}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='mb-4'>
                  <label className='required fw-bold'>
                    {intl.formatMessage({id: 'FORM.INPUT.NAME.EMAIL'})}
                  </label>

                  <input
                    {...formik.getFieldProps('email')}
                    placeholder='meucliente@gmail.com'
                    type='email'
                    value={data.email ?? ''}
                    name='email'
                    onChange={(e: any) => {
                      formik.setFieldValue('email', e.target.value)
                      updateData({
                        email: e.target.value,
                      })
                    }}
                    className={clsx(
                      'form-control form-control-solid py-2  rounded fw-normal',
                      {'is-invalid': formik.touched.email && formik.errors.email},
                      {
                        'is-valid': formik.touched.email && !formik.errors.email,
                      }
                    )}
                    autoComplete='off'
                    disabled={formik.isSubmitting || isClientLoading}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.email}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='mb-4'>
                  <label className='required fw-bold'>
                    {intl.formatMessage({id: 'FORM.INPUT.NAME.PHONE'})}
                  </label>

                  <input
                    {...formik.getFieldProps('phone')}
                    placeholder='(31) 91234-5678'
                    type='text'
                    name='phone'
                    onChange={(e: any) => {
                      formik.setFieldValue('phone', e.target.value)
                      updateData({
                        phone: mask(e.target.value, ['99 9 9999-9999']),
                      })
                    }}
                    value={data.phone ?? ''}
                    className={clsx(
                      'form-control form-control-solid py-2  rounded fw-normal',
                      {'is-invalid': formik.touched.phone && formik.errors.phone},
                      {
                        'is-valid': formik.touched.phone && !formik.errors.phone,
                      }
                    )}
                    autoComplete='off'
                    disabled={formik.isSubmitting || isClientLoading}
                  />
                  {formik.touched.phone && formik.errors.phone && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.phone}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='mb-4'>
                  <label className='fw-bold'>
                    {intl.formatMessage({id: 'FORM.INPUT.NAME.COMMENTS'})}
                  </label>

                  <input
                    placeholder='Obs...'
                    type='text'
                    value={data.observation ?? ''}
                    name='observation'
                    onChange={(e: any) =>
                      updateData({
                        observation: e.target.value,
                      })
                    }
                    className={clsx(
                      'form-control form-control-solid py-2  rounded fw-normal',
                      {'is-invalid': formik.touched.observation && formik.errors.observation},
                      {
                        'is-valid': formik.touched.observation && !formik.errors.observation,
                      }
                    )}
                    autoComplete='off'
                    disabled={formik.isSubmitting || isClientLoading}
                  />
                  {formik.touched.observation && formik.errors.observation && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.observation}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <hr className='d-lg-none' />
              <div className='col-lg-5'>
                {/* <div className='mb-10 mt-5 mt-lg-0'>
                    <div className='mb-4'>
                      <div className='form-check form-switch form-check-custom form-check-solid me-10'>
                        <input
                          type='checkbox'
                          name='active'
                          id='isActive'
                          checked={data.active}
                          onChange={(e: any) => {
                            updateData({
                              active: e.target.checked,
                            })
                          }}
                          className={clsx('form-check-input h-25px w-40px')}
                          autoComplete='off'
                          disabled={formik.isSubmitting || isClientLoading}
                        />
                        {formik.touched.active && formik.errors.active && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.active}</span>
                            </div>
                          </div>
                        )}

                        <label className='form-check-label' htmlFor='isActive'>
                          {intl.formatMessage({id: 'FORM.INPUT.NAME.IS_CLIENT_ACTIVE'})}
                        </label>
                      </div>
                    </div>
                  </div> */}
                {/* <hr/> */}
                {/* <div className='mt-10'>
                <p className="fs-5 fw-bold mb-5">{intl.formatMessage({id: 'FORM.INPUT.NAME.CATEGORIES'})}</p>
                
                <div className='mb-4'>
                  <div className="form-check form-switch form-check-custom form-check-solid me-10">
                    
                    <input
                      {...formik.getFieldProps('categories')}
                      type='checkbox'
                      name='categories'
                      id='marketing'
                      className={clsx(
                        'form-check-input h-25px w-40px',
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting || isClientLoading}
                    />
                    <label className='form-check-label' htmlFor='marketing'>Marketing Digital</label>
                    
                  </div>
                </div>
                
                
               
                <div className='mb-4'>
                  <div className="form-check form-switch form-check-custom form-check-solid me-10">
                    
                    <input
                      {...formik.getFieldProps('categories')}
                      type='checkbox'
                      name='categories'
                      id='development'
                      className={clsx(
                        'form-check-input h-25px w-40px',
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting || isClientLoading}
                    />
                   
                    
                    <label className='form-check-label' htmlFor='development'>Desenvolvimento</label>
                    
                  </div>
                </div>
                
                
               
                <div className='mb-4'>
                  <div className="form-check form-switch form-check-custom form-check-solid me-10">
                    
                    <input
                      {...formik.getFieldProps('categories')}
                      type='checkbox'
                      name='categories'
                      id='hosting'
                      className={clsx(
                        'form-check-input h-25px w-40px',
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting || isClientLoading}
                    />
                   
                    
                    <label className='form-check-label' htmlFor='hosting'>Hospedagem</label>
                    
                  </div>
                </div>
                
              </div> */}
              </div>
            </div>
          </div>
          {/* end::Scroll */}

          {/* begin::Actions */}
          <div className='text-end pt-15'>
            <button
              type='submit'
              className='btn btn-primary py-2'
              data-kt-projects-modal-action='submit'
              disabled={
                isClientLoading || formik.isSubmitting || !formik.isValid || !formik.touched
              }
            >
              <span className='indicator-label'>
                {intl.formatMessage({id: 'FORM.GENERAL.SAVE_BUTTON'})}
              </span>
              {(formik.isSubmitting || isClientLoading) && (
                <span className='indicator-progress'>
                  {intl.formatMessage({id: 'GENERAL.LOADING'})}{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Actions */}
        </form>
        {(formik.isSubmitting || isClientLoading) && <Loading />}
      </div>
    </>
  )
}

export {ClientEditModalForm}
