import {FC, useState} from 'react'
import {useDroppable} from '@dnd-kit/core'
import {SortableContext, verticalListSortingStrategy} from '@dnd-kit/sortable'
import {KanbanCard} from './KanbanCard'
import {TaskModal} from '../../../../../_metronic/partials/modals/task/TaskModal'
import {useIntl} from 'react-intl'
import {Task, TaskKanban} from '../../core/_models'
import {stages} from '../kanban/helper/stages'

type Props = {
  id: string
  tasks: Task[]
  children?: JSX.Element[] | undefined
}

const KanbanLane: FC<Props> = ({id, children, tasks}) => {
  const {setNodeRef} = useDroppable({id})
  const [showTaskModal, setShowTaskModal] = useState(false)
  const intl = useIntl()

  return (
    <>
      <div
        className='text-white text-capitalize fw-semi-bold fs-4 h-32px bg-blue-light-900 px-7 py-2 rounded'
        style={{backgroundColor: '#1289A7'}}
      >
        {stages.filter((stage) => stage === id)[0]}
      </div>

      <div className='d-flex fw-semi-bold fs-7 justify-content-center py-1'>
        <span role='button' data-ui='add-task' onClick={() => setShowTaskModal(true)}>
          <i className='fa-solid fa-plus'></i>
          {intl.formatMessage({id: 'GENERAL.ADD_TASK'})}
        </span>
      </div>

      <SortableContext id={id} items={tasks as TaskKanban[]} strategy={verticalListSortingStrategy}>
        <div
          ref={setNodeRef}
          className='d-flex flex-column h-100 bg-gray-100 p-5 border-radius overflow-scroll'
        >
          {tasks.map((task, index) => task && <KanbanCard key={index} task={task} />)}
        </div>
      </SortableContext>

      <TaskModal
        show={showTaskModal}
        handleClose={() => setShowTaskModal(false)}
        stageId={id}
      />
    </>
  )
}

export {KanbanLane}
