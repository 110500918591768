import React, {ChangeEvent, FC, useEffect, useRef, useState} from 'react'
import {SearchComponent} from '../../../assets/ts/components'
import {ID, KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useIntl} from 'react-intl'
import {useMutation} from 'react-query'
import {filterByValue} from '../../../../app/modules/user/components/users-list/core/_requests'
import {
  Clients,
  Contracts,
  Projects,
  Tasks,
  Wikis,
} from '../../../../app/modules/user/components/users-list/core/_models'
import {useAuth} from '../../../../app/modules/auth'
import {useListView} from '../../modals/task/core/ListViewProvider'
import {TaskEditModal} from '../../../../app/modules/project/components/tasks-list/task-edit-modal/TaskEditModal'
import {useSearchParams} from 'react-router-dom'

const Search: FC = () => {
  const [menuState, setMenuState] = useState<'main' | 'advanced' | 'preferences'>('main')
  const element = useRef<HTMLDivElement | null>(null)
  const wrapperElement = useRef<HTMLDivElement | null>(null)
  const resultsElement = useRef<HTMLDivElement | null>(null)
  const emptyElement = useRef<HTMLDivElement | null>(null)
  const intl = useIntl()
  const [value, setValue] = useState<string>('')
  const [projects, setProjects] = useState<Array<Projects>>([])
  const [tasks, setTasks] = useState<Array<Tasks>>([])
  const [contracts, setContracts] = useState<Array<Contracts>>([])
  const [wikis, setWikis] = useState<Array<Wikis>>([])
  const [clients, setClients] = useState<Array<Clients>>([])
  const {currentUser} = useAuth()
  const [showModal, setShowModal] = useState<boolean>(false)
  const {setItemIdForUpdate} = useListView()
  const [taskId, setTaskId] = useState<ID>()
  const [action, setAction] = useState<boolean>(false)

  const [searchParams, setSearchParams] = useSearchParams()
  const [taskByIdURL, setTaskByIdURL] = useState<ID>()

  const processs = (search: SearchComponent) => {
    setTimeout(function () {
      const number = Math.floor(Math.random() * 6) + 1

      if (number === 2) {
        resultsElement.current!.classList.add('d-none')
        emptyElement.current!.classList.remove('d-none')
      } else {
        resultsElement.current!.classList.remove('d-none')
        emptyElement.current!.classList.add('d-none')
      }
      search.complete()
    }, 1500)
  }

  const clear = (search: SearchComponent) => {
    resultsElement.current!.classList.add('d-none')
    emptyElement.current!.classList.add('d-none')
  }

  useEffect(() => {
    if (value.length >= 2) {
      searchByValue.mutateAsync(value)
    }
  }, [value])

  useEffect(() => {
    const searchObject = SearchComponent.createInsance('#kt_header_search')
    searchObject!.on('kt.search.process', processs)
    searchObject!.on('kt.search.clear', clear)
  }, [])

  const clearValue = () => {
    setValue('')
  }

  const searchByValue = useMutation((value: string) => filterByValue(value.toString()), {
    onSuccess: (response) => {
      setProjects(response?.data[0]!.projects!)
      setTasks(response?.data[0]!.tasks!)
      setContracts(response?.data[0]!.contracts!)
      setWikis(response?.data[0]!.wikis!)
      setClients(response?.data[0]!.clients!)
    },
  })

  const openView = (file: string | undefined) => {
    window.open(process.env.REACT_APP_THEME_API_URL + '/storage/' + file, '_blank')
  }

  const openEditModal = (taskIdParam: ID) => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set('taskId', taskIdParam!.toString()!)
    setSearchParams(newParams)
    setItemIdForUpdate(taskIdParam)
    setTaskByIdURL(taskIdParam)
    setShowModal(true)
    setAction(true)
  }

  return (
    <>
      <div
        id='kt_header_search'
        className='d-flex align-items-stretch'
        data-kt-search-keypress='true'
        data-kt-search-layout='menu'
        data-kt-menu-trigger='auto'
        data-kt-menu-overflow='false'
        data-kt-menu-permanent='true'
        data-kt-menu-placement='bottom-end'
        ref={element}
      >
        <div
          className='d-flex align-items-center'
          data-kt-search-element='toggle'
          id='kt_header_search_toggle'
        >
          <div className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'>
            <KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-1' />
          </div>
        </div>

        <div
          data-kt-search-element='content'
          className='menu menu-sub menu-sub-dropdown p-7 w-325px w-md-375px'
        >
          <div
            className={`${menuState === 'main' ? '' : 'd-none'}`}
            ref={wrapperElement}
            data-kt-search-element='wrapper'
          >
            <form
              data-kt-search-element='form'
              className='w-100 position-relative mb-3'
              autoComplete='off'
            >
              <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 translate-middle-y ms-0'
              />

              <input
                type='text'
                className='form-control form-control-flush ps-10'
                name='search'
                value={value}
                onChange={(e: any) => {
                  setValue(e.target.value)
                }}
                onBlur={clearValue}
                placeholder={intl.formatMessage({id: 'FORM.INPUT.SEARCH.NAVBAR'})}
                data-kt-search-element='input'
              />

              <span
                className='position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-1'
                data-kt-search-element='spinner'
              >
                <span className='spinner-border h-15px w-15px align-middle text-gray-400' />
              </span>

              <span
                className='btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 d-none'
                data-kt-search-element='clear'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon-2 svg-icon-lg-1 me-0'
                />
              </span>

              <div
                className='position-absolute top-50 end-0 translate-middle-y'
                data-kt-search-element='toolbar'
              >
                <div
                  data-kt-search-element='preferences-show'
                  className='btn btn-icon w-20px btn-sm btn-active-color-primary me-1'
                  data-bs-toggle='tooltip'
                  onClick={() => {
                    setMenuState('preferences')
                  }}
                  title='Show search preferences'
                >
                  <KTSVG path='/media/icons/duotune/coding/cod001.svg' className='svg-icon-1' />
                </div>
              </div>
            </form>

            <div ref={resultsElement} data-kt-search-element='results' className='d-none'>
              <div className='scroll-y mh-200px mh-lg-350px'>
                {currentUser?.administrator && clients.length > 0 ? (
                  <>
                    <h3
                      className='fs-5 text-muted m-0 pt-5 pb-5'
                      data-kt-search-element='category-title'
                    >
                      {intl.formatMessage({id: 'MENU.CLIENTS'})}
                    </h3>
                  </>
                ) : null}

                {clients?.map((client_row, index) => {
                  return (
                    <>
                      {currentUser?.administrator ? (
                        <a
                          href={`/clients/${client_row.client_id}`}
                          className='d-flex text-dark text-hover-primary align-items-center mb-5'
                        >
                          <div className='d-flex flex-column'>
                            <span className='fs-6 fw-bold'>{client_row.client_name}</span>
                          </div>
                        </a>
                      ) : null}
                    </>
                  )
                })}

                {tasks.length > 0 ? (
                  <>
                    <h3
                      className='fs-5 text-muted m-0 pb-5'
                      data-kt-search-element='category-title'
                    >
                      {intl.formatMessage({id: 'MENU.TASKS'})}
                    </h3>
                  </>
                ) : null}

                {tasks?.map((task_row, index) => {
                  return (
                    <>
                      <a
                        onClick={(e) => {
                          e.preventDefault()
                          openEditModal(task_row.task_id)
                        }}
                        href='/#'
                        className='d-flex text-dark text-hover-primary align-items-center mb-5'
                      >
                        <div className='d-flex flex-column justify-content-start fw-bold'>
                          <span className='fs-6 fw-bold'>{task_row.task_name}</span>
                          <span className='fs-7 fw-bold text-muted'>
                            #{task_row.task_id} - {task_row.client_name}
                          </span>
                        </div>
                      </a>
                    </>
                  )
                })}

                {projects.length > 0 ? (
                  <>
                    <h3
                      className='fs-5 text-muted m-0 pb-5'
                      data-kt-search-element='category-title'
                    >
                      {intl.formatMessage({id: 'MENU.PROJECTS'})}
                    </h3>
                  </>
                ) : null}

                {projects?.map((project_row, index) => {
                  return (
                    <>
                      <a
                        href={`/projects/${project_row.project_id}`}
                        className='d-flex text-dark text-hover-primary align-items-center mb-5'
                      >
                        <div className='d-flex flex-column justify-content-start fw-bold'>
                          <span className='fs-6 fw-bold'>{project_row.project_name}</span>
                          <span className='fs-7 fw-bold text-muted'>
                            #{project_row.project_id} - {project_row.client_name}
                          </span>
                        </div>
                      </a>
                    </>
                  )
                })}

                {wikis.length > 0 ? (
                  <>
                    <h3
                      className='fs-5 text-muted m-0 pb-5'
                      data-kt-search-element='category-title'
                    >
                      WIKI
                    </h3>
                  </>
                ) : null}

                {wikis?.map((wiki_row, index) => {
                  return (
                    <>
                      <a
                        href={`/wiki/overview/${wiki_row.wiki_id}`}
                        className='d-flex text-dark text-hover-primary align-items-center mb-5'
                      >
                        <div className='d-flex flex-column'>
                          <span className='fs-6 fw-bold'>{wiki_row.wiki_title}</span>
                          <span className='fs-7 fw-bold text-muted'>{wiki_row.wiki_category}</span>
                        </div>
                      </a>
                    </>
                  )
                })}

                {currentUser?.administrator && contracts.length > 0 ? (
                  <>
                    <h3
                      className='fs-5 text-muted m-0 pt-5 pb-5'
                      data-kt-search-element='category-title'
                    >
                      {intl.formatMessage({id: 'MENU.CONTRACTS'})}
                    </h3>
                  </>
                ) : null}

                {contracts?.map((contract_row, index) => {
                  return (
                    <>
                      {currentUser?.administrator ? (
                        <a
                          onClick={() => openView(contract_row.contract_file)}
                          href='/#'
                          className='d-flex text-dark text-hover-primary align-items-center mb-5'
                        >
                          <div className='d-flex flex-column'>
                            <span className='fs-6 fw-bold'>{contract_row.contract_name}</span>
                            <span className='fs-7 fw-bold text-muted'>
                              {contract_row.project_name} - {contract_row.client_name}
                            </span>
                          </div>
                        </a>
                      ) : null}
                    </>
                  )
                })}
              </div>
            </div>

            <div ref={emptyElement} data-kt-search-element='empty' className='text-center d-none'>
              <div className='pt-10 pb-10'>
                <KTSVG
                  path='/media/icons/duotune/files/fil024.svg'
                  className='svg-icon-4x opacity-50'
                />
              </div>

              <div className='pb-15 fw-bold'>
                <h3 className='text-gray-600 fs-5 mb-2'>
                  {intl.formatMessage({id: 'FORM.INPUT.SEARCH.NAVBAR.NOT.FOUND'})}
                </h3>
                <div className='text-muted fs-7'>
                  {intl.formatMessage({id: 'FORM.INPUT.SEARCH.NAVBAR.NOT.FOUND.TEXT'})}
                </div>
              </div>
            </div>
          </div>

          <form className={`pt-1 ${menuState === 'preferences' ? '' : 'd-none'}`}>
            <h3 className='fw-bold text-dark mb-7'>
              {intl.formatMessage({id: 'FORM.INPUT.SEARCH.CATEGORIES'})}
            </h3>

            <div className='pb-4 border-bottom'>
              <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>
                  {intl.formatMessage({id: 'FORM.INPUT.SEARCH.CATEGORIES.TASKS'})}
                </span>

                <input
                  className='form-check-input'
                  type='checkbox'
                  value='1'
                  name='search_categories_tasks'
                  defaultChecked
                />
              </label>
            </div>

            <div className='py-4 border-bottom'>
              <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>
                  {intl.formatMessage({id: 'FORM.INPUT.SEARCH.CATEGORIES.PROJECTS'})}
                </span>
                <input
                  className='form-check-input'
                  type='checkbox'
                  value='1'
                  name='search_categories_projects'
                  defaultChecked
                />
              </label>
            </div>

            <div className='py-4 border-bottom'>
              <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>
                  {intl.formatMessage({id: 'FORM.INPUT.SEARCH.CATEGORIES.CLIENTS'})}
                </span>
                <input
                  className='form-check-input'
                  type='checkbox'
                  value='1'
                  name='search_categories_clients'
                  defaultChecked
                />
              </label>
            </div>

            <div className='py-4 border-bottom'>
              <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>
                  {intl.formatMessage({id: 'FORM.INPUT.SEARCH.CATEGORIES.WIKI'})}
                </span>
                <input
                  className='form-check-input'
                  type='checkbox'
                  name='search_categories_wiki'
                  value='1'
                  defaultChecked
                />
              </label>
            </div>

            <div className='py-4 border-bottom'>
              <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>
                  {intl.formatMessage({id: 'FORM.INPUT.SEARCH.CATEGORIES.CONTRACTS'})}
                </span>
                <input
                  className='form-check-input'
                  type='checkbox'
                  value='1'
                  name='search_categories_contracts'
                  defaultChecked
                />
              </label>
            </div>

            <div className='d-flex justify-content-end pt-7'>
              <button
                onClick={(e) => {
                  e.preventDefault()
                  setMenuState('main')
                }}
                className='btn btn-sm btn-light fw-bolder btn-active-light-primary me-2'
              >
                {intl.formatMessage({id: 'FORM.INPUT.SEARCH.CATEGORIES.BUTTON.CANCEL'})}
              </button>
              <button className='btn btn-sm fw-bolder btn-primary'>
                {intl.formatMessage({id: 'FORM.INPUT.SEARCH.CATEGORIES.BUTTON.SAVE'})}
              </button>
            </div>
          </form>
        </div>
      </div>

      <TaskEditModal />
    </>
  )
}

export {Search}
