import {ID, Response} from '../../../../../_metronic/helpers'
import { Project } from '../../../project/components/core/_models'
export type Client = {
  id?: string
  avatar?: string
  name?: string
  responsible?: string
  email?: string
  phone?: string
  observation?: string
  active?: boolean
  total_projects?: number
  total_clients?: number
  total_time?: number
  plan_cost?: number
  categories?: Array<string>
  instance_id?: number
  profit_margin?: number
  projects?: Project[]
}

export type ResponseClient = {
  client: Client
  message: string
  errors: boolean
}

export type ClientsQueryResponse = Response<Array<Client>>

export const initialClient: Client = {
  id: '',
  name: "",
  responsible: "",
  avatar: "",
  email: "",
  active: true,
  phone: "",
  total_projects: 0,
  total_time:  0,
  plan_cost: 0
}
