import {Modal} from 'react-bootstrap'
import {createPortal} from 'react-dom'
import {useIntl} from 'react-intl'
import {ID, isNotEmpty, KTSVG, QUERIES} from '../../../../_metronic/helpers'
import {AdjustTimeUserModalForm} from './AdjustTimeUserModalForm'
import {useQuery} from "react-query";
import {getTaskById} from "../../../../app/modules/project/components/tasks-list/core/_requests";
import {useListView} from "../../../../app/modules/project/components/tasks-list/core/ListViewProvider";

type Props = {
    show: boolean
    handleClose: () => void
    taskId: ID
}
  
const modalsRoot = document.getElementById('root-modals') || document.body

const AdjustTimeUserModal = ({show, handleClose, taskId}: Props) => {
    const intl = useIntl()

    return createPortal(
        //@ts-ignore
        <Modal
          id='kt_modal_edit_project'
          tabIndex={-1}
          aria-hidden='true'
          dialogClassName='modal-dialog modal-dialog-centered mw-700px'
          show={show}
          onHide={handleClose}
          data-backdrop='static'
          className="bg-glass"
        >
            <div className='modal-header'>
                <h2>{intl.formatMessage({id: 'TIMETRACKING.LABEL'})}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>
            <div className='modal-body px-lg-10'>
                <AdjustTimeUserModalForm handleClose={handleClose} taskId={taskId}/>
            </div>
        </Modal>,
        modalsRoot
    )
}

export {AdjustTimeUserModal}