import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardPageWrapper} from '../pages/dashboard/DashboardPageWrapper'
import {OnboardingPage} from '../pages/onboarding/OnboardingPage'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {useAuth} from '../modules/auth'
import TasksByUserPage from '../modules/tasks-by-user/TasksByUserPage'

const PrivateRoutes = () => {
  const TeamsPage = lazy(() => import('../modules/team/TeamsPage'))
  const UsersPage = lazy(() => import('../modules/user/UsersPage'))
  const ProjectsPage = lazy(() => import('../modules/project/ProjectsPage'))
  const TimeTrackingPage = lazy(() => import('../modules/time-tracking/TimeTrackingPage'))
  const TimeTrackingUserPage = lazy(
    () => import('../modules/time-tracking-user/TimeTrackingUserPage')
  )
  const ClientsPage = lazy(() => import('../modules/clients/ClientsPage'))
  const SprintsPage = lazy(() => import('../modules/sprints/SprintsPage'))
  const ReportsPage = lazy(() => import('../modules/reports/ReportsPage'))
  const ContractsPage = lazy(() => import('../modules/contracts/ContractsPage'))
  const WikiPage = lazy(() => import('../modules/wiki/WikiPage'))
  const CalendarPage = lazy(() => import('../modules/calendar/CalendarPage'))
  const TicketsPage = lazy(() => import('../modules/tickets/TicketsPage'))
  const ConfigsPage = lazy(() => import('../modules/config/ConfigsPage'))
  const SchedulePage = lazy(() => import('../modules/schedule/SchedulePage'))
  const SquadTasksPage = lazy(() => import('../modules/squad/SquadTasksPage'))
  const ForecastPage = lazy(() => import('../modules/project-forecast/ForecastPage'))
  const CategoryPage = lazy(() => import('../modules/category/CategoryPage'))
  const TagPage = lazy(() => import('../modules/tags/TagPage'))
  const MyTasksPage = lazy(() => import('../modules/mytasks/MyTasksPage'))
  const StagePage = lazy(() => import('../modules/stages/StagePage'))
  const HolidaysPage = lazy(() => import('../modules/holidays/HolidaysPage'))
  const BillingsPage = lazy(() => import('../modules/billings/BillingsPage'))
  const {currentUser} = useAuth()

  return (
    <Routes>
      <Route path='onboarding/*' element={<OnboardingPage />} />
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard or Onboarding after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardPageWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}

        {currentUser?.administrator ? (
          <>
            <Route
              path='teams/*'
              element={
                <SuspensedView>
                  <TeamsPage />
                </SuspensedView>
              }
            />

            <Route
              path='users/*'
              element={
                <SuspensedView>
                  <UsersPage />
                </SuspensedView>
              }
            />

            <Route
              path='time-tracking/*'
              element={
                <SuspensedView>
                  <TimeTrackingPage />
                </SuspensedView>
              }
            />

            <Route
              path='clients/*'
              element={
                <SuspensedView>
                  <ClientsPage />
                </SuspensedView>
              }
            />

            <Route
              path='squad-tasks/*'
              element={
                <SuspensedView>
                  <SquadTasksPage />
                </SuspensedView>
              }
            />

            <Route
              path='contracts/*'
              element={
                <SuspensedView>
                  <ContractsPage />
                </SuspensedView>
              }
            />

            <Route
              path='stages/'
              element={
                <SuspensedView>
                  <StagePage />
                </SuspensedView>
              }
            />

            <Route
              path='tasksbyuser/*'
              element={
                <SuspensedView>
                  <TasksByUserPage />
                </SuspensedView>
              }
            />

            <Route
              path='holidays/*'
              element={
                <SuspensedView>
                  <HolidaysPage />
                </SuspensedView>
              }
            />

            <Route
              path='billings/*'
              element={
                <SuspensedView>
                  <BillingsPage />
                </SuspensedView>
              }
            />
          </>
        ) : (
          <>
            <Route path='teams/*' element={<DashboardPageWrapper />} />
            <Route path='users/*' element={<DashboardPageWrapper />} />
            <Route
              path='time-tracking-user/'
              element={
                <SuspensedView>
                  <TimeTrackingUserPage />
                </SuspensedView>
              }
            />
            <Route path='clients/*' element={<DashboardPageWrapper />} />
            <Route path='squad-tasks/*' element={<DashboardPageWrapper />} />
            <Route path='contracts/*' element={<DashboardPageWrapper />} />
            <Route path='stages/*' element={<DashboardPageWrapper />} />
          </>
        )}

        <Route
          path='category/*'
          element={
            <SuspensedView>
              <CategoryPage />
            </SuspensedView>
          }
        />
        <Route
          path='tags/*'
          element={
            <SuspensedView>
              <TagPage />
            </SuspensedView>
          }
        />
        <Route
          path='projects/*'
          element={
            <SuspensedView>
              <ProjectsPage />
            </SuspensedView>
          }
        />

        <Route
          path='sprints/*'
          element={
            <SuspensedView>
              <SprintsPage />
            </SuspensedView>
          }
        />
        <Route
          path='reports/*'
          element={
            <SuspensedView>
              <ReportsPage />
            </SuspensedView>
          }
        />
        <Route
          path='wiki/*'
          element={
            <SuspensedView>
              <WikiPage />
            </SuspensedView>
          }
        />
        <Route
          path='calendar/*'
          element={
            <SuspensedView>
              <CalendarPage />
            </SuspensedView>
          }
        />
        <Route
          path='tickets/*'
          element={
            <SuspensedView>
              <TicketsPage />
            </SuspensedView>
          }
        />
        <Route
          path='configs/*'
          element={
            <SuspensedView>
              <ConfigsPage />
            </SuspensedView>
          }
        />
        <Route
          path='schedules/*'
          element={
            <SuspensedView>
              <SchedulePage />
            </SuspensedView>
          }
        />

        <Route
          path='project-forecast/*'
          element={
            <SuspensedView>
              <ForecastPage />
            </SuspensedView>
          }
        />

        <Route
          path='mytasks/*'
          element={
            <SuspensedView>
              <MyTasksPage />
            </SuspensedView>
          }
        />

        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
