/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useCallback, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useIntl} from 'react-intl'
import ptbrLocale from '@fullcalendar/core/locales/pt-br'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import {DateSelectArg} from '@fullcalendar/core'
import interactionPlugin from '@fullcalendar/interaction' // needed for dayClick
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useQuery} from 'react-query'
import {useAuth} from '../../../modules/auth'
import {ProjectCard} from '../../../../_metronic/partials/content/cards/ProjectCard'
import {TasksListWrapper} from './components/tasks-list/TasksList'
import {
  KTSVG,
  QUERIES,
  REGISTERED_HOURS,
  isNotEmpty,
  toAbsoluteUrl,
} from '../../../../_metronic/helpers'
import {AdjustTimeModal} from '../../../../_metronic/partials/modals/adjust-time/AdjustTimeModal'
import {
  getDashboardInfoUser,
  getProjects,
  getRecurringProjects,
  getOpenedProjects,
  getRecentActivities,
} from '../dashboard-manager/core/_requests'
import {MyTasksListWrapper} from '../../../modules/mytasks/components/MyTasksList'
import {getTimeTrackingByUserId} from '../../../modules/time-tracking/components/time-tracking-list/core/_requests'
import {User} from '../../../modules/time-tracking/components/time-tracking-list/core/_models'
import {DetailsTimeUserModal} from '../../../../_metronic/partials/modals/details-time-user/DetailsTimeUserModal'

const DashboardUserPage: FC = () => {
  const intl = useIntl()
  const [showAdjustTimeModal, setShowAdjustTimeModal] = useState<boolean>(false)
  const {currentUser} = useAuth()
  const today = new Date()
  const TemaAtual = window.localStorage.getItem('kt_theme_mode_value') || ''
  const [windowSize, setWindowSize] = useState<number>(window.innerWidth)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [dateSelected, setDateSelected] = useState('')
  const [dateFormat, setDateFormatSelected] = useState('')

  const {
    isLoading: isDashboardLoading,
    data: dashboardInfo,
    refetch,
    error,
  } = useQuery(
    `${QUERIES.DASHBOARD_INFO_USER}`,
    () => {
      return getDashboardInfoUser()
    },
    {
      cacheTime: 0,
      onError: (err) => {
        console.error(err)
      },
      refetchOnWindowFocus: false,
    }
  )

  const {
    isLoading: isProjectsLoading,
    data: dashboardProjects,
    refetch: projectsRefetch,
    error: errorRefetch,
  } = useQuery(
    `${QUERIES.DASHBOARD_PROJECTS}`,
    () => {
      return getProjects()
    },
    {
      cacheTime: 0,
      onError: (err) => {
        console.error(err)
      },
      refetchOnWindowFocus: false,
    }
  )

  const {
    isLoading: isTimeTrackingLoading,
    data: dashboardTimeTracking,
    refetch: timetrackingRefetch,
    error: errorRefetchTimeTracking,
  } = useQuery(
    `${QUERIES.DASHBOARD_TIMETRACKING}`,
    () => {
      return getTimeTrackingByUserId(currentUser?.id)
    },
    {
      cacheTime: 0,
      onError: (err) => {
        console.error(err)
      },
      refetchOnWindowFocus: false,
    }
  )

  const [data, setData] = useState<User>()
  useEffect(() => {
    dashboardTimeTracking && setData(dashboardTimeTracking)
  }, [isTimeTrackingLoading])

  let perfomanceLevel = ''
  if (
    (data?.performance! >= data?.instanceConfiguration?.min_hour_index! &&
      data?.performance! < data?.instanceConfiguration?.max_hour_index!) ||
    !data?.has_time_tracking
  ) {
    perfomanceLevel = 'text-success fw-bold'
  } else if (data?.performance! > data?.instanceConfiguration?.max_hour_index!) {
    perfomanceLevel = 'text-warning fw-bold'
  } else {
    perfomanceLevel = 'text-danger fw-bold'
  }

  let performace

  if (data?.performance === undefined) {
    performace = 0
  } else {
    performace = data.performance
  }

  const openDetails = (dateSelected: string, dateFormat: string) => {
    //Abre modal para mostrar o detalhamento dos horários para aquele usuário e dia
    setDateSelected(dateSelected)
    setDateFormatSelected(dateFormat)
    setShowModal(true)
  }

  // const toolbar = {
  //   left: 'prevYear,prev,next,nextYear today',
  //   center: 'title',
  //   right: 'dayGridMonth,dayGridWeek,dayGridDay',
  // }
  // let eventGuid = 0
  // const createEventId = () => String(eventGuid++)

  // const handleDateSelect = useCallback((selectInfo: DateSelectArg) => {
  //   let title = prompt('Insira o título do evento')?.trim()
  //   let calendarApi = selectInfo.view.calendar
  //   calendarApi.unselect()
  //   if (title) {
  //     calendarApi.addEvent({
  //       id: createEventId(),
  //       title,
  //       start: selectInfo.startStr,
  //       end: selectInfo.endStr,
  //       allDay: selectInfo.allDay,
  //     })
  //   }
  // }, [])

  return (
    <>
      {/* START HEADER */}
      <div className='container'>
        <div className='row align-items-end justify-content-between bg-body pt-10 pb-15 px-3 px-md-5 mb-6 rounded'>
          <div className='col-md-8'>
            <div className='d-flex align-items-center mb-4'>
              <div className='fw-semi-bold fs-2 me-4'>
                {intl.formatMessage({id: 'DASHBOARD.LABEL.WORKPLACE'})}
              </div>
              <span className='text-gray-600'>
                {intl.formatMessage({id: 'DASHBOARD.LABEL.FEATURED_OF_THE_DAY'})}
              </span>
            </div>
            <div className='d-flex align-items-center'>
              <div className='symbol symbol-circle symbol-60px overflow-hidden me-3'>
                <div className='symbol symbol-60px symbol-md-60px'>
                  {currentUser?.avatar && TemaAtual === 'dark' ? (
                    <img
                      className='object-fit-cover'
                      alt={currentUser.name}
                      src={
                        isNotEmpty(currentUser.avatar) && currentUser.avatar !== 'null'
                          ? process.env.REACT_APP_THEME_API_URL + '/storage/' + currentUser?.avatar
                          : toAbsoluteUrl('/media/svg/files/blank-image-dark.svg')
                      }
                    />
                  ) : (
                    <img
                      className='object-fit-cover'
                      alt={currentUser?.name}
                      src={
                        isNotEmpty(currentUser?.avatar) && currentUser?.avatar !== 'null'
                          ? process.env.REACT_APP_THEME_API_URL + '/storage/' + currentUser?.avatar
                          : toAbsoluteUrl('/media/svg/files/blank-image.svg')
                      }
                    />
                  )}
                </div>
              </div>
              <div className='d-flex flex-column'>
                <p className='text-gray-900 fw-bold fs-4 mb-1'>
                  {today.getHours() < 12
                    ? intl.formatMessage({id: 'GENERAL.GOOD_MORNING'})
                    : today.getHours() < 18
                    ? intl.formatMessage({id: 'GENERAL.GOOD_AFTERNOON'})
                    : intl.formatMessage({id: 'GENERAL.GOOD_EVENING'})}
                  , {currentUser?.name}!
                </p>
                {/* <span className='text-gray-600'>
                  Hoje inicia o ciclo dos clientes ABC e XYZ. Em 07 dias, será a entrega do layout
                  do site ABC.
                </span> */}
              </div>
            </div>
          </div>
          <div className='col-md-4 d-flex align-items-center justify-content-between justify-content-md-end mt-10 mt-md-0'>
            <div className='text-end border-end border-gray-300 pe-5 me-10'>
              <span className='text-gray-600'>
                {intl.formatMessage({id: 'DASHBOARD.LABEL.PROJECTS_I_AM_IN'})}
              </span>
              <div className='fs-2'>{dashboardInfo?.projects_quantity}</div>
            </div>
            <div className='text-end'>
              <span className='text-gray-600'>
                {intl.formatMessage({id: 'DASHBOARD.LABEL.TASKS_FOR_ME'})}
              </span>
              <div className='fs-2'>{dashboardInfo?.user_tasks_quantity}</div>
            </div>
          </div>
        </div>
      </div>
      {/* END HEADER */}
      <div className='container pt-8 pb-15'>
        <div className='row mb-10'>
          <div className='col-12'>
            <div className='fs-2x fw-bold'>
              {intl.formatMessage({id: 'DASHBOARD.LABEL.TASKS_INDICATORS'})}
            </div>
          </div>
        </div>
        <div className='row bg-body pt-10 pb-4 px-3 px-md-5 mb-6 rounded'>
          <div className='col-lg-6 justify-content-center align-items-center ps-lg-10 mt-md-10 mt-lg-0 mb-8 mb-md-0'>
            <div className='row g-3 g-lg-6'>
              <div className='col-4'>
                {/* 
              //@ts-ignore */}
                <OverlayTrigger
                  key={1}
                  placement='bottom'
                  overlay={
                    //@ts-ignore
                    <Tooltip id='tooltip-user-name'>
                      {intl.formatMessage({id: 'DASHBOARD.LABEL.OPEN_TO_ME'})}
                    </Tooltip>
                  }
                >
                  <div className='bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5'>
                    <div className='symbol symbol-30px me-5 mb-8'>
                      <KTSVG
                        path='media/icons/duotune/general/gen013.svg'
                        className='svg-icon svg-icon-1 svg-icon-primary'
                      />
                    </div>
                    <div className='m-0 py-md-3'>
                      <span className='text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1'>
                        {dashboardInfo?.userTasksOpenQuantity}
                      </span>
                      <span className='text-gray-500 fw-semibold fs-6 d-none d-md-block'>
                        {intl.formatMessage({id: 'DASHBOARD.LABEL.OPEN_TO_ME'})}
                      </span>
                    </div>
                  </div>
                </OverlayTrigger>
              </div>
              <div className='col-4'>
                {/* 
              //@ts-ignore */}
                <OverlayTrigger
                  key={2}
                  placement='bottom'
                  overlay={
                    //@ts-ignore
                    <Tooltip id='tooltip-user-name'>
                      {intl.formatMessage({id: 'DASHBOARD.LABEL.FINISHED_ON_TIME'})}
                    </Tooltip>
                  }
                >
                  <div className='bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5'>
                    <div className='symbol symbol-30px me-5 mb-8'>
                      <KTSVG
                        path='media/icons/duotune/general/gen020.svg'
                        className='svg-icon svg-icon-1 svg-icon-primary'
                      />
                    </div>
                    <div className='m-0 py-md-3'>
                      <span className='text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1'>
                        {dashboardInfo?.userTaskDeliveryQuantity}
                      </span>
                      <span className='text-gray-500 fw-semibold fs-6 d-none d-md-block'>
                        {intl.formatMessage({id: 'DASHBOARD.LABEL.FINISHED_ON_TIME'})}
                      </span>
                    </div>
                  </div>
                </OverlayTrigger>
              </div>
              <div className='col-4'>
                {/* 
              //@ts-ignore */}
                <OverlayTrigger
                  key={3}
                  placement='bottom'
                  overlay={
                    //@ts-ignore
                    <Tooltip id='tooltip-user-name'>
                      {intl.formatMessage({id: 'DASHBOARD.LABEL.FINISHED_ON_TIME'})}
                    </Tooltip>
                  }
                >
                  <div className='bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5'>
                    <div className='symbol symbol-30px me-5 mb-8'>
                      <KTSVG
                        path='media/icons/duotune/general/gen040.svg'
                        className='svg-icon svg-icon-1 svg-icon-primary'
                      />
                    </div>
                    <div className='m-0 py-md-3'>
                      <span className='text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1'>
                        {dashboardInfo?.userTaskNotDeliveryQuantity}
                      </span>
                      <span className='text-gray-500 fw-semibold fs-6 d-none d-md-block'>
                        {intl.formatMessage({id: 'DASHBOARD.LABEL.NOT_FINISHED'})}
                      </span>
                    </div>
                  </div>
                </OverlayTrigger>
              </div>
            </div>
          </div>
          <div className='col-lg-6 ps-lg-10 mt-md-10 mt-lg-0 mb-8 mb-md-0'>
            <div className='d-flex justify-content-between align-items-center mb-10'>
              <div className='fs-2 fw-bold'>
                {intl.formatMessage({id: 'DASHBOARD.LABEL.REGISTERED_HOURS_IN_TASKS'})}
              </div>
            </div>

            <div className='d-flex overflow-scroll'>
              {data?.hours_by_day?.length == 0 ? (
                <>
                  <h3 className='fs-2 fw-bold mb-0 text-center'>
                    Nenhum apontamento realizado até o momento...
                  </h3>
                </>
              ) : (
                data?.hours_by_day!.map((row) => {
                  return (
                    <>
                      <div
                        className={`cursor-pointer d-flex flex-column align-items-center mb-15 ${
                          windowSize >= 991 ? 'col-1 mx-7' : 'w-fit-content mx-2'
                        }`}
                        onClick={() => openDetails(row.date, row.date_format)}
                      >
                        <span className='fs-7 white-space-nowrap'>
                          {row.day} - {row.day_name}
                          <span className='fw-bold'>
                            {row.is_holiday ? '(' + row.holiday + ')' : null}
                            {row.is_vacation_day ? '(Férias)' : null}
                          </span>
                          {row.has_change ? (
                            <i
                              className='fas fa-exclamation-circle fs-7 ps-1'
                              data-bs-toggle='tooltip'
                              title='Alteração de jornada de trabalho.'
                            ></i>
                          ) : null}
                        </span>
                        <div
                          className='w-55px h-75px bg-gray-300 position-relative d-flex align-items-end rounded overflow-hidden'
                          // className='w-55px h-75px bg-gray-300 position-relative d-flex align-items-end rounded'
                          data-bs-toggle='tooltip'
                          title={row.tooltip}
                        >
                          <div
                            className={`
                      ${
                        !row.is_working_day || row.is_holiday || row.is_vacation_day
                          ? 'bg-primary'
                          : row.total_time_porcent >=
                              data?.instanceConfiguration?.min_hour_index! &&
                            row.total_time_porcent <= data?.instanceConfiguration?.max_hour_index!
                          ? 'bg-success'
                          : row.total_time_porcent < data?.instanceConfiguration?.min_hour_index!
                          ? 'bg-danger'
                          : 'bg-warning'
                      }
                        rounded w-80px
                        `}
                            role='progressbar'
                            style={{
                              height:
                                row.is_holiday || row.is_vacation_day
                                  ? '100%'
                                  : `${row.total_time_porcent}%`,
                            }}
                          ></div>
                          <div className='text-center text-white position-absolute end-0 bottom-0px start-0 pb-1'>
                            {row.total_time}h
                          </div>
                        </div>
                      </div>
                      <DetailsTimeUserModal
                        show={showModal}
                        dateSelected={dateSelected}
                        dateFormatSelected={dateFormat}
                        userId={currentUser?.id.toString()!}
                        handleClose={() => setShowModal(false)}
                      />
                    </>
                  )
                })
              )}
            </div>
            <div className='d-flex justify-content-between align-items-center'>
              <div className='d-flex align-items-center'>
                <div className='text-gray-600 fs-4 fw-bold me-5'>
                  <span className={perfomanceLevel}>{data?.hours_tracked}h</span> de{' '}
                  {data?.hours_total}
                </div>
                <div className={perfomanceLevel}>{performace}%</div>
              </div>
              <Link
                to={'../time-tracking-user'}
                className='btn btn-outline border-primary text-primary btn-sm w-fit-content py-2 px-3'
              >
                {intl.formatMessage({id: 'DASHBOARD.LABEL.SEE_MY_HISTORIC'})}
              </Link>
            </div>
          </div>
        </div>

        {/* <div className='row bg-body pt-10 pb-4 px-3 px-md-5 mb-6 rounded'>
          <div className='fs-2 fw-bold mb-8'>
            {intl.formatMessage({id: 'DASHBOARD.LABEL.MYSCHEDULE'})}
          </div>
          <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            initialView='dayGridWeek'
            locale={ptbrLocale}
            selectable={true}
            select={handleDateSelect}
            editable={true}
            dayMaxEvents={true}
            headerToolbar={toolbar}
            height='auto'
            themeSystem='bootstrap5'
            events={[
              {
                title: 'The Title',
                start: '2023-01-13',
              },
              {
                title: 'Other Title',
                start: '2023-01-10',
              },
            ]}
          />
        </div> */}
        <div className='row bg-body pt-10 pb-4 px-3 px-md-5 mb-6 rounded'>
          <MyTasksListWrapper />
        </div>
        <div className='row g-xl-9 pt-8 mb-15'>
          <div className='d-flex justify-content-between align-items-center mb-10'>
            {dashboardProjects?.projects.length != 0 ? (
              <div className='fs-2 fw-bold'>
                {intl.formatMessage({id: 'DASHBOARD.LABEL.PROJECTS_I_LEAD'})}
              </div>
            ) : null}
            {/* <div className='d-flex align-items-center position-relative me-5'>
              <span className='svg-icon svg-icon-1 position-absolute ms-3'>
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  className='mh-50px'
                >
                  <rect
                    opacity='0.5'
                    x='17.0365'
                    y='15.1223'
                    width='8.15546'
                    height='2'
                    rx='1'
                    transform='rotate(45 17.0365 15.1223)'
                    fill='currentColor'
                  ></rect>
                  <path
                    d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                    fill='currentColor'
                  ></path>
                </svg>
              </span>
              <input
                type='text'
                className='form-control form-control-sm form-control-solid w-md-250px ps-11 bg-transparent'
                placeholder={intl.formatMessage({id: 'TOOLBAR.SEARCH_PROJECT'})}
                // value=''
              />
            </div> */}
          </div>

          {dashboardProjects?.projects.map((project, i) => (
            <div key={i} className='col-md-6 col-lg-4'>
              <ProjectCard
                id={parseInt(project.id!)}
                name={project.name}
                client={project.clientName}
                budgetedHours={project.budgeted_hours}
                users={project.users!}
                begin_date={project.begin_date}
                end_date={project.end_date}
                closed_at={project.closed_at}
                totalTime={project.totalTime}
                profit_margin={project.profit_margin}
              />
            </div>
          ))}
        </div>
      </div>
      <AdjustTimeModal
        show={showAdjustTimeModal}
        handleClose={() => setShowAdjustTimeModal(false)}
      />
    </>
  )
}

export {DashboardUserPage}
