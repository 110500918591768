import {Task} from '../../../core/_models'

export const getTasksByStage = (tasks: Task[], status: string) => {
  return tasks.filter((task) => {
    if (task.status?.toLowerCase() == 'fazendo' && status == 'em andamento') return task

    return task.status?.toLowerCase() == status
  })
}

export const getTaskById = (tasks: Task[], id: string) => {
  return tasks.find((task) => task?.id?.toString() === id)
}
