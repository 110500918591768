import {useIntl} from 'react-intl'
import {ID, KTCardBody} from '../../../../../../../_metronic/helpers'
import {useState} from "react";
import {Contracts} from "../../core/_models";
import {ContractsModal} from "../../../../../../../_metronic/partials/modals/contracts/ContractsModal";

const ContractsPlaceholder = () => {
  const intl = useIntl()
  const [showContractsModal, setShowContractsModal] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [contractId, setContractId] = useState<ID>(undefined)

  const openEditModal = (rs: Contracts) => {
    setContractId(rs.id)
    setShowModal(true)
  }

  return (
    <div id='kt_projects_cards_pane' className='tab-pane fade show active'>
      <KTCardBody className='px-0'>
        <div className='card-px text-center pt-15 pb-15'>
          {/*begin::Title*/}
          <h2 className='fs-2x fw-bold mb-0'>{intl.formatMessage({id: 'PROJECT.CREATE_NOT_RECORDS_CONTRACT'})}</h2>

        </div>
        <div className='text-center pb-15 px-5'>
          <img
            src='/media/illustrations/sketchy-1/2.png'
            alt=''
            className='mw-100 h-200px h-sm-325px'
          />
        </div>

        <ContractsModal
            show={showModal}
            handleClose={() => setShowModal(false)}
            projectId={undefined}
            clientId={undefined}
            contractId={contractId}
        />
      </KTCardBody>
    </div>
  )
}

export {ContractsPlaceholder}
