/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Contracts} from '../../../../../modules/contracts/components/contracts-list/core/_models'

type Props = {
  contract: Contracts
}

const ContractsLastModCell: FC<Props> = ({contract}) => {
  return (
    <div className='d-flex align-items-center'>
      vence em {contract.expire && contract.expire > '0' ? contract.expire : ''} dias
    </div>
  )
}

export {ContractsLastModCell}
