import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../../_metronic/helpers'
import {Project} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const PROJECT_URL = `${API_URL}/api/projetos`

const closeProjectById = (project: Project): Promise<Project | undefined> => {
  return axios
    .post(`${PROJECT_URL}/fechar`, project)
    .then((response: AxiosResponse<Response<Project>>) => response.data)
    .then((response: Response<Project>) => response.data)
}

const countTasksByProjectId = (projectId: number, date: string): Promise<number> => {
  return axios
    .get(`${PROJECT_URL}/${projectId}/count-tasks?date=${date}`)
    .then((response) => response.data.data)
}

export {closeProjectById, countTasksByProjectId}
