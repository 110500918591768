import {FC, useState, createContext, useContext, useMemo, Dispatch, SetStateAction} from 'react'
import {
  ID,
  calculatedGroupingIsDisabled,
  calculateIsAllDataSelected,
  groupingOnSelect,
  initialListView,
  ListViewContextProps,
  groupingOnSelectAll,
  WithChildren,
} from '../../../../../../_metronic/helpers'
import {useQueryResponse, useQueryResponseData} from './QueryResponseProvider'

type ExtraListViewContextProps = {
  sprintId: ID
  setSprintId: Dispatch<SetStateAction<ID>>
  activeSprint: ID 
  setActiveSprint: Dispatch<SetStateAction<ID>>
  sprintName: String 
  setSprintName: Dispatch<SetStateAction<String>>
  sprintDate: String 
  setSprintDate: Dispatch<SetStateAction<String>>
}

type CustomListViewContextProps = ListViewContextProps & ExtraListViewContextProps

const extraInitialListView: ExtraListViewContextProps = {
  sprintId: null,
  setSprintId: () => {},
  activeSprint: null,
  setActiveSprint: () => {},
  sprintName: '',
  setSprintName: () => {},
  sprintDate: '',
  setSprintDate: () => {},
}

const ListViewContext = createContext<CustomListViewContextProps>(Object.assign(initialListView, extraInitialListView))

const ListViewProvider: FC<WithChildren> = ({children}) => {
  const [selected, setSelected] = useState<Array<ID>>(initialListView.selected)
  const [itemIdForUpdate, setItemIdForUpdate] = useState<ID>(initialListView.itemIdForUpdate)
  const [taskIdForUpdate, setTaskIdForUpdate] = useState<ID>(initialListView.taskIdForUpdate)
  const [holidayIdForUpdate, setHolidayIdForUpdate] = useState<ID>(initialListView.holidayIdForUpdate)
  const {isLoading} = useQueryResponse()
  const data = useQueryResponseData()
  const disabled = useMemo(() => calculatedGroupingIsDisabled(isLoading, data), [isLoading, data])
  const isAllSelected = useMemo(() => calculateIsAllDataSelected(data, selected), [data, selected])
  const [sprintId, setSprintId] = useState<ID>(null)
  const [activeSprint, setActiveSprint] = useState<ID>(null)
  const [sprintName, setSprintName] = useState<String>('')
  const [sprintDate, setSprintDate] = useState<String>('')

  

  return (
    <ListViewContext.Provider
      value={{
        selected,
        itemIdForUpdate,
        setItemIdForUpdate,
        taskIdForUpdate, 
        setTaskIdForUpdate,
        holidayIdForUpdate,
        setHolidayIdForUpdate,
        disabled,
        isAllSelected,
        onSelect: (id: ID) => {
          groupingOnSelect(id, selected, setSelected)
        },
        onSelectAll: () => {
          groupingOnSelectAll(isAllSelected, setSelected)
        },
        clearSelected: () => {
          setSelected([])
        },
        sprintId,
        setSprintId,
        activeSprint,
        setActiveSprint,
        sprintName,
        setSprintName,
        sprintDate,
        setSprintDate
      }}
    >
      {children}
    </ListViewContext.Provider>
  )
}

const useListView = () => useContext(ListViewContext)

export {ListViewProvider, useListView}
