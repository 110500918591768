/* eslint-disable jsx-a11y/anchor-is-valid */
import {useIntl} from 'react-intl'
import {StepProps} from '../../../../../app/modules/project/components/core/_models'
import {InputCurrency} from '../../../../helpers'

const Step3 = ({data, updateData, hasError}: StepProps) => {
  const intl = useIntl()

  return (
    <div className='pb-5' data-kt-stepper-element='content'>
      <div className='w-100'>
        <div className='row g-6 mb-10'>
          {/*begin::Form Group */}
          <div className='col'>
            <label htmlFor='begin_date' className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              {intl.formatMessage({id: 'FORM.INPUT.NAME.BEGIN_DATE'})}
            </label>
            <input
              type='date'
              className='form-control form-control-lg form-control-solid'
              name='begin_date'
              id='begin_date'
              onChange={(e) =>
                updateData({
                  begin_date: e.target.value,
                })
              }
            />

            {!data.begin_date && hasError && (
              <div className='fv-plugins-message-container'>
                <div data-field='begin_date' data-validator='notEmpty' className='fv-help-block'>
                  {intl.formatMessage({id: 'FORM.INPUT.VALIDATION.REQUIRED'})}
                </div>
              </div>
            )}
          </div>
          {/*end::Form Group */}

          {/*begin::Form Group */}
          <div className='col'>
            <label htmlFor='end_date' className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              {intl.formatMessage({id: 'FORM.INPUT.NAME.END_DATE'})}
            </label>
            <input
              type='date'
              className='form-control form-control-lg form-control-solid'
              name='end_date'
              id='end_date'
              onChange={(e) => {
                updateData({
                  end_date: e.target.value,
                })
              }}
            />
          </div>
          {/*end::Form Group */}
        </div>

        <div className='row g-6'>
          {/*begin::Form Group */}
          <div className='col-md-6'>
            <label
              htmlFor='budgeted_hours'
              className='d-flex align-items-center fs-5 fw-semibold mb-2'
            >
              <span>
                {data.type === 1
                  ? intl.formatMessage({id: 'FORM.INPUT.NAME.BUDGETED_HOURS'})
                  : intl.formatMessage({id: 'PROJECT.LABEL.CYCLE_TIME'})}
              </span>
            </label>
            <input
              value={data.budgeted_hours}
              onChange={(e) => {
                const regex = /\D/g
                if (regex.test(e.target.value)) {
                  e.preventDefault()
                  return
                }
                if (isNaN(parseInt(e.target.value))) {
                  updateData({
                    budgeted_hours: 0,
                  })
                  return
                }
                updateData({
                  budgeted_hours: parseInt(e.target.value),
                })
              }}
              className='form-control form-control-lg form-control-solid'
              name='budgeted_hours'
              id='budgeted_hours'
            />
          </div>
          {/*end::Form Group */}
          {/*begin::Form Group */}
          <div className='col-md-6'>
            <label htmlFor='budget' className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              {data.type === 1 ? (
                <>
                  <span>{intl.formatMessage({id: 'FORM.INPUT.NAME.CLOSED_BUDGET'})}</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title={intl.formatMessage({id: 'FORM.INPUT.TOOLTIP.CLOSED_BUDGET'})}
                  ></i>
                </>
              ) : (
                <>
                  <span>{intl.formatMessage({id: 'FORM.INPUT.NAME.MONTHLY_BUDGET'})}</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title={intl.formatMessage({id: 'FORM.INPUT.TOOLTIP.MONTHLY_BUDGET'})}
                  ></i>
                </>
              )}
            </label>
            <InputCurrency
              className='form-control form-control-lg form-control-solid'
              value={0}
              onChange={(e) => {
                updateData({
                  budget: parseInt(
                    e.target.value.replace('.', '').replace('.', ''.replace(',', '.'))
                  ),
                })
              }}
            />
          </div>
          {/*end::Form Group */}
        </div>
      </div>
    </div>
  )
}

export {Step3}
