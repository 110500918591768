import {ID, Response} from '../../../../../_metronic/helpers'

export type TimeTracking = {
  id?: ID
  begin_date?: string
  end_time?: string
  total_time?: ID
  user_id?: ID
  task_id?: ID
  finished?: boolean
  play?: boolean
  type?: string
  date?: string
  message?: string
  code?: number
}

export type ResponseTimeTracking = {
  timetracking: TimeTracking
  message: string
  errors: boolean
}


export type TimeTrackingQueryResponse = Response<Array<TimeTracking>>

export const initialTimeTracking: TimeTracking = {
  begin_date: '',
  end_time: '',
  finished: false,
  play: false,
  type: ''
}

export type StepProps = {
  data: TimeTracking,
  updateData: (fieldsToUpdate: Partial<TimeTracking>) => void,
  hasError: boolean
}