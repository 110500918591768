import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../../_metronic/helpers'
import {ResponseTask, ResponseTasks, Task, TasksQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const TASK_URL = `${API_URL}/api/tarefas`

const getTasks = (userId: string, date: string): Promise<ResponseTasks> => {
  return axios
  .get(`${TASK_URL}/${userId}/${date}/get-tasks-by-userid-and-date`)
  .then((response: Response<ResponseTasks>) => response.data)
}


const getTasksAll = (userId: string, date: string): Promise<TasksQueryResponse> => {
  return axios
  .get(`${TASK_URL}/${userId}/${date}/get-tasks-by-userid-and-date-all`)
  .then((d: AxiosResponse<TasksQueryResponse>) => d.data)
}

export {getTasks, getTasksAll}
