import axios, { AxiosResponse } from 'axios'
import { ID, QueryState, Response } from '../../../../../_metronic/helpers'
import { Options } from './_models'
import { ResponseProject } from '../../../../../app/modules/project/components/core/_models'

//Type
export type SelectEndPoint = {
  url: string
}

const API_URL = process.env.REACT_APP_THEME_API_URL


const getData = (endPoint: string, search : string = ''): Promise<Options[]> => {
  return axios
    .get(`${API_URL}/api/${endPoint}/select`,{params: {"search" : search}})
    .then((d: AxiosResponse<Options[]>) => d.data
    )
}



export { getData }
