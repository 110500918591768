/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {ChangeEvent, FC, useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {useListView} from '../../../core/ListViewProvider'
import {Autocomplete, Chip} from '@mui/material'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {getTaskById, updateStatusTask} from '../../../core/_requests'
import {ID, QUERIES} from '../../../../../../_metronic/helpers'
import {useParams} from 'react-router-dom'
import {getStagesByInstanceId} from '../../../../sprints/components/kanban/core/_requests'
import {Stage} from '../../../../stages/components/core/_models'
import {UserModel, useAuth} from '../../../../auth'

type Props = {
  status2: StatusProps
  status: string
  color: string
  rowIndex: number
  columnId: number
  task_id: ID
  updateCellData: (rowIndex: number, columnId: number, value: string) => void
}

type StatusProps = {
  id?: ID
  name?: string
  color?: string
}

const TaskByUserStatusCell: FC<Props> = ({status2, rowIndex, columnId, status, task_id, color}) => {
  const {setCurrentUser} = useAuth()
  const [value, setValue] = useState<StatusProps | null>(status2)
  const intl = useIntl()
  const ref = useRef<HTMLInputElement>(null)
  var statusColor = ''
  const queryClient = useQueryClient()

  useEffect(() => {
    if (ref.current && status === '') {
      ref.current.focus()
    }
  }, [ref.current])

  useEffect(() => {
    setValue(status2)
  }, [status])

  const [stages, setStages] = useState<Array<Stage>>([])

  const {isLoading, data: tasks_data} = useQuery(
    `${QUERIES.STAGES_LIST}`,
    () => {
      return getStagesByInstanceId()
    },
    {
      cacheTime: 0,
      onError: (err) => {
        console.error(err)
      },
      onSuccess(data) {
        setStages(data)
      },
      refetchOnWindowFocus: false,
    }
  )

  const updateStatusTaskItem = useMutation(
    (value: string) => updateStatusTask(task_id!, value.toString()),
    {
      onSuccess: () => {
        setCurrentUser((user) => {
          return {...user, has_active_time_tracking: false} as UserModel
        })
        cancel(true)
      },
    }
  )

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      queryClient.invalidateQueries()
    }
  }

  const {
    refetch,
    data: tasks,
    error,
  } = useQuery(
    `${QUERIES.MYTASKS_LIST}`,
    () => {
      return getTaskById(task_id!)
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  )

  const onChange = (e: any, newValue: StatusProps | null) => {
    setValue(newValue)
    updateStatusTaskItem.mutateAsync(newValue?.name!)
  }

  if (value?.color == undefined) {
    return (
      <div className='d-flex flex-column justify-content-center align-items-center'>
        <Autocomplete
          blurOnSelect={true}
          classes={{
            popper: 'w-auto',
            paper: 'bg-gray-100i',
            input: `border border-0 rounded-pill fs-7 text-center text-${color} bg-light-${color} mb-0 px-3 py-2 cursor-pointer`,
          }}
          options={stages}
          value={value}
          onChange={onChange}
          ref={ref}
          getOptionLabel={(options) => options.name! || ''}
          isOptionEqualToValue={(options, value) => options.name! === status}
          renderInput={(params) => (
            <div className='text-center' ref={params.InputProps.ref}>
              {/* 
                            //@ts-ignore */}
              <input type='text' {...params.inputProps} size={value?.name!.length} value={status} />
            </div>
          )}
          renderOption={(props, options) => (
            <li {...props}>
              <Chip
                classes={{label: `text-${options.color}`, filled: `bg-light-${options.color}`}}
                label={options.name!}
                clickable={true}
              />
            </li>
          )}
        />
      </div>
    )
  } else {
    return (
      <div className='d-flex flex-column justify-content-center align-items-center'>
        <Autocomplete
          blurOnSelect={true}
          classes={{
            popper: 'w-auto',
            paper: 'bg-gray-100i',
            input: `border border-0 rounded-pill fs-7 text-center text-${value?.color} bg-light-${value?.color} mb-0 px-3 py-2 cursor-pointer`,
          }}
          options={stages}
          value={value}
          onChange={onChange}
          ref={ref}
          getOptionLabel={(options) => options.name || ''}
          isOptionEqualToValue={(options, value) => options.name === value.name}
          renderInput={(params) => (
            <div className='text-center' ref={params.InputProps.ref}>
              {/* 
                            //@ts-ignore */}
              <input
                type='text'
                {...params.inputProps}
                size={value?.name!.length}
                value={value?.name}
              />
            </div>
          )}
          renderOption={(props, options) => (
            <li {...props}>
              <Chip
                classes={{label: `text-${options.color}`, filled: `bg-light-${options.color}`}}
                label={options.name}
                clickable={true}
              />
            </li>
          )}
        />
      </div>
    )
  }
}

export {TaskByUserStatusCell}
