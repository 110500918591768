import {FC, useEffect, useState} from 'react'
import {TaskEditModalFormWrapper} from './TaskEditModalFormWrapper'
import {Modal} from 'react-bootstrap'
import {ID} from '../../../../../../_metronic/helpers'
import {useSearchParams} from 'react-router-dom'

type Props = {}

const TaskEditModal: FC<Props> = ({}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const taskIdSearchParam = searchParams.get('taskId') as ID
  const [open, setOpen] = useState(false)
  useEffect(() => setOpen(!!taskIdSearchParam), [taskIdSearchParam])

  function handleClose() {
    let newParams = new URLSearchParams(searchParams)
    newParams.delete('taskId')
    setSearchParams(newParams)
  }

  return (
    <Modal
      className='modal'
      id='kt_modal_add_client'
      tabIndex={-1}
      dialogClassName='modal-dialog mw-1000px modal-dialog-centered'
      show={open}
      backdrop='static'
      onHide={handleClose}
    >
      <TaskEditModalFormWrapper handleClose={handleClose} taskId={taskIdSearchParam} />
    </Modal>
  )
}

export {TaskEditModal}
