import {useEffect, useState} from 'react'
import {
  DndContext,
  closestCorners,
  MouseSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  DragOverEvent,
  DropAnimation,
  defaultDropAnimation,
  KeyboardSensor,
} from '@dnd-kit/core'
import {arrayMove, sortableKeyboardCoordinates} from '@dnd-kit/sortable'
import {useSearchParams} from 'react-router-dom'
import {useQueryClient} from 'react-query'
import {useQueryResponseLoading} from '../../core/QueryResponseProvider'
import {useListView} from '../../core/ListViewProvider'
import {Board} from '../../core/_models'
import {Loading} from '../../../../../_metronic/partials/components/loading/Loading'
import {MyTasksListPagination} from '../pagination/MyTasksListPagination'
import {ID, KTSVG, QUERIES, stringifyRequestQuery} from '../../../../../_metronic/helpers'
import {stopTimeTracking, updateStatusTask} from '../../core/_requests'
import {findBoardSectionContainer, initializeBoard} from './helper/stages'
import {KanbanLane} from './KanbanLane'
import {KanbanCard} from './KanbanCard'
import {getTaskById} from './helper/tasks'
import {useQueryRequest} from '../../core/QueryRequestProvider'

const TasksKanban = () => {
  const {tasks, setTasks} = useListView()
  const isLoading = useQueryResponseLoading()
  const [showModal, setShowModal] = useState(false)
  const [action, setAction] = useState(false)
  const {setItemIdForUpdate} = useListView()
  const [activeTaskId, setActiveTaskId] = useState<string | null>()
  const [searchParams, setSearchParams] = useSearchParams()
  const [taskByIdURL, setTaskByIdURL] = useState<ID>()
  const [stageBoards, setStageBoards] = useState({} as Board)
  const queryClient = useQueryClient()
  const activeTask = activeTaskId ? getTaskById(tasks, activeTaskId) : null
  const {state} = useQueryRequest()

  useEffect(() => {
    setStageBoards(initializeBoard(tasks))
  }, [tasks])

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  const handleDragStart = (event: DragStartEvent) => {
    setActiveTaskId(event.active.id.toString())
  }

  const handleDragCancel = () => {
    setActiveTaskId(null)
  }

  const handleDragOver = (event: DragOverEvent) => {
    const {active, over} = event

    if (active.id || over?.id) {
      const activeContainer = findBoardSectionContainer(
        stageBoards,
        active.data.current?.sortable.containerId as string
      )
      const overContainer = findBoardSectionContainer(
        stageBoards,
        (over?.data.current?.sortable.containerId as string) ?? (over?.id as string)
      )

      if (!activeContainer || !overContainer || activeContainer === overContainer) return

      setStageBoards((stageBoard) => {
        const activeItems = stageBoard[activeContainer]
        const overItems = stageBoard[overContainer]

        const activeIndex =
          activeItems.length < 1
            ? 0
            : activeItems.findIndex((item) => (item !== undefined ? item.id === active?.id : ''))
        const overIndex =
          overItems.length < 1
            ? 0
            : overItems.findIndex((item) => (item !== undefined ? item.id !== over?.id : ''))

        return {
          ...stageBoard,
          [activeContainer]: [
            ...stageBoard[activeContainer].filter((item) =>
              item !== undefined ? item.id !== active?.id : ''
            ),
          ],
          [overContainer]: [
            ...stageBoard[overContainer].slice(0, overIndex),
            stageBoards[activeContainer][activeIndex],
            ...stageBoard[overContainer].slice(overIndex, stageBoard[overContainer].length),
          ],
        }
      })
    }
  }

  const handleDragEnd = (event: DragEndEvent) => {
    const {active, over} = event

    const activeContainer = findBoardSectionContainer(
      stageBoards,
      active.data.current?.sortable.containerId as string
    )
    const overContainer = findBoardSectionContainer(
      stageBoards,
      (over?.data.current?.sortable.containerId as string) ?? over?.id
    )

    if (activeTask?.status?.toLowerCase() === overContainer) return

    const index = tasks.findIndex((task) => task.id === active.id);

    if (!activeTask) return
    if (activeTask.play == 'run') stopTimeTracking(activeTask.id)

    const status = activeContainer![0].toUpperCase() + activeContainer!.substring(1)
    const newTasks = tasks;
    newTasks[index].status = status;
    setTasks(newTasks)
    updateStatusTask(activeTask.id, status)
    
    setActiveTaskId(null)
    // queryClient.invalidateQueries([`${QUERIES.TASKS_LIST}-${stringifyRequestQuery(state)}`])
  }

  const dropAnimation: DropAnimation = {
    ...defaultDropAnimation,
  }

  useEffect(() => {
    if (action == false && searchParams.get('taskId')) {
      var taskId_search = searchParams.get('taskId')!
      setItemIdForUpdate(parseInt(taskId_search))
      setTaskByIdURL(parseInt(taskId_search))
      setShowModal(true)
    }
  }, [])

  return (
    <div id='kt_tasks_project_kanban_pane' className='tab-pane fade'>
      <DndContext
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
        sensors={sensors}
        collisionDetection={closestCorners}
      >
        <div className='d-flex flex-column flex-lg-row justify-content-between align-items-center border-bottom px-5 py-5'>
          <div className='d-flex justify-content-between w-100 w-lg-auto mb-3 mb-lg-0'>
            <div className='d-flex gap-5'>
              <button
                className='btn btn-sm btn-icon btn-light btn-color-primary btn-active-primary d-lg-none'
                type='button'
                data-bs-toggle='offcanvas'
                data-bs-target='#kanbanOffcanvasFilter'
                aria-controls='kanbanOffcanvasFilter'
              >
                <KTSVG
                  path='/media/icons/duotune/general/gen052.svg'
                  className='svg-icon-2hx svg-icon-primary'
                />
              </button>
            </div>
          </div>
        </div>
        <div className='d-flex w-100 justify-content-left vh-100 overflow-x-scroll overflow-y-hidden pt-2'>
          {Object.keys(stageBoards).map((stageBoardKey) => {
            return (
              <div key={stageBoardKey} className='w-275px w-lg-350px p-5 flex-shrink-0'>
                <KanbanLane id={stageBoardKey} tasks={stageBoards[stageBoardKey]} />
              </div>
            )
          })}
        </div>
        <DragOverlay dropAnimation={dropAnimation}>
          {activeTask && <KanbanCard task={activeTask} />}
        </DragOverlay>
      </DndContext>

      <MyTasksListPagination />

      {isLoading && <Loading />}
    </div>
  )
}
export {TasksKanban}
