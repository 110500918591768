import {Board, Task} from '../../../core/_models'
import {getTasksByStage} from './tasks'

export const stages = ['backlog', 'em andamento', 'entregue']

export const initializeBoard = (tasks: Task[]) => {
  const boardSections: Board = {}

  stages.map((stage) => {
    boardSections[stage] = getTasksByStage(tasks, stage)
  })

  return boardSections
}

export const findBoardSectionContainer = (boardSections: Board, id: string) => {
  if (id in boardSections) {
    return id
  }

  const container = Object.keys(boardSections).find((key) =>
    id == undefined
      ? 0
      : boardSections[key].find((item) => (item !== undefined ? item.id?.toString() === id : 0))
  )
  return container
}
