import {FC} from 'react'
import {TaskModalContent} from './TaskModalContent'
import {ID} from '../../../helpers'
import {useParams} from 'react-router-dom'
import {TaskModalContentByUserId} from './TaskModalContentByUserId'

type Props = {
  handleClose: () => void | undefined
  stageId: ID | string
  sprintId: ID
}

const TaskModalContentWrapper: FC<Props> = ({handleClose, stageId, sprintId}) => {
  const {project_id} = useParams()

  return project_id ? (
    <TaskModalContent handleClose={handleClose} stageId={stageId} sprintId={sprintId} />
  ) : (
    <TaskModalContentByUserId handleClose={handleClose} stageId={stageId} sprintId={sprintId} />
  )
}

export {TaskModalContentWrapper}
