import {TasksByUserListSearchComponent} from "./TasksByUserListSearchComponent";

const TasksByUserListHeader = () => {
    return (
        <div className='card h-100'>
            <div className='card-header flex-grow-1 align-items-start border-0 p-5'>
            {/* begin::Card toolbar */}
                {/* begin::Group actions */}
                <TasksByUserListSearchComponent />
                {/* end::Group actions */}
            {/* end::Card toolbar */}
        </div>
        </div>

            )
}

export {TasksByUserListHeader}
