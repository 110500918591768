// @ts-nocheck
import clsx from 'clsx'
import {FC} from 'react'
import {Row} from 'react-table'

type Props = {
  row: Row<Contracts>
}

const CustomRow: FC<Props> = ({row}) => (
  
  <tr {...row.getRowProps()}>
    
    {row.cells.map((cell, index) => {
      return (
        
        <td
          {...cell.getCellProps()}
          className={clsx({'text-end min-w-100px': cell.column.id === 'actions'})}
        >
          {cell.render('Cell')}
        </td>
      )
    })}
  </tr>
)

export {CustomRow}
