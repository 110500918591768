import {FC, useMemo} from 'react'
import {useTable, Row} from 'react-table'
import {useIntl} from 'react-intl'
import {useQuery} from 'react-query'
import {KTCardBody} from '../../../../helpers'
import {Task} from '../../../../../app/modules/reports/components/sprint-team/core/_models'
import {tasksColumns} from './columns/_columns'
import {CustomRow} from './columns/CustomRow'
import {getTasksByUserSprintAndStatus} from '../../../../../app/modules/reports/components/sprint-team/core/_requests'
import {useSearchParams} from 'react-router-dom'

type Props = {
  status: string
  userId?: number
}

const TasksTable: FC<Props> = ({userId, status}) => {
  const [searchParams] = useSearchParams()
  const columns = useMemo(() => tasksColumns, [])
  const sprintId = searchParams.get('sprintId') ?? ''
  const userIdParam = searchParams.get('userId')
  const intl = useIntl()

  function useSprintTasksByUser(userId: number, status: string, type: string) {
    const fetcher = async () =>
      await getTasksByUserSprintAndStatus(sprintId, userId ?? userIdParam, status)
    const queryResult = useQuery(['sprintUsersByUser', sprintId, userId, status, type], fetcher, {})
    return {...queryResult, data: queryResult.data ?? ([] as Task[])}
  }

  const {data: tasks, isLoading} = useSprintTasksByUser(userId!, status, userId ? 'user' : 'sprint')

  const data = useMemo(() => tasks, [tasks])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  return (
    <>
      <KTCardBody className='p-4 pt-0 mh-350px overflow-scroll'>
        <div className='table-responsive'>
          <table
            id='kt_table_tasks'
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer tab-pane fade show active'
            {...getTableProps()}
          >
            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {rows.length > 0 && !isLoading ? (
                rows.map((row: Row<Task>, i) => {
                  prepareRow(row)
                  return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                })
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      {intl.formatMessage({id: 'TABLE.GENERAL.NO_RECORD_FOUND'})}
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </KTCardBody>
    </>
  )
}

export {TasksTable}
