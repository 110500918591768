import {FC, useState, useMemo, HTMLProps} from 'react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'
import {Project} from '../../../../app/modules/project/components/core/_models'

type Props = Omit<HTMLProps<HTMLInputElement>, 'onChange'> & {
  updateData: (fieldsToUpdate: Partial<Project>) => void
  handleBlur?: ({
    cycleId,
    cycleOption,
    cycle,
    budget,
    budgeted_hours,
  }: Partial<Project> & {cycleId?: number; cycleOption?: string}) => void
  value: string | number
  inputValue?: string
  label?: string
  inputType?: string
  field: string
}

const InputEdit: FC<Props> = ({
  updateData,
  handleBlur,
  value,
  inputValue,
  label,
  inputType,
  field,
  ...props
}) => {
  const intl = useIntl()
  const [editableField, setEditableField] = useState<boolean>(false)
  const [dataChanged, setDataChanged] = useState<boolean>(false)
  const [blured, setBlured] = useState<boolean>(true)
  const oldInputValue = useMemo(() => (inputValue != null ? inputValue : value), [blured])

  const handleCloseEditableField = () => {
    if (editableField) {
      updateData({
        [field]: oldInputValue,
      })
    }
    setEditableField(!editableField)
  }

  return (
    <div
      className='d-flex align-items-center mb-6'
      onBlur={(e) => {
        if (editableField) {
          const currentTarget = e.currentTarget
          requestAnimationFrame(() => {
            if (!currentTarget.contains(document.activeElement)) {
              setEditableField(false)
              if (dataChanged && handleBlur) {
                handleBlur({})
              }
              setBlured(true)
            }
            setDataChanged(false)
          })
        }
      }}
    >
      <span className='fw-bold text-dark me-1'>{label}</span>
      {editableField ? (
        <input
          className='form-control form-control-sm form-control-solid mw-150px'
          type={inputType ?? 'text'}
          autoFocus
          value={inputValue ?? value}
          onFocus={() => setBlured(false)}
          onChange={(e) => {
            if (value !== e.target.value.toString()) {
              updateData({
                [field]: e.target.value,
              })
              setDataChanged(true)
              return
            }
            setDataChanged(false)
          }}
          {...props}
        />
      ) : (
        <span className='text-dark me-2'>{value}</span>
      )}
      <button onClick={handleCloseEditableField} className='btn p-0'>
        <i
          className={clsx(
            'cursor-pointer fs-5 bi',
            editableField ? 'bi-x-square-fill ms-4' : 'bi-pencil'
          )}
        ></i>
      </button>
    </div>
  )
}

export {InputEdit}
