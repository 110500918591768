/* eslint-disable jsx-a11y/anchor-is-valid */
import {useIntl} from 'react-intl'
import {StepProps} from '../../../../../app/modules/project/components/core/_models'
import {KTSVG} from '../../../../helpers'

const Step2 = ({data, updateData}: StepProps) => {
  const intl = useIntl()

  return (
    <>
      {/*begin::Step 3 */}
      <div className='pb-5' data-kt-stepper-element='content'>
        <div className='w-100'>
          {/*begin::Form Group */}
          <div className='fv-row mb-12'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-4'>
              {intl.formatMessage({id: 'FORM.INPUT.NAME.PROJECT_TYPE'})}
            </label>

            {/*begin:Option */}
            <label className='d-flex align-items-center justify-content-between cursor-pointer mb-6'>
              <span className='d-flex align-items-center me-2'>
                <span className='symbol symbol-50px me-6'>
                  <span className='symbol-label bg-light-success'>
                    <KTSVG path="/media/icons/duotune/abstract/abs027.svg" className="svg-icon-success svg-icon-2hx" />
                  </span>
                </span>

                <span className='d-flex flex-column'>
                  <span className='fw-bolder fs-6'>{intl.formatMessage({id: 'FORM.INPUT.NAME.CLOSED_SCOPE'})}</span>
                  <span className='fs-7 text-muted'>{intl.formatMessage({id: 'FORM.INPUT.TOOLTIP.CLOSED_SCOPE'})}</span>
                </span>
              </span>

              <span className='form-check form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='type'
                  value={1}
                  checked={data.type === 1}
                  onChange={(e) =>
                    updateData({
                      type: parseInt(e.target.value)
                    })
                  }
                />
              </span>
            </label>
            {/*end::Option */}
            
            {/*begin:Option */}
            <label className='d-flex align-items-center justify-content-between cursor-pointer mb-6'>
              <span className='d-flex align-items-center me-2'>
                <span className='symbol symbol-50px me-6'>
                  <span className='symbol-label bg-light-warning'>
                    <KTSVG path="/media/icons/duotune/abstract/abs028.svg" className="svg-icon-warning svg-icon-2hx" />
                  </span>
                </span>

                <span className='d-flex flex-column'>
                  <span className='fw-bolder fs-6'>{intl.formatMessage({id: 'FORM.INPUT.NAME.RECURRENT_PROJECT'})}</span>
                  <span className='fs-7 text-muted'>{intl.formatMessage({id: 'FORM.INPUT.TOOLTIP.RECURRENT_PROJECT'})}</span>
                </span>
              </span>

              <span className='form-check form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='projectType'
                  value={0}
                  checked={data.type === 0}
                  onChange={(e) =>
                    updateData({
                      type: parseInt(e.target.value)
                    })
                  }
                />
              </span>
            </label>
            {/*end::Option */}
          </div>
          {/*end::Form Group */}
          
          

          {/*begin::Form Group */}
          {/* <div className='fv-row pt-8 border-top border-gray-300 border-top-dashed'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-4'>
              {intl.formatMessage({id: 'FORM.INPUT.NAME.CATEGORIES'})}
            </label> */}

            {/*begin:Option */}
            {/* <label className='d-flex align-items-center justify-content-between cursor-pointer mb-6 '>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <span className='fw-bolder fs-6'>Marketing Digital</span>
                </span>
              </span>

              <span className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input h-20px w-35px'
                  type='checkbox'
                  value='marketing-digital'
                  onChange={(e) => {
                    const index = data.categories!.indexOf('marketing-digital')
                    updateData({
                      type: data.type,
                      categories: !e.target.checked && index > -1 ? data.categories!.filter(category => category !== e.target.value) : data.categories!.concat(e.target.value)
                    })
                  }
                  }
                />
              </span>
            </label> */}
            {/*end::Option */}

            {/*begin:Option */}
            {/* <label className='d-flex align-items-center justify-content-between cursor-pointer mb-6'>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <span className='fw-bolder fs-6'>Desenvolvimento</span>
                </span>
              </span>

              <span className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input h-20px w-35px'
                  type='checkbox'
                  value='desenvolvimento'
                  onChange={(e) => {
                    const index = data.categories!.indexOf(e.target.value)
                    updateData({
                      type: data.type,
                      categories: !e.target.checked && index > -1 ? data.categories!.filter(category => category !== e.target.value) : data.categories!.concat(e.target.value)
                    })
                  }
                  }
                />
              </span>
            </label> */}
            {/*end::Option */}
            
            {/*begin:Option */}
            {/* <label className='d-flex align-items-center justify-content-between cursor-pointer mb-6'>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <span className='fw-bolder fs-6'>Hospedagem</span>
                </span>
              </span>

              <span className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input h-20px w-35px'
                  type='checkbox'
                  value='Hospedagem'
                  onChange={(e) => {
                    const index = data.categories!.indexOf(e.target.value)
                    updateData({
                      type: data.type,
                      categories: !e.target.checked && index > -1 ? data.categories!.filter(category => category !== e.target.value) : data.categories!.concat(e.target.value)
                    })
                  }
                  }
                />
              </span>
            </label> */}
            {/*end::Option */}
          {/* </div> */}
          {/*end::Form Group */}
        </div>
      </div>
      {/*end::Step 3 */}
    </>
  )
}

export {Step2}
