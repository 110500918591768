/* eslint-disable jsx-a11y/anchor-is-valid */
//@ts-nocheck
import React, {FC, forwardRef, useEffect, useState} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {useIntl} from 'react-intl'
import {ID, QUERIES} from '../../../../../../_metronic/helpers'
import {useListView} from '../../../core/ListViewProvider'
import {useQueryResponse} from '../../../core/QueryResponseProvider'
import Swal from 'sweetalert2'
import {deleteTask, updateStatusTask} from '../../../core/_requests'
import {deliveryTask} from '../../../core/_requests'
import {Dropdown} from 'react-bootstrap'
import {Toast} from '../../../../../../_metronic/partials/widgets'
import {TaskCloneModal} from '../../../../project/components/tasks-list/task-clone-modal/TaskCloneModal'
import {AdjustTimeUserModal} from '../../../../../../_metronic/partials/modals/adjust-time-user/AdjustTimeUserModal'
import {useSearchParams} from 'react-router-dom'
import {exit} from 'process'

type Props = {
  taskId: ID
  name: string
  status: string
}

export type CustomToggleProps = {
  children: React.ReactNode
  onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {}
}

const TaskByUserActionsCell: FC<Props> = ({taskId, name, status}) => {
  const intl = useIntl()
  const {setItemIdForUpdate} = useListView()
  const queryClient = useQueryClient()
  const {refetch} = useQueryResponse()

  const [showModal, setShowModal] = useState<boolean>(false)
  const [showModalClone, setShowModalClone] = useState<boolean>(false)
  const [showAdjustTimeModal, setShowAdjustTimeModal] = useState<boolean>(false)
  const TemaAtual = window.localStorage.getItem('kt_theme_mode_value') || ''
  const [action, setAction] = useState<boolean>(false)

  const [searchParams, setSearchParams] = useSearchParams()
  const [taskByIdURL, setTaskByIdURL] = useState<ID>()

  const openEditModal = (taskIdParam: ID) => {
    const newParams = new URLSearchParams(searchParams)
    newParams.set('taskId', taskId!.toString())
    setSearchParams(newParams)
    setItemIdForUpdate(taskIdParam)
    setTaskByIdURL(taskIdParam)
    setShowModal(true)
    setAction(true)
  }

  const openCloneTask = () => {
    setItemIdForUpdate(taskId!)
    setShowModalClone(true)
  }

  const openTask = async () => {
    updateStatusTask(taskId, 'Backlog')

    Toast.fire({
      icon: 'success',
      title: intl.formatMessage(
        {id: 'ALERT.LABEL.SUCCESS_TASK_OPEN_ITEM'},
        {item: intl.formatMessage({id: 'TABLE.GENERAL.LABEL.TASK'})}
      ),
      customClass: {
        popup: 'bg-light-success',
        title: 'text-success',
      },
    })
    queryClient.invalidateQueries()
    cancel(true)
  }

  const entregatask = async () => {
    const CurrentTheme = window.localStorage.getItem('kt_theme_mode_value') || ''
    let tema = ''
    if (CurrentTheme === 'dark') {
      tema = '#353b48'
    } else {
      tema = '#fff'
    }

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: false,
    })

    swalWithBootstrapButtons
      .fire({
        title: intl.formatMessage({id: 'ALERT.TITLE.TASK.DELIVERY'}),
        icon: 'warning',
        background: tema,
        showCancelButton: true,
        confirmButtonText: intl.formatMessage({id: 'GENERAL.YES'}),
        cancelButtonText: intl.formatMessage({id: 'GENERAL.NOT'}),
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          return await deliveryItem.mutateAsync()
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          Toast.fire({
            icon: 'warning',
            title: intl.formatMessage(
              {id: 'ALERT.LABEL.ERROR_TASK_CLOSE_ITEM'},
              {item: intl.formatMessage({id: 'TABLE.GENERAL.LABEL.TASK'})}
            ),
            customClass: {
              popup: 'bg-light-danger',
              title: 'text-danger',
            },
          })
        }
      })
  }

  const deleta = async () => {
    const CurrentTheme = window.localStorage.getItem('kt_theme_mode_value') || ''
    let tema = ''
    if (CurrentTheme === 'dark') {
      tema = '#353b48'
    } else {
      tema = '#fff'
    }

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: false,
    })

    swalWithBootstrapButtons
      .fire({
        title: intl.formatMessage({id: 'ALERT.LABEL.ARE_YOU_SURE'}),
        html: intl.formatMessage({id: 'ALERT.LABEL.IT_CAN_NOT_BE_UNDONE'}),
        icon: 'warning',
        background: tema,
        showCancelButton: true,
        confirmButtonText: 'Sim, pode excluir',
        cancelButtonText: 'Não, cancele!',
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          return await deleteItem.mutateAsync()
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          Toast.fire({
            icon: 'success',
            title: intl.formatMessage(
              {id: 'ALERT.LABEL.SUCCESS_TASK_UPDATE_ITEM'},
              {item: intl.formatMessage({id: 'TABLE.GENERAL.LABEL.TASK'})}
            ),
            customClass: {
              popup: 'bg-light-success',
              title: 'text-success',
            },
          })
        }
      })
  }

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const deliveryItem = useMutation(() => deliveryTask(taskId), {
    onSuccess: (response) => {
      Toast.fire({
        icon: response.status === 'success' ? 'success' : 'error',
        title: response.message,
        customClass: {
          popup: response.status === 'success' ? 'bg-light-success' : 'bg-light-error',
          title: TemaAtual === 'dark' ? 'text-dark' : 'text-success',
        },
      })

      if (play === 'run')
        setCurrentUser((user) => ({...user, has_active_time_tracking: false} as UserModel))

      cancel(true)
      queryClient.invalidateQueries()
    },
  })

  const deleteItem = useMutation(() => deleteTask(taskId), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      Toast.fire({
        icon: 'success',
        title: intl.formatMessage(
          {id: 'ALERT.LABEL.SUCCESS_TASK_DELETE_ITEM'},
          {item: intl.formatMessage({id: 'TABLE.GENERAL.LABEL.TASK'})}
        ),
        customClass: {
          popup: 'bg-light-success',
          title: 'text-success',
        },
      })
      cancel(true)
      queryClient.invalidateQueries()
    },
  })

  const CustomToggle = forwardRef((props: CustomToggleProps, ref: React.Ref<HTMLAnchorElement>) => (
    <a
      href=''
      className='text-hover-primary'
      ref={ref}
      onClick={(e) => {
        e.preventDefault()
        props.onClick(e)
      }}
    >
      {props.children}
      <span style={{paddingLeft: '5px'}}></span>
    </a>
  ))

  return (
    <>
      {name !== '' && (
        <div
          className='text-center'
          style={{display: 'flex', gap: '20px', justifyContent: 'center'}}
        >
          <button
            className='btn btn-icon-dark btn-active-icon-primary btn-active-text-primary p-0'
            onClick={() => openEditModal(taskId)}
          >
            <span className='svg-icon svg-icon-1'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='30'
                height='30'
                fill='currentColor'
                className='bi bi-eye-fill'
                viewBox='0 0 16 16'
              >
                <path d='M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z' />
                <path d='M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z' />
              </svg>
            </span>
          </button>

          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id='dropdown-custom-components'>
              <i className='bi bi-list-ul fs-1 text-dark'></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={(e) => {
                  e.preventDefault()
                  setShowAdjustTimeModal(true)
                }}
                eventKey='2'
              >
                {intl.formatMessage({id: 'TABLE.GENERAL.LABEL.ADJUST_TIMETRACKING'})}
              </Dropdown.Item>
              {status == 'Entregue' ? (
                <Dropdown.Item onClick={openTask} eventKey='4'>
                  {intl.formatMessage({id: 'TABLE.GENERAL.LABEL.OPEN_TASK'})}
                </Dropdown.Item>
              ) : (
                <Dropdown.Item onClick={entregatask} eventKey='4'>
                  {intl.formatMessage({id: 'TABLE.GENERAL.LABEL.FINIS_TASK'})}
                </Dropdown.Item>
              )}

              <Dropdown.Item onClick={openCloneTask} eventKey='5'>
                {intl.formatMessage({id: 'TABLE.GENERAL.LABEL.CLONE_TASK'})}
              </Dropdown.Item>
              <Dropdown.Item onClick={deleta} eventKey='6'>
                {intl.formatMessage({id: 'TABLE.GENERAL.LABEL.DELETE'})}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
      <TaskCloneModal
        show={showModalClone}
        handleClose={() => setShowModalClone(false)}
        taskId={taskId}
      />
      <AdjustTimeUserModal
        show={showAdjustTimeModal}
        handleClose={() => setShowAdjustTimeModal(false)}
        taskId={taskId!}
      />
    </>
  )
}

export {TaskByUserActionsCell}
