import {FC, useEffect} from 'react'
import {TaskCloneModalFormWrapper} from './TaskCloneModalFormWrapper'
import {Modal} from 'react-bootstrap'
import {TaskModalContentWrapper} from "../../../../../../_metronic/partials/modals/task/TaskModalContentWrapper";
import { ID } from '../../../../../../_metronic/helpers';

type Props = {
  show: boolean
  handleClose: () => void | undefined
  taskId: ID
}

const TaskCloneModal: FC<Props> = ({show, handleClose, taskId}) => {
  return (
    <>
    {/* 
    //@ts-ignore */}
      <Modal
        className='modal'
        id='kt_modal_add_task_clone'
        tabIndex={-1}
        dialogClassName='modal-dialog mw-1000px modal-dialog-centered'
        show={show}
        backdrop='static'
        onHide={handleClose}
      >
        <TaskCloneModalFormWrapper handleClose={handleClose} taskId={taskId} />
      </Modal>

    </>
  )
}

export {TaskCloneModal}
