import {Modal} from 'react-bootstrap'
import {ID} from '../../../helpers'
import {DetailsTimeUserModalContentWrapper} from './DetailsTimeUserModalContentWrapper'

type Props = {
  show: boolean
  handleClose: () => void | undefined
  userId: String 
  dateSelected: String
  dateFormatSelected: String
}


const DetailsTimeUserModal: React.FC<Props> = ({show, handleClose, userId, dateSelected, dateFormatSelected}) => {
  return (
    //@ts-ignore
    <Modal className='px-md-5' id='kt_modal_adjust_time' data-backdrop='static' tabIndex={-1} role='dialog' show={show}
      dialogClassName='mw-600px modal-dialog-centered' aria-hidden='true' onHide={handleClose}>
      <DetailsTimeUserModalContentWrapper handleClose={handleClose} userId={userId} dateSelected={dateSelected} dateFormatSelected={dateFormatSelected} />
    </Modal>
  )
}

export {DetailsTimeUserModal}