import axios, {AxiosResponse} from 'axios'
import {ID, QueryState, Response} from '../../../../../../../_metronic/helpers'
import {ResponseTaskUsers, TaskUsers, TasksQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const GET_TASKUSERS_URL = `${API_URL}/api/tarefas/usuarios`

const taskresponsible_insert =  (taskUsers: TaskUsers): Promise<ResponseTaskUsers> => {
  let formData = new FormData()
  if (taskUsers.task_id) {
    formData.append('task_id', taskUsers.task_id.toString())
  }
  if (taskUsers.users) {
    formData.append('users', JSON.stringify(taskUsers.users))
  }

  return axios
      .post(`${GET_TASKUSERS_URL}/insert`, formData)
      .then((response: AxiosResponse<ResponseTaskUsers>) => response.data)
}

const taskresponsible_adjust =  (taskUsers: TaskUsers): Promise<ResponseTaskUsers> => {
  let formData = new FormData()
  if (taskUsers.task_id) {
    formData.append('task_id', taskUsers.task_id.toString())
  }
  if (taskUsers.user_id) {
    formData.append('user_id', taskUsers.user_id?.toString())
  }

  return axios
      .post(`${GET_TASKUSERS_URL}/adjust`, formData)
      .then((response: AxiosResponse<ResponseTaskUsers>) => response.data)
}

const taskresponsible_delete_columm = (task_responsible: ID): Promise<void> => {
  return axios.get(`${GET_TASKUSERS_URL}/deletecolumn/${task_responsible}`).then(() => {})
}

const taskresponsible_delete =  (taskUsers: TaskUsers): Promise<ResponseTaskUsers> => {
  let formData = new FormData()
  if (taskUsers.task_id) {
    formData.append('task_id', taskUsers.task_id.toString())
  }
  if (taskUsers.users) {
    formData.append('users', JSON.stringify(taskUsers.users))
  }

  return axios
      .post(`${GET_TASKUSERS_URL}/delete`, formData)
      .then((response: AxiosResponse<ResponseTaskUsers>) => response.data)
}


// const deleteUser = (attachementid: ID): Promise<void> => {
//   return axios.delete(`${GET_TASKUSERS_URL}/${attachementid}/excluir`).then(() => {})
// }

const getTaskUserById = (id: ID): Promise<TasksQueryResponse> => {
  return axios
      .get(`${GET_TASKUSERS_URL}/${id}`)
      .then((d: AxiosResponse<TasksQueryResponse>) => d.data)
}


export {taskresponsible_insert,taskresponsible_adjust, taskresponsible_delete, getTaskUserById, taskresponsible_delete_columm}
