/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo} from 'react'
import {useQuery} from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  PaginationState,
  QUERIES,
  WithChildren,
} from '../../../../../_metronic/helpers'
import {getProjectsList} from './_requests'
import {Project, ProjectsQueryResponse} from './_models'
import {useSearchParams} from 'react-router-dom'

const QueryResponseContext = createResponseContext<Project>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({children}) => {
  const [searchParams] = useSearchParams()
  const [query, setQuery] = useState<string>(searchParams.toString())
  const [isEmptyResults, setIsEmptyResults] = useState(false)

  useEffect(() => {
    setQuery(searchParams.toString())
  }, [searchParams])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(`${QUERIES.PROJECTS_LIST}-${query}`, () => getProjectsList(query), {
    onSuccess: (data) => setIsEmptyResults(data.isEmptyResults),
    cacheTime: 0,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  return (
    <QueryResponseContext.Provider
      value={{isLoading: isFetching, refetch, response: response?.data, query, isEmptyResults}}
    >
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const {response} = useQueryResponse()

  if (!response) return []

  return response?.data || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    per_page: 12,
    current_page: 1,
    // ...initialQueryState,
  }

  const {response} = useQueryResponse()
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState
  }

  return response.payload.pagination
}

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

const useQueryResponseTotalData = (): number => {
  const {response} = useQueryResponse()
  return response?.payload?.pagination?.total ?? 0
}

const useQueryResponseIsEmptyResults = (): boolean => {
  const {isEmptyResults} = useQueryResponse()
  return isEmptyResults ?? false
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
  useQueryResponseTotalData,
  useQueryResponseIsEmptyResults,
}
