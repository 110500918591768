import {FC, useState} from 'react'
import {useQuery} from 'react-query'
import {isNotEmpty, QUERIES} from '../../../helpers'
import {ContractsModalContent} from './ContractsModalContent'
import {ID} from '../../../helpers'
import { getContractById } from '../../../../app/modules/contracts/components/contracts-list/core/_requests'

type Props = {
  id: ID
  projectId: string | undefined
  clientId: string | undefined
  handleClose: () => void | undefined
}

const ContractsModalContentWrapper: FC<Props> = ({id, handleClose,projectId,clientId}) => {
  const [itemIdForUpdate, setItemIdForUpdate] = useState<ID>(id)
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
      isLoading,
      data: contract,
      error,
    } = useQuery(
      `${QUERIES.CONTRACTS_LIST}-CONTRACT-${itemIdForUpdate}`,
      () => {
        return getContractById(itemIdForUpdate)
      },
      {
        cacheTime: 0,
        enabled: enabledQuery,
        onError: (err) => {
          setItemIdForUpdate(undefined)
          console.error(err)
        },
        refetchOnWindowFocus: false
      }
    )

  if (!itemIdForUpdate) {
    return <ContractsModalContent handleClose={handleClose} projectId={projectId} clientId={clientId} isContractLoading={isLoading} Contracts={{
      contracts: {
        id: undefined,
      },
      message: '',
      errors: false
    }}/>
  }

  if (!isLoading && !error && contract) {
    return <ContractsModalContent handleClose={handleClose} projectId={projectId} clientId={clientId} isContractLoading={isLoading} Contracts={contract}/>
  }

  return null
}

export {ContractsModalContentWrapper}