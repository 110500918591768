import {useEffect, useMemo, useState} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {
  DndContext,
  closestCorners,
  MouseSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core'
import {arrayMove, SortableContext, verticalListSortingStrategy} from '@dnd-kit/sortable'
import {restrictToVerticalAxis} from '@dnd-kit/modifiers'
import {useIntl} from 'react-intl'
import {useSearchParams} from 'react-router-dom'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {useQueryResponseLoading} from '../../core/QueryResponseProvider'
import {useListView} from '../../core/ListViewProvider'
import {tasksColumns} from './columns/_columns'
import {Task} from '../../core/_models'
import {Loading} from '../../../../../_metronic/partials/components/loading/Loading'
import {MyTasksListPagination} from '../../components/pagination/MyTasksListPagination'
import {ID, KTCardBody} from '../../../../../_metronic/helpers'
import {updateOrderTask} from '../../core/_requests'

const MyTasksTable = () => {
  const {tasks, setTasks} = useListView()
  const isLoading = useQueryResponseLoading()
  const columns = useMemo(() => tasksColumns, [])
  const intl = useIntl()
  const data = useMemo(() => tasks, [tasks])
  const [showModal, setShowModal] = useState<boolean>(false)
  const {setItemIdForUpdate} = useListView()

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  const items = useMemo(
    () =>
      tasks?.reduce((filtered: string[], option: Task) => {
        if (option.id !== null && option.id !== undefined) {
          var id = option.id.toString()
          filtered.push(id)
        }
        return filtered
      }, []),
    [tasks]
  )

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  )

  const handleDragEnd = (event: DragEndEvent) => {
    const {active, over} = event
    if (over && active.id !== over.id) {
      var overId = over.id.toString()
      var activeId = active.id.toString()

      var newIndex = items.indexOf(overId)
      var activeIndex = items.indexOf(activeId)

      var initialIndex = Math.min(newIndex, activeIndex)
      var finalIndex = Math.max(newIndex, activeIndex)

      let newTasks = JSON.parse(JSON.stringify(tasks))
      newTasks = arrayMove(newTasks, activeIndex, newIndex)

      const tasksToUpdateOrder: {id: number; order_mytasks: number}[] = []
      for (initialIndex; initialIndex <= finalIndex; initialIndex++) {
        newTasks[initialIndex].order_mytasks = tasks[initialIndex].order_mytasks
        tasksToUpdateOrder.push({
          id: newTasks[initialIndex].id!,
          order_mytasks: newTasks[initialIndex].order_mytasks!,
        })
      }

      updateOrderTask(tasksToUpdateOrder)
      setTasks(newTasks)
    }
  }

  const [action, setAction] = useState<boolean>(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [taskByIdURL, setTaskByIdURL] = useState<ID>()

  useEffect(() => {
    if (action == false && searchParams.get('taskId')) {
      var taskId_search = searchParams.get('taskId')!
      setItemIdForUpdate(parseInt(taskId_search))
      setTaskByIdURL(parseInt(taskId_search))
      setShowModal(true)
    }
  }, [])

  return (
    <div id='kt_tasks_project_list_pane' className='tab-pane fade show active'>
      <KTCardBody className='py-4'>
        <div className='table-responsive'>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCorners}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToVerticalAxis]}
          >
            <table
              id='kt_table_tasks'
              className='table align-middle table-row-dashed fs-6 gy-4 dataTable no-footer tab-pane fade show active'
              {...getTableProps()}
            >
              <thead>
                <tr className='text-start text-muted fw-bolder fs-7 gs-0 border-bottom-0'>
                  {headers.map((column: ColumnInstance<Task>) => (
                    <CustomHeaderColumn key={column.id} column={column} />
                  ))}
                </tr>
              </thead>
              <tbody className='text-gray-600' {...getTableBodyProps()}>
                <SortableContext items={items} strategy={verticalListSortingStrategy}>
                  {rows.length > 0 ? (
                    rows.map((row: Row<Task>, i) => {
                      prepareRow(row)
                      return <CustomRow row={row} key={row.original.id} />
                    })
                  ) : (
                    <tr>
                      <td colSpan={7}>
                        <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                          {intl.formatMessage({id: 'TABLE.GENERAL.NO_RECORD_FOUND'})}
                        </div>
                      </td>
                    </tr>
                  )}
                </SortableContext>
              </tbody>
            </table>
          </DndContext>
        </div>
        <MyTasksListPagination />

        {isLoading && <Loading />}
      </KTCardBody>
    </div>
  )
}

export {MyTasksTable}
