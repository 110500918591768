/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {KTSVG} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'
import {getCSS, getCSSVariableValue} from '../../../assets/ts/_utils'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'

type Props = {
    chartColor: string
    chartHeight?: string
    percentage: number
}

const ChartsWidget14: React.FC<Props> = ({chartColor, chartHeight = '230px', percentage}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, chartOptions(chartColor, chartHeight, percentage))
    if (chart) {
      chart.render()
    }

    return chart
  }
  
  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, percentage])

  return (
    <>
        {/* begin::Chart */}
        <div ref={chartRef} id='kt_charts_widget_1_chart' />
        {/* end::Chart */}
    </>
  )
}

export {ChartsWidget14}

const chartOptions = (chartColor: string, chartHeight: string, percentage: number): ApexOptions => {
    chartColor = percentage <= 100 ? chartColor : 'danger'
    const baseColor = getCSSVariableValue('--kt-' + chartColor)
    const lightColor = getCSSVariableValue('--kt-' + chartColor + '-light')
    const labelColor = getCSSVariableValue('--kt-gray-700')
  
    return {
      series: [percentage],
      chart: {
        fontFamily: 'inherit',
        height: chartHeight,
        type: 'radialBar',
      },
      plotOptions: {
        radialBar: {
            startAngle: -90,
            endAngle: 90,
            hollow: {
                margin: 10,
                size: "72%",
            },
            dataLabels: {
                value: {
                    color: labelColor,
                    fontSize: "30px",
                    fontWeight: "600",
                    offsetY: -12,
                    show: true,
                    formatter: function (value) {
                        return percentage + '%';
                    }
                }
            },
            track: {
                background: lightColor,
                strokeWidth: '100%'
            }
        }
    },
      colors: [baseColor],
      stroke: {
        lineCap: 'round',
        // width: 0.5,
        curve: 'stepline'
      },
      labels: [''],
    }
  }
