import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../helpers'
import {ResponseProjectAndClient, ProjectAndClient} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const PROJECT_AND_CLIENT_URL = `${API_URL}/api/projetos/new-project-and-client`

const createProjectAndClient =  (projectAndClient: ProjectAndClient): Promise<ResponseProjectAndClient> => {
  return axios
    .post(`${PROJECT_AND_CLIENT_URL}`, projectAndClient)
    .then((response: AxiosResponse<ResponseProjectAndClient>) => response.data)
}

export {createProjectAndClient}
