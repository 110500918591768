/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {ID} from "../../../../../../_metronic/helpers"
import {UsersSymbolGroupColumn} from "../../../../project/components/tasks-list/UsersSymbolGroupColumn"



type Props = {
  users: Array<any>
  usersAll: Array<any>
  task_id: ID
}

const TaskByUserParticipantsCell: FC<Props> = ({users, usersAll, task_id}) => {

  return (
   <>
  <UsersSymbolGroupColumn users={users} usersAll={usersAll} classes='justify-content-start' taskId={task_id}/>
   </>
  )
}

export {TaskByUserParticipantsCell}
