import {Routes, Route} from 'react-router-dom'
import {OnboardingPageWrapper} from './OnboardingPageWrapper'
import {StepsWrapper} from './steps/StepsWrapper'

const OnboardingPage = () => {
  return (
    <Routes>
      <Route element={<OnboardingPageWrapper />}>
        <Route
          path='/'
          element={
            <>
              <StepsWrapper />
            </>
          }
        />
        <Route
          path=':step'
          element={
            <>
              <StepsWrapper />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export {OnboardingPage}
