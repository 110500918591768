import {Modal} from 'react-bootstrap'
import {ID} from '../../../helpers'
import {ContractsModalContentWrapper} from './ContractsModalContentWrapper'
import { ContractEditModalHeader } from './components/header/ContractEditModalHeader'

type Props = {
  show: boolean
  handleClose: () => void | undefined
  projectId: string | undefined
  clientId: string | undefined
  contractId: ID
}

const ContractsModal: React.FC<Props> = ({show, handleClose, projectId, contractId,clientId}) => {
  return (
    //@ts-ignore
    <Modal
      id='kt_modal_add_contract'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered'
      show={show}
      backdrop='static'
      onHide={handleClose}
    >
      <div className='modal-content'>
        <ContractEditModalHeader handleClose={handleClose} />
        <div className='modal-body scroll-y'>
          <ContractsModalContentWrapper
            projectId={projectId}
            clientId={clientId}
            id={contractId}
            handleClose={handleClose}
          />
        </div>
      </div>
    </Modal>
  )
}

export {ContractsModal}
