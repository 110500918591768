import {FC, useState, useMemo, HTMLProps, useEffect} from 'react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'
import {Project, ProjectCycle} from '../../../../app/modules/project/components/core/_models'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../helpers'

type Props = Omit<HTMLProps<HTMLSelectElement>, 'onChange'> & {
  currentCycle: ProjectCycle | undefined
  updateData: (fieldsToUpdate: Partial<Project> & {cycleOption?: string}) => void
  handleBlur?: ({
    cycleId,
    cycleOption,
    cycle,
    budget,
    budgeted_hours,
  }: Partial<Project> & {cycleId?: number; cycleOption?: string}) => void
  value: string | number
  label?: string
  field: string
  options: {
    label: string | number
    value?: string | number
  }[]
  hasModal?: boolean
}

const SelectEdit: FC<Props> = ({
  currentCycle,
  updateData,
  handleBlur,
  value,
  label,
  field,
  options,
  hasModal = false,
  ...props
}) => {
  const intl = useIntl()
  const [openModal, setOpenModal] = useState(false)
  const [editableField, setEditableField] = useState<boolean>(false)
  const [dataChanged, setDataChanged] = useState<boolean>(false)
  const [blured, setBlured] = useState<boolean>(true)
  const [selectedOption, setSelectedOption] = useState('currentCycle')
  const oldInputValue = useMemo(() => value, [blured])
  const currentDate = new Date()
  const beginDate = currentCycle && new Date(currentCycle.begin_date + ' UTC -3')
  const endDate =
    beginDate &&
    new Date(
      beginDate.getUTCFullYear().toString() +
        '-' +
        (beginDate.getUTCMonth() + 2).toString() +
        '-' +
        value?.toString()
    )
  if (endDate && endDate <= currentDate) endDate.setMonth(endDate.getMonth() + 1)

  // useEffect(() => {
  //   updateData({cycleOption: selectedOption})
  // }, [selectedOption])

  const handleCloseEditableField = () => {
    if (editableField) {
      updateData({
        [field]: oldInputValue,
      })
    }
    setEditableField(!editableField)
  }

  function handleComplete() {
    if (handleBlur)
      handleBlur({
        cycleOption: selectedOption,
        [field]: value,
      })
    setBlured(true)
    setOpenModal(false)
    setSelectedOption('currentCycle')
  }

  function handleClose() {
    updateData({[field]: oldInputValue})
    setSelectedOption('currentCycle')
    setOpenModal(false)
  }

  return (
    <div
      className='d-flex align-items-center mb-6'
      onBlur={(e) => {
        if (editableField) {
          const currentTarget = e.currentTarget
          requestAnimationFrame(() => {
            if (!currentTarget.contains(document.activeElement)) {
              setEditableField(false)
              if (dataChanged && handleBlur && hasModal) setOpenModal(true)
              if (dataChanged && handleBlur && !hasModal) {
                handleBlur({})
                setBlured(true)
              }
            }
            setDataChanged(false)
          })
        }
      }}
    >
      <span className='fw-bold text-dark me-1'>{label}</span>
      {editableField ? (
        <select
          className='form-select form-select-sm form-select-solid mw-150px'
          value={value}
          onFocus={() => setBlured(false)}
          onChange={(e) => {
            if (value !== e.target.value.toString()) {
              updateData({
                [field]: e.target.value,
              })
              setDataChanged(true)
              return
            }
            setDataChanged(false)
          }}
          {...props}
        >
          <option>Ciclo atual</option>
          {options.map((option, i) => (
            <option key={i} value={option.value ?? option.label}>
              {option.label}
            </option>
          ))}
        </select>
      ) : (
        <span className='text-dark me-2'>{value}</span>
      )}
      <button onClick={handleCloseEditableField} className='btn p-0'>
        <i
          className={clsx(
            'cursor-pointer fs-5 bi',
            editableField ? 'bi-x-square-fill ms-4' : 'bi-pencil'
          )}
        ></i>
      </button>

      <Modal
        data-backdrop='static'
        tabIndex={-1}
        role='dialog'
        show={openModal}
        dialogClassName='modal-dialog-centered'
        aria-hidden='true'
        onHide={() => setOpenModal(false)}
      >
        <Modal.Dialog className='my-0'>
          <Modal.Header className='d-flex align-items-center py-2 border-bottom'>
            <Modal.Title className='fw-bold'>Confirmação de alteração de dia do ciclo</Modal.Title>
            <div className='d-flex'>
              <div
                className='btn btn-icon btn-sm btn-light-primary'
                data-bs-dismiss='modal'
                onClick={handleClose}
              >
                <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className='alert alert-primary mt-0 mb-6' role='alert'>
              O ciclo do projeto significa um período de 30 dias de atividades para o projeto. Este
              é um conceito para projetos recorrentes e é utilizado para entender o real esforço
              alocado para este projeto durante um determinado período.
            </div>

            <div>
              <p className='m-0'>
                Por favor, confirme sua alteração de ciclo. Atualmente, o ciclo deste projeto é todo
                <strong> dia {oldInputValue}.</strong>
              </p>
              <p className=''>
                Você pode optar para atualizar o dia do ciclo considerando daqui em diante, ou
                também, os ciclos passados. Selecione uma das opções abaixo:
              </p>
            </div>

            <div className='form-check form-check-custom form-check-solid'>
              <input
                className='form-check-input me-3'
                name='role'
                type='radio'
                value='currentCycle'
                id='kt_modal_update_role_option_0'
                checked={selectedOption === 'currentCycle'}
                onChange={(e) => setSelectedOption(e.target.value)}
              />

              <label className='form-check-label' htmlFor='kt_modal_update_role_option_0'>
                <div className='fw-bolder text-gray-800'>Daqui em diante</div>
              </label>
            </div>

            <div className='form-check form-check-custom form-check-solid mt-2'>
              <input
                className='form-check-input me-3'
                name='role'
                type='radio'
                value='allCycles'
                id='kt_modal_update_role_option_1'
                checked={selectedOption === 'allCycles'}
                onChange={(e) => setSelectedOption(e.target.value)}
              />

              <label className='form-check-label' htmlFor='kt_modal_update_role_option_1'>
                <div className='fw-bolder text-gray-800'>Passados e também daqui em diante</div>
              </label>
            </div>

            {selectedOption === 'currentCycle' ? (
              <div className='alert alert-warning mt-6 mb-0' role='alert'>
                *Atenção: Como resultado da mudança, o ciclo atual mudará de{' '}
                {`${
                  currentCycle &&
                  new Date(currentCycle.begin_date).toLocaleDateString(intl.locale, {
                    timeZone: 'UTC',
                  })
                } a ${
                  currentCycle &&
                  new Date(currentCycle.end_date).toLocaleDateString(intl.locale, {
                    timeZone: 'UTC',
                  })
                }`}{' '}
                para{' '}
                {`${
                  currentCycle &&
                  new Date(currentCycle.begin_date).toLocaleDateString(intl.locale, {
                    timeZone: 'UTC',
                  })
                } a ${
                  currentCycle &&
                  endDate?.toLocaleDateString(intl.locale, {
                    timeZone: 'UTC',
                  })
                }`}
              </div>
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            <button type='button' onClick={handleClose} className='btn btn-outline-secondary py-2'>
              Cancelar
            </button>
            <button type='button' onClick={handleComplete} className='btn btn-primary py-2'>
              Alterar dia do ciclo
            </button>
          </Modal.Footer>
        </Modal.Dialog>
      </Modal>
    </div>
  )
}

export {SelectEdit}
