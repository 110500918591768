import axios, {AxiosResponse} from 'axios'
import {ID, QueryState} from '../../../../../_metronic/helpers'
import {Stage, StageQueryResponse, ResponseStage} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const GET_STAGE_URL = `${API_URL}/api/stage`

const getStages = (): Promise<StageQueryResponse> => {
  return axios
    .get(GET_STAGE_URL)
    .then((d: AxiosResponse<StageQueryResponse>) => d.data)
}

const searchStage = (search: QueryState): Promise<StageQueryResponse> => {
  return axios
    .post(`${GET_STAGE_URL}/search`, search)
    .then((d: AxiosResponse<StageQueryResponse>) => d.data)
}

const getStageById = (id: ID): Promise<ResponseStage> => {
  return axios
    .get(`${GET_STAGE_URL}/${id}`)
    .then((response: AxiosResponse<ResponseStage>) => response.data)
}

const createStage = (Stage: Stage | undefined): Promise<ResponseStage> => {
  return axios
    .post(`${GET_STAGE_URL}/novo`, Stage)
    .then((response: AxiosResponse<ResponseStage>) => response.data)
}

const updateStage = (stage: Stage): Promise<ResponseStage> => {
  return axios
    .put(`${GET_STAGE_URL}/${stage.id}/editar`, stage)
    .then((response: AxiosResponse<ResponseStage>) => response.data)
}

const deleteStage = (stageId: ID): Promise<void> => {
  return axios.delete(`${GET_STAGE_URL}/${stageId}/excluir`).then(() => {})
}

export {getStages, deleteStage,searchStage,getStageById, createStage, updateStage}
