import {FC} from 'react'
import {useQuery} from 'react-query'
import {useIntl} from 'react-intl'
import {ClientEditModalForm} from './ClientEditModalForm'
import {ID, isNotEmpty, QUERIES} from '../../../helpers'
import {useListView} from '../../../../app/modules/clients/components/core/ListViewProvider'
import {getClientById} from '../../../../app/modules/clients/components/core/_requests'
import {Loading} from '../../components/loading/Loading'
import {ClientEditModalHeader} from './ClientEditModalHeader'
import {initialClient} from '../../../../app/modules/clients/components/core/_models'

type Props = {
  handleClose: () => void | undefined
  clientId: ID | undefined
}

const ClientEditModalFormWrapper: FC<Props> = ({handleClose, clientId}) => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  if (clientId !== undefined) {
    setItemIdForUpdate(clientId)
  }
  const enabledQuery: boolean = isNotEmpty(clientId)

  const {
    isLoading,
    data: response,
    error,
  } = useQuery(
    `${QUERIES.CLIENTS_LIST}-client-${clientId}`,
    () => {
      return getClientById(clientId)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        console.error(err)
      },
      refetchOnWindowFocus: false,
    }
  )

  if (!clientId) {
    return (
      <>
        <ClientEditModalHeader handleClose={handleClose} />
        <ClientEditModalForm
          isClientLoading={isLoading}
          handleClose={handleClose}
          client={initialClient}
        />
      </>
    )
  }

  if (!isLoading && !error && response) {
    return (
      <>
        <ClientEditModalHeader handleClose={handleClose} />
        <ClientEditModalForm
          isClientLoading={isLoading}
          handleClose={handleClose}
          client={response.client}
        />
      </>
    )
  } else {
    return <Loading />
  }

  return null
}

export {ClientEditModalFormWrapper}
