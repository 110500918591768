import {FC} from 'react'
import { DetailsTimeUserModalContent } from './DetailsTimeUserModalContent'

type Props = {
  handleClose: () => void | undefined
  userId: String 
  dateSelected: String
  dateFormatSelected: String
}

const DetailsTimeUserModalContentWrapper: FC<Props> = ({handleClose, userId, dateSelected, dateFormatSelected}) => {
  return <DetailsTimeUserModalContent handleClose={handleClose} userId={userId} dateSelected={dateSelected} dateFormatSelected={dateFormatSelected} />
}

export {DetailsTimeUserModalContentWrapper}