import {FC, useState} from 'react'
import {useQuery} from 'react-query'
import {useIntl} from 'react-intl'
import {isNotEmpty, QUERIES} from '../../../helpers'
import {UserModalContent} from './UserModalContent'
import {getUserById} from './core/_requests'
import {initialUser} from './core/_models'
import {ID, KTSVG} from '../../../helpers'
import {StepperComponent} from '../../../assets/ts/components'
import {Loading} from '../../components/loading/Loading'

type Props = {
  id: ID
  handleClose: () => void | undefined
  stepper: React.MutableRefObject<StepperComponent | null>
  stepperRef: React.MutableRefObject<HTMLDivElement | null>
  setIsLoading: React.SetStateAction<any>
}

const UserModalContentWrapper: FC<Props> = ({
  id,
  handleClose,
  stepper,
  stepperRef,
  setIsLoading,
}) => {
  const intl = useIntl()
  const [itemIdForUpdate, setItemIdForUpdate] = useState<ID>(id)
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  setIsLoading(true)

  const {
    isLoading,
    data: response,
    error,
  } = useQuery(
    `${QUERIES.USERS_LIST}-USER-${itemIdForUpdate}`,
    () => {
      return getUserById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
      refetchOnWindowFocus: false,
    }
  )

  if (!itemIdForUpdate) {
    return (
      <>
        <div className='modal-header'>
          <h2>{intl.formatMessage({id: 'MODAL.TITLE.USER'})}</h2>
          {/* begin::Close */}
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
          {/* end::Close */}
        </div>
        <UserModalContent
          handleClose={handleClose}
          user={initialUser}
          userId={undefined}
          stepper={stepper}
          stepperRef={stepperRef}
        />
      </>
    )
  }

  if (!isLoading && !error && response) {
    setIsLoading(isLoading)
    return (
      <>
        <div className='modal-header'>
          <h2>{intl.formatMessage({id: 'MODAL.TITLE.USER'})}</h2>
          {/* begin::Close */}
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
          {/* end::Close */}
        </div>
        <UserModalContent
          handleClose={handleClose}
          userId={id}
          user={response.users}
          stepper={stepper}
          stepperRef={stepperRef}
        />
      </>
    )
  } else {
    return <Loading />
  }
}

export {UserModalContentWrapper}
