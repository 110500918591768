import clsx from 'clsx'
import {SprintUser} from '../../../../../app/modules/reports/components/sprint-team/core/_models'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

type Props = {
  className: string
  description: string
  stats: number
  labelColor: string
  textColor: string
  users: SprintUser[]
}

export function CardSprintMembers({
  className,
  description,
  stats,
  labelColor,
  textColor,
  users,
}: Props) {
  return (
    <div className={`card card-flush ${className}`}>
      <div className='card-header justify-content-start pt-5'>
        <div className='card-title d-flex flex-column'>
          <div className='card-title d-flex flex-column'>
            <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>{stats}</span>
            <span className='text-gray-400 pt-1 fw-semibold fs-6'>{description}</span>
          </div>
        </div>
      </div>
      <div className='card-body d-flex flex-column justify-content-end pe-0'>
        <span className='fs-6 fw-bolder text-gray-800 d-block mb-2'>Membros</span>
        <div className='symbol-group symbol-hover flex-nowrap'>
          {users.map((user, i) =>
            i <= 5 ? (
              <OverlayTrigger
                key={`${i}-${user.name}`}
                placement='top'
                overlay={<Tooltip id='tooltip-user-name'>{user.name}</Tooltip>}
              >
                <div
                  className='symbol symbol-35px symbol-circle'
                  data-bs-toggle='tooltip'
                  title={user.name}
                  key={`cw7-item-${user.id}`}
                >
                  {user.avatar ? (
                    <img
                      alt={user.name}
                      src={process.env.REACT_APP_THEME_API_URL + '/storage/' + user.avatar}
                      className='object-fit-cover'
                    />
                  ) : (
                    <span
                      className={clsx(
                        'symbol-label fs-8 fw-bold'
                      )}
                    >
                      {user.name.charAt(0)}
                    </span>
                  )}
                </div>
              </OverlayTrigger>
            ) : null
          )}

          {users.length > 5 ? (
            <a href='#' className='symbol symbol-35px symbol-circle'>
              <span
                className={clsx(
                  'symbol-label fs-8 fw-bold',
                  'bg-' + labelColor,
                  'text-' + textColor
                )}
              >
                {users.length - 5} +
              </span>
            </a>
          ) : null}
        </div>
      </div>
    </div>
  )
}
