/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo} from 'react'
import {useQuery} from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  isNotEmpty,
  PaginationState,
  QUERIES,
  stringifyRequestQuery,
  WithChildren,
} from '../../../../../../_metronic/helpers'
import {
  getTasksByCreatorId,
  getTasksByProjectId,
  getTasksCloseByProjectId,
  getTasksList,
  searchTask,
} from './_requests'
import {Task, TasksQueryResponse} from './_models'
import {useQueryRequest} from './QueryRequestProvider'
import {useParams} from 'react-router-dom'

const QueryResponseContext = createResponseContext<Task>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({children}) => {
  const {state} = useQueryRequest()
  const [query, setQuery] = useState(stringifyRequestQuery(state))
  const {project_id} = useParams()

  useEffect(() => {
    const stringQuery = stringifyRequestQuery(state)
    if (query !== stringQuery) {
      setQuery(stringQuery)
    }
  }, [state])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.TASKS_LIST}-${query}`,
    async () => {
      if (state.search === 'All') {
        const responseTasks = await getTasksByProjectId(project_id!, query)
        const responseClosedTasks = await getTasksCloseByProjectId(project_id!, query)
        return {
          data: [...responseTasks.data, ...responseClosedTasks.data],
        }
      }
      if (isNotEmpty(state.search)) {
        if (state.search === 'Aberta') {
          return getTasksByProjectId(project_id!, query)
        } else if (state.search === 'Fechada') {
          return getTasksCloseByProjectId(project_id!, query)
        } else if (state.search === 'CriadasPorMim') {
          return getTasksByCreatorId(project_id!, query)
        } else {
          return searchTask(state.search!.toString(), parseInt(project_id!))
        }
      } else {
        return getTasksByProjectId(project_id!, query)
      }
    },
    {cacheTime: 0, keepPreviousData: false, refetchOnWindowFocus: true}
  )

  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const {response} = useQueryResponse()

  if (!response) {
    return []
  }

  return response?.data || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const {response} = useQueryResponse()
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState
  }

  return response.payload.pagination
}

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
