import {ID, Response} from '../../../../../../../_metronic/helpers'

export type Project = {
  closed_at?: string
  project_id?: ID
}

export type ProjectExtraCostsQueryResponse = Response<Array<Project>>

export const initialProjectExtraCost: Project = {
  closed_at: new Date().toLocaleDateString('en-CA'),
  project_id: 0
}
