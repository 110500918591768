/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {Contracts} from '../../../../../modules/contracts/components/contracts-list/core/_models'
import {KTSVG} from '../../../../../../_metronic/helpers'

type Props = {
  contract: Contracts
}

const openView = (file: string | undefined) => {
  window.open(file,'_blank')
}


const ContractsNameCell: FC<Props> = ({contract}) => (
  <div className='d-flex align-items-center px-4'>
    {contract.expire && contract.expire <='2' ? <span className='bullet bullet-dot bg-danger me-2 h-10px w-10px'></span> : contract.expire && contract.expire >='2' && contract.expire <='30' ? <span className="bullet bullet-dot bg-warning me-2 h-10px w-10px"></span> : <span className="bullet bullet-dot bg-success me-2 h-10px w-10px"></span>} 
    <Link to='' onClick={() => openView(contract.file)} className="text-gray-800 text-hover-primary">{contract.title}</Link>
  </div>
)

export {ContractsNameCell}
