export const toAbsoluteUrl = (pathname: string) => process.env.PUBLIC_URL + pathname

export const secondsToHours = (timeInSeconds: number) => {
  var timeFormated = '00:00:00'
  if (timeInSeconds !== undefined) {
    var h =
      Math.floor(timeInSeconds / 3600) < 10
        ? `0${Math.floor(timeInSeconds / 3600)}`
        : Math.floor(timeInSeconds / 3600)
    var m = `0${Math.floor((timeInSeconds % 3600) / 60)}`.slice(-2)
    var s = `0${Math.floor((timeInSeconds % 3600) % 60)}`.slice(-2)
    timeFormated = h + ':' + m + ':' + s
  }
  return timeFormated
}

export const secondsToHoursFormatNotSeconds = (timeInSeconds: number) => {
  var timeFormated = '00:00'
  if (timeInSeconds !== undefined) {
    var h =
      Math.floor(timeInSeconds / 3600) < 10
        ? `0${Math.floor(timeInSeconds / 3600)}`
        : Math.floor(timeInSeconds / 3600)
    var m = `0${Math.floor((timeInSeconds % 3600) / 60)}`.slice(-2)
    timeFormated = h + ':' + m
  }
  return timeFormated
}

export const hoursToSeconds = (timeInHours: string) => {
  var hourSplit = timeInHours.split(':')
  if (hourSplit.length === 2) {
    var hourToSeconds = +hourSplit[0] * 60 * 60 + +hourSplit[1] * 60 + +0
  } else {
    var hourToSeconds = +hourSplit[0] * 60 * 60 + +hourSplit[1] * 60 + +hourSplit[2]
  }

  return hourToSeconds
}

export const secondsToDays = (timeInSeconds: number) => {
  return Math.ceil(timeInSeconds / (1000 * 3600 * 24))
}

export const diffInDays = (dateA: string, dateB: string) => {
  let date1 = new Date(dateA)
  let date2 = new Date(dateB)

  let diffInTime = date1.getTime() - date2.getTime()

  let diffInDays = Math.round(diffInTime / (1000 * 3600 * 24))

  return diffInDays
}

export const diffInMonths = (initialDate: string, finalDate: string) => {
  let date1 = new Date(initialDate)
  let date2 = new Date(finalDate)
  return Math.max(
    (date2.getFullYear() - date1.getFullYear()) * 12 + date2.getMonth() - date1.getMonth(),
    0
  )
}

export const diffInYears = (initialDate: string, finalDate: string) => {
  return Math.round(diffInMonths(initialDate, finalDate) / 12)
}