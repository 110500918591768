import axios, {AxiosResponse} from 'axios'
import {ID, QueryState, Response} from '../../../../../../../_metronic/helpers'
import {ResponseTaskAttachement, TaskAttachement, TasksQueryResponse} from './_models'
import {Contracts, ResponseContract} from "../../../../../contracts/components/contracts-list/core/_models";

const API_URL = process.env.REACT_APP_THEME_API_URL
const GET_TASKATTACHEMENT_URL = `${API_URL}/api/tarefas/anexo`

const createTaskAttachement =  (taskAttachement: TaskAttachement): Promise<ResponseTaskAttachement> => {
  let formData = new FormData()
  if (taskAttachement.file) {
    formData.append('file', taskAttachement.file)
  }
  if (taskAttachement.task_id) {
    formData.append('task_id', taskAttachement.task_id.toLocaleString())
  }
  return axios
      .post(`${GET_TASKATTACHEMENT_URL}/novo`, formData)
      .then((response: AxiosResponse<ResponseTaskAttachement>) => response.data)
}


const getAttachements = (id: ID): Promise<TasksQueryResponse> => {
  return axios
      .get(`${GET_TASKATTACHEMENT_URL}/${id}`)
      .then((d: AxiosResponse<TasksQueryResponse>) => d.data)
}

const deleteAttachement = (attachementid: ID): Promise<void> => {
  return axios.delete(`${GET_TASKATTACHEMENT_URL}/${attachementid}/excluir`).then(() => {})
}

const getTaskAttachementById = (id: ID): Promise<TasksQueryResponse> => {
  return axios
      .get(`${GET_TASKATTACHEMENT_URL}/${id}`)
      .then((d: AxiosResponse<TasksQueryResponse>) => d.data)
}


export {createTaskAttachement, getAttachements, deleteAttachement, getTaskAttachementById}
