import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { requestPassword } from '../core/_requests'
import { useIntl } from 'react-intl'

const initialValues = {
  email: '',
}

export function ForgotPassword() {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [errorMessage, setErrorMessage] = useState('')

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: 'FORM.INPUT.VALIDATION.EMAIL' }))
      .max(50, intl.formatMessage({ id: 'FORM.INPUT.VALIDATION.MAX_LENGTH' }, { max: '50' }))
      .required(intl.formatMessage({ id: 'FORM.INPUT.VALIDATION.REQUIRED' })),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        requestPassword(values.email)
          .then(({ data: { result } }) => {
            setHasErrors(false)
            setLoading(false)
          })
          .catch((error) => {
            if (error.response.status == 422) {
              setErrorMessage(error.response.data.message)
            } else {
              setErrorMessage(intl.formatMessage({ id: 'AUTH.FORGOT.SENT_FAIL' }))
            }

            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setStatus('The login detail is incorrect')
          })
      }, 1000)
    },
  })

  return (

    <>
      <div className='d-flex flex-column flex-lg-row flex-column-fluid '>
        <div className='d-flex flex-lg-row-fluid'>
          {/*begin::Content*/}
          <div className='d-flex flex-column flex-center pb-0 pb-lg-10 p-10 w-100'>
            {/*begin::Image*/}
            <img
              className='theme-light-show mx-auto mw-100 w-150px w-lg-300px mb-10 mb-lg-20'
              src='/media/logos/taskrush.svg'
              alt=''
            />
            <img
              className='theme-dark-show mx-auto mw-100 w-150px w-lg-300px mb-10 mb-lg-20'
              src='/media/logos/taskrush.svg'
              alt=''
            />
            {/*end::Image*/}

            {/*begin::Title*/}
            <h1 className='text-gray-800 fs-2qx fw-bold text-center mb-7'>
              {intl.formatMessage({ id: 'AUTH.LOGIN.BIO' })}
            </h1>
            {/*end::Title*/}
          </div>
          {/*end::Content*/}
        </div>

        <div className='d-flex flex-column flex-lg-row flex-column-fluid justify-content-lg-end'>
          {/*begin::Wrapper*/}
          <div className='bg-body d-flex flex-column flex-center rounded-4 w-md-500px p-8'>
            {/*begin::Content*/}
            <div className='d-flex flex-center flex-column align-items-stretch h-lg-100 w-md-400px'>
              {/*begin::Wrapper*/}
              <div className='d-flex flex-center flex-column flex-column-fluid pb-15 pb-lg-10'>
                {/*begin::Form*/}
                <form
                  className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                  noValidate
                  id='kt_login_password_reset_form'
                  onSubmit={formik.handleSubmit}
                >
                  <div className='text-center mb-10'>
                    {/* begin::Title */}
                    <h1 className='text-dark fw-bolder mb-3'>{intl.formatMessage({ id: 'AUTH.FORGOT.TITLE' })}</h1>
                    {/* end::Title */}

                    {/* begin::Link */}
                    <div className='text-gray-500 fw-semibold fs-6'>
                      {intl.formatMessage({ id: 'AUTH.FORGOT.DESC' })}
                    </div>
                    {/* end::Link */}
                  </div>

                  {/* begin::Title */}
                  {hasErrors === true && (
                    <div className='mb-lg-15 alert alert-danger'>
                      <div className='alert-text font-weight-bold'>
                        {errorMessage}
                      </div>
                    </div>
                  )}

                  {hasErrors === false && (
                    <div className='mb-10 bg-light-info p-8 rounded'>
                      <div className='text-info'>{intl.formatMessage({ id: 'AUTH.FORGOT.SENT_SUCCESS' })}</div>
                    </div>
                  )}
                  {/* end::Title */}

                  {/* begin::Form group */}
                  <div className='fv-row mb-8'>
                    <label className='form-label fw-bolder text-gray-900 fs-6'>{intl.formatMessage({ id: 'AUTH.INPUT.EMAIL' })}</label>
                    <input
                      type='email'
                      placeholder=''
                      autoComplete='off'
                      {...formik.getFieldProps('email')}
                      className={clsx(
                        'form-control bg-transparent',
                        { 'is-invalid': formik.touched.email && formik.errors.email },
                        {
                          'is-valid': formik.touched.email && !formik.errors.email,
                        }
                      )}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.email}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* end::Form group */}

                  {/* begin::Form group */}
                  <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                    <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
                      <span className='indicator-label'>{intl.formatMessage({ id: 'AUTH.GENERAL.SUBMIT_BUTTON' })}</span>
                      {loading && (
                        <span className='indicator-progress'>
                          {intl.formatMessage({ id: 'AUTH.GENERAL.LOADING' })}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                    <Link to='/auth/login'>
                      <button
                        type='button'
                        id='kt_login_password_reset_form_cancel_button'
                        className='btn btn-light'
                        disabled={formik.isSubmitting || !formik.isValid}
                      >
                        {intl.formatMessage({ id: 'AUTH.GENERAL.CANCEL_BUTTON' })}
                      </button>
                    </Link>{' '}
                  </div>
                  {/* end::Form group */}
                </form>
                {/*end::Form*/}
              </div>
            </div>
            {/*end::Content*/}
          </div>
          {/*end::Wrapper*/}
        </div>
      </div>
    </>
  )
}
