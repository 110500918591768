/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo} from 'react'
import {useQuery} from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  isNotEmpty,
  PaginationState,
  QUERIES,
  stringifyRequestQuery,
  WithChildren,
} from '../../../../_metronic/helpers'
import {getTasksByCreatorId, getTasksByUserId, getTasksCloseByUserId, searchTask} from './_requests'
import {Task} from './_models'
import {useQueryRequest} from './QueryRequestProvider'
import {useParams} from "react-router-dom";

const QueryResponseContext = createResponseContext<Task>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({children}) => {
const {state} = useQueryRequest()
const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])
const {userId} = useParams()

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
      `${QUERIES.TASKS_LIST}-${query}`,
      () => {
        if (isNotEmpty(state.search)) {
            if(state.search === 'Aberta') {
              return getTasksByUserId(parseInt(userId!), query)
            } else if(state.search === 'Fechada') {
              return getTasksCloseByUserId(parseInt(userId!),query)
            } else {
              return searchTask(parseInt(userId!),state.search!.toString())
            }
        } else {
          return getTasksByUserId(parseInt(userId!), query)
        }
      },
      {cacheTime: 0, keepPreviousData: false, refetchOnWindowFocus: true}
  )

  return (
      <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
        {children}
      </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const {response} = useQueryResponse()

  if (!response) {
    return []
  }

  return response?.data || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const {response} = useQueryResponse()
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState
  }

  return response.payload.pagination
}

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
